.AvatarWithNumber-module_avatar-with-number_RXV-F__Stat {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.AvatarWithNumber-module_avatar-with-number__jersey-number_MXjQk__Stat {
  border: 1px solid var(--line-line);
  background-color: var(--bg-block);
  color: var(--text-secondary);
  z-index: 1;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 15px;
  margin-top: -5px;
  display: flex;
  position: relative;
}

.AvatarWithNumber-module_avatar-with-number__avatar_aEpXc__Stat {
  border-radius: 30px;
}

.Fixtures-module_fixtures_dHzZU__Stat {
  border: 1px solid var(--line-line);
  background: var(--line-line);
  border-radius: 10px;
  flex-direction: column;
  gap: 1px;
  display: flex;
  overflow: hidden;
}

.Fixtures-module_fixtures__header_YRgjE__Stat {
  background: var(--bg-component);
  align-items: center;
  gap: 10px;
  padding: 15px 10px;
  display: flex;
}

.Fixtures-module_fixtures__title_fbMK4__Stat {
  color: var(--text-primary);
}

.Fixtures-module_fixtures__main_OZHml__Stat {
  background: var(--line-line);
  flex-direction: column;
  gap: 1px;
  display: flex;
}

.Fixtures-module_fixtures__button_R-BxQ__Stat {
  cursor: pointer;
  background: var(--bg-block);
  border-radius: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  display: flex;
}

.Fixtures-module_fixtures__button__text_grKZY__Stat {
  color: var(--text-primary);
}

.Fixtures-module_fixtures__button__icon_GTWCl__Stat {
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  display: flex;
}

.Fixtures-module_fixtures__button__icon_GTWCl__Stat path {
  fill: var(--text-primary);
}

.FixturesMatch-module_fixtures-match_laGlE__Stat {
  background: var(--bg-block);
  grid-template-columns: .45fr .95fr .7fr;
  align-items: center;
  width: 100%;
  padding: 10px;
  display: grid;
}

@media screen and (max-width: 800px) {
  .FixturesMatch-module_fixtures-match_laGlE__Stat {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    display: flex;
  }
}

.FixturesMatchMainInfo-module_match-info_hIV-B__Stat {
  grid-template-columns: 1fr 60px 1fr;
  gap: 10px;
  display: grid;
}

.FixturesMatchMainInfo-module_match-info__home_x4Bn2__Stat, .FixturesMatchMainInfo-module_match-info__away_--cdt__Stat {
  align-items: center;
  gap: 10px;
  display: flex;
}

.FixturesMatchMainInfo-module_match-info__home_x4Bn2__Stat {
  justify-content: flex-end;
}

.FixturesMatchMainInfo-module_match-info__team-title_Ko-PB__Stat {
  color: var(--text-primary);
  word-break: break-word;
}

.FixturesMatchMainInfo-module_match-info__team-title--home_4ljGk__Stat {
  text-align: right;
}

.FixturesMatchMainInfo-module_match-info__team-title--disabled_M5U0O__Stat {
  color: var(--text-tertiary);
}

.FixturesMatchMainInfo-module_match-info__scores-wrapper_bubNL__Stat {
  flex-direction: column;
  gap: 5px;
  display: flex;
}

.FixturesMatchMainInfo-module_match-info__score_Dn1cd__Stat {
  border-radius: 5px;
  justify-content: space-around;
  align-items: center;
  width: 60px;
  height: 30px;
  display: flex;
}

.FixturesMatchMainInfo-module_match-info__score--default_T2890__Stat {
  background: var(--match-bg-score-not-started);
}

.FixturesMatchMainInfo-module_match-info__score--live_5S2tV__Stat {
  background: var(--match-bg-score-live);
}

.FixturesMatchMainInfo-module_match-info__score--placeholder_Pwdjj__Stat {
  background: var(--match-bg-score-not-started);
}

.FixturesMatchMainInfo-module_match-info__score--win_sZw5Q__Stat {
  box-shadow: 0 2px 0 0 var(--match-bg-score-live);
}

.FixturesMatchMainInfo-module_match-info__score--draw_LJ9yT__Stat {
  box-shadow: 0 2px 0 0 var(--match-bg-score-full-time);
}

.FixturesMatchMainInfo-module_match-info__score--lose_oIoMj__Stat {
  box-shadow: 0 2px 0 0 var(--text-error-live);
}

.FixturesMatchMainInfo-module_match-info__score__text_vIjwI__Stat {
  text-align: center;
  width: 50%;
}

.FixturesMatchMainInfo-module_match-info__score__text--default_qRJhT__Stat {
  color: var(--text-primary);
}

.FixturesMatchMainInfo-module_match-info__score__text--live_HDKph__Stat {
  color: var(--text-white);
}

.FixturesMatchMainInfo-module_match-info__score__delimiter_FG9r5__Stat {
  width: 1px;
  height: 10px;
}

.FixturesMatchMainInfo-module_match-info__score__delimiter--default_4I9mp__Stat {
  background: var(--icons-primary);
}

.FixturesMatchMainInfo-module_match-info__score__delimiter--live_6ct7s__Stat {
  background: var(--text-white);
}

.FixturesMatchMainInfo-module_match-info__penalty-score_IeV8O__Stat {
  border-radius: 5px;
  justify-content: space-around;
  align-items: center;
  width: 60px;
  height: 30px;
  display: flex;
}

.FixturesMatchMainInfo-module_match-info__penalty-score--live_VLol-__Stat {
  background: var(--match-bg-score-live);
}

.FixturesMatchMainInfo-module_match-info__penalty-score--default_j-jr3__Stat {
  background: var(--match-bg-score-full-time);
}

.FixturesMatchMainInfo-module_match-info__penalty-score__text_IdcYT__Stat {
  text-align: center;
  width: 50%;
  color: var(--text-white);
}

.FixturesMatchMainInfo-module_match-info__penalty-score__delimiter_Rf3Hb__Stat {
  color: var(--text-white);
}

@media screen and (max-width: 800px) {
  .FixturesMatchMainInfo-module_match-info_hIV-B__Stat {
    align-self: center;
    align-items: center;
    width: 100%;
  }
}

.FixturesMatchOdds-module_odds_N-kWs__Stat {
  justify-content: flex-end;
  display: flex;
}

.FixturesMatchOdds-module_odds__wrapper_LKMak__Stat {
  background: var(--bg-component);
  cursor: pointer;
  border-radius: 5px;
  align-items: center;
  gap: 5px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px;
  display: flex;
}

.FixturesMatchOdds-module_odds__wrapper--ggbet_xyfse__Stat {
  background-color: var(--bg-black-3);
  border-radius: unset;
  clip-path: polygon(10px 0, 100% 0, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%, 0 10px);
}

.FixturesMatchOdds-module_odds__wrapper--ggbet-icon_kZEPJ__Stat {
  margin: 0 10px;
}

.FixturesMatchOdds-module_odds__odd_VOPU6__Stat {
  background: var(--bg-block);
  border-radius: 5px;
  justify-content: space-between;
  align-items: center;
  width: 65px;
  height: 20px;
  padding: 0 10px;
  display: flex;
}

.FixturesMatchOdds-module_odds__odd--ggbet_X8Bmv__Stat {
  background-color: var(--advertisement-orange);
  border-radius: unset;
  clip-path: polygon(8px 0, 100% 0, 100% calc(100% - 8px), calc(100% - 8px) 100%, 0 100%, 0 8px);
}

.FixturesMatchOdds-module_odds__odd__text_I12Bu__Stat {
  color: var(--text-secondary);
}

.FixturesMatchOdds-module_odds__odd__text--ggbet_ioPqH__Stat {
  color: var(--text-white);
}

@media screen and (max-width: 800px) {
  .FixturesMatchOdds-module_odds_N-kWs__Stat {
    align-self: center;
  }
}

.FixturesMatchStatusInfo-module_match-status-info_DtIA4__Stat {
  flex-wrap: wrap;
  gap: 5px 20px;
  display: flex;
}

.FixturesMatchStatusInfo-module_match-status-info__tournament-text_BqbY9__Stat, .FixturesMatchStatusInfo-module_match-status-info__date-and-time_NfSsh__Stat {
  color: var(--text-secondary);
}

.FixturesMatchStatusInfo-module_match-status-info__status-text_xFRgm__Stat {
  position: relative;
}

.FixturesMatchStatusInfo-module_match-status-info__status-text--delimiter-before_lcZ8l__Stat:before {
  content: "";
  background: var(--line-line);
  width: 1px;
  height: 10px;
  display: flex;
  position: absolute;
  top: 2px;
  left: -10px;
}

.FixturesMatchStatusInfo-module_match-status-info__status-text--default_NFjJQ__Stat {
  color: var(--text-secondary);
}

.FixturesMatchStatusInfo-module_match-status-info__status-text--live_2oynX__Stat {
  color: var(--text-error-live);
}

.FixturesMatchStatusInfo-module_match-status-info__tournament-text_BqbY9__Stat {
  width: 100%;
}

@media screen and (max-width: 800px) {
  .FixturesMatchStatusInfo-module_match-status-info_DtIA4__Stat {
    grid-template-rows: auto;
    grid-template-columns: repeat(3, max-content);
  }

  .FixturesMatchStatusInfo-module_match-status-info__tournament-text_BqbY9__Stat {
    width: max-content;
    position: relative;
  }

  .FixturesMatchStatusInfo-module_match-status-info__tournament-text_BqbY9__Stat:before {
    content: "";
    background: var(--line-line);
    width: 1px;
    height: 10px;
    display: flex;
    position: absolute;
    top: 2px;
    left: -10px;
  }
}

.InjuryHistory-module_injury-history_TKNgl__Stat {
  background: var(--bg-block);
  border-radius: 15px;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: 15px 10px 10px;
  scroll-margin-top: 70px;
  display: flex;
}

.InjuryHistory-module_injury-history__header_dQa1l__Stat {
  color: var(--text-primary);
}

.InjuryHistoryRow-module_injury-history-table--row_-GMuE__Stat, .InjuryHistoryRow-module_injury-history-table--header_3JPFC__Stat {
  border-bottom: 1px solid var(--line-line);
  grid-template-columns: 1fr repeat(3, 40px);
  padding: 15px 10px;
  display: grid;
}

.InjuryHistoryRow-module_injury-history-table--row__main-column_AGg-4__Stat, .InjuryHistoryRow-module_injury-history-table--row__missed-days-column_TSSBG__Stat, .InjuryHistoryRow-module_injury-history-table--row__missed-matches-column_uzkgU__Stat, .InjuryHistoryRow-module_injury-history-table--row__team-column_laI07__Stat, .InjuryHistoryRow-module_injury-history-table--header__main-column_pAyJY__Stat, .InjuryHistoryRow-module_injury-history-table--header__missed-days-column_uA6L3__Stat, .InjuryHistoryRow-module_injury-history-table--header__missed-matches-column_-tdiu__Stat, .InjuryHistoryRow-module_injury-history-table--header__team-column_EFD1o__Stat {
  justify-content: center;
  display: flex;
}

.InjuryHistoryRow-module_injury-history-table--row__main-column_AGg-4__Stat, .InjuryHistoryRow-module_injury-history-table--header__main-column_pAyJY__Stat {
  flex-direction: column;
}

.InjuryHistoryRow-module_injury-history-table--row__missed-days-column_TSSBG__Stat, .InjuryHistoryRow-module_injury-history-table--row__missed-matches-column_uzkgU__Stat, .InjuryHistoryRow-module_injury-history-table--row__team-column_laI07__Stat, .InjuryHistoryRow-module_injury-history-table--header__missed-days-column_uA6L3__Stat, .InjuryHistoryRow-module_injury-history-table--header__missed-matches-column_-tdiu__Stat, .InjuryHistoryRow-module_injury-history-table--header__team-column_EFD1o__Stat {
  align-items: center;
}

.InjuryHistoryRow-module_injury-history-table--header_3JPFC__Stat {
  background: var(--bg-component);
}

.InjuryHistoryRow-module_injury-history-table--header__main-column__main-text_JyH4P__Stat, .InjuryHistoryRow-module_injury-history-table--header__missed-days-column_uA6L3__Stat, .InjuryHistoryRow-module_injury-history-table--header__missed-matches-column_-tdiu__Stat, .InjuryHistoryRow-module_injury-history-table--header__team-column_EFD1o__Stat {
  color: var(--text-secondary);
}

.InjuryHistoryRow-module_injury-history-table--row_-GMuE__Stat {
  background: var(--bg-block);
}

.InjuryHistoryRow-module_injury-history-table--row__main-column_AGg-4__Stat {
  gap: 5px;
}

.InjuryHistoryRow-module_injury-history-table--row__main-column__main-text_gZWkA__Stat, .InjuryHistoryRow-module_injury-history-table--row__missed-days-column_TSSBG__Stat, .InjuryHistoryRow-module_injury-history-table--row__missed-matches-column_uzkgU__Stat, .InjuryHistoryRow-module_injury-history-table--row__team-column_laI07__Stat {
  color: var(--text-primary);
}

.InjuryHistoryRow-module_injury-history-table--row__main-column__sub-text_QC7I-__Stat {
  color: var(--text-secondary);
}

.Legends-module_legends_Jarlw__Stat {
  background-color: var(--bg-block);
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
  display: flex;
}

.Legends-module_legends__legend_mj8--__Stat {
  color: var(--text-primary);
  justify-content: center;
  align-items: center;
  gap: 5px;
  display: flex;
}

.Legends-module_legends__legend-text_Nnffa__Stat {
  color: var(--text-secondary);
}

.TableHOC-module_table-hoc_xKq-x__Stat {
  border: 1px solid var(--line-line);
  border-radius: 10px;
  overflow: hidden;
}

.LeagueStatPlayers-module_league-stat-players_oIeV4__Stat {
  flex-direction: column;
  gap: 20px;
  display: flex;
}

.LeagueStatPlayers-module_league-stat-players__main_sAllT__Stat {
  background-color: var(--bg-block);
  border-radius: 15px;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
  display: flex;
}

.SquadBest-module_squad-best_zvCpp__Stat {
  flex-direction: column;
  gap: 20px;
  display: flex;
}

.SquadBest-module_squad-best__main_gx-92__Stat {
  background-color: var(--bg-block);
  border-radius: 15px;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
  display: flex;
}

.SquadBest-module_squad-best__dropdowns_iPf6W__Stat {
  gap: 10px;
  display: flex;
}

.SquadBest-module_squad-best__dropdowns__dropdown_DDx3H__Stat {
  width: 290px;
  max-width: 290px;
}

.SquadBest-module_squad-best__cards_ip-S2__Stat {
  flex-wrap: wrap;
  gap: 10px;
  display: flex;
}

.SquadBest-module_squad-best__card_Fs39N__Stat {
  z-index: 1;
  flex-direction: column;
  flex: 1;
  min-width: 293px;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
}

.SquadBest-module_squad-best__empty-line_9f4sP__Stat {
  border-bottom: 1px solid var(--line-line);
  height: 40px;
}

.SquadBest-module_squad-best__show-all_Jtv0W__Stat {
  cursor: pointer;
  color: var(--text-primary);
  justify-content: center;
  padding: 10px;
  display: flex;
}

.SquadBest-module_squad-best__show-all--disabled_BD8a1__Stat {
  color: var(--disabled-text);
  cursor: default;
}

.SquadBest-module_squad-best-detailed_ANqAP__Stat {
  flex-direction: column;
  gap: 20px;
  display: flex;
}

.SquadBest-module_squad-best-detailed__header_hUnwv__Stat {
  align-items: center;
  gap: 5px;
  display: flex;
}

.SquadBest-module_squad-best-detailed__category-name_46JJH__Stat {
  color: var(--text-primary);
}

.SquadBest-module_squad-best-detailed__arrow-icon_PMhVO__Stat {
  fill: var(--icons-primary);
  cursor: pointer;
  width: 20px;
  height: 20px;
  transform: rotate(90deg);
}

.SquadBest-module_squad-best-detailed__top-players_c4TCJ__Stat {
  flex-wrap: wrap;
  gap: 10px;
  display: flex;
}

.SquadBest-module_best-players-table__row_Bjjt5__Stat {
  padding: 10px;
  display: flex;
}

.SquadBest-module_best-players-table__row_Bjjt5__Stat:not(:last-child) {
  border-bottom: 1px solid var(--line-line);
}

.SquadBest-module_best-players-table__number_p-kjX__Stat {
  color: var(--text-tertiary);
}

.SquadBest-module_best-players-table__name_AMBML__Stat {
  color: var(--text-primary);
  -webkit-line-clamp: 2;
  word-break: break-word;
  -webkit-box-orient: vertical;
  flex: 1;
  min-width: 200px;
  display: -webkit-box;
  overflow: hidden;
}

.SquadBest-module_best-players-table__team_1huMb__Stat {
  color: var(--text-primary);
  align-items: center;
  gap: 5px;
  min-width: 200px;
  padding-right: 10px;
  display: flex;
}

.SquadBest-module_best-players-table__team__name_ClbcP__Stat, .SquadBest-module_best-players-table__value_mScpt__Stat {
  color: var(--text-primary);
}

.SquadBest-module_best-players-table__number_p-kjX__Stat, .SquadBest-module_best-players-table__value_mScpt__Stat {
  justify-content: center;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 25px;
  display: flex;
}

@media (max-width: 660px) {
  .SquadBest-module_squad-best__dropdowns_iPf6W__Stat {
    flex-direction: column;
  }

  .SquadBest-module_squad-best__dropdowns__dropdown_DDx3H__Stat {
    width: 100%;
    max-width: none;
  }

  .SquadBest-module_best-players-table__team_1huMb__Stat {
    min-width: 20px;
  }

  .SquadBest-module_best-players-table__team__name_ClbcP__Stat {
    display: none;
  }
}

.TheBestPlayersWidget-module_the-best_AAQZN__Stat {
  color: var(--text-primary);
  flex-direction: column;
  gap: 20px;
  display: flex;
}

.TheBestPlayersWidget-module_the-best__with-background_x4sSH__Stat {
  background-color: var(--bg-block);
  border-radius: 15px;
  padding: 10px;
}

.TheBestPlayersWidget-module_the-best__header_BP6DX__Stat {
  justify-content: space-between;
  display: flex;
}

.TheBestPlayersWidget-module_the-best__header-link_glMSq__Stat {
  color: var(--text-secondary);
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
}

.TheBestPlayersWidget-module_stat-block_UxGzk__Stat {
  flex-direction: column;
  width: 275px;
  display: flex;
}

.TheBestPlayersWidget-module_stat-block__bg_9FnLA__Stat {
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.TheBestPlayersWidget-module_stat-block__row_dL1Wy__Stat {
  position: relative;
}

.TheBestPlayersWidget-module_stat-block__row_dL1Wy__Stat:not(:last-child) {
  border-bottom: 1px solid var(--line-line);
}

.TheBestPlayersWidget-module_stat-block__title_uJY1i__Stat {
  background-color: var(--bg-component);
  width: -moz-fit-content;
  width: fit-content;
  color: var(--text-tertiary);
  border-bottom-right-radius: 10px;
  padding: 5px 10px;
  display: flex;
}

.TheBestPlayersWidget-module_stat-block__name_IBoq7__Stat {
  color: var(--text-primary);
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 10px;
  display: block;
  overflow: hidden;
}

.TheBestPlayersWidget-module_stat-block__value_UVHHu__Stat {
  color: var(--text-primary);
  flex: 1;
  justify-content: flex-end;
  display: flex;
}

.TheBestPlayersWidget-module_stat-block__player-row_PrKy3__Stat {
  align-items: center;
  padding: 10px;
  display: flex;
}

.TheBestPlayersWidget-module_stat-block__logo_eHYtd__Stat {
  border-radius: 10px;
  margin: 7px;
}

.TheBestPlayersWidget-module_stat-block__logo_eHYtd__Stat div img {
  border-radius: 30px;
}

.TheBestPlayersWidget-module_stat-block__logo-wrapper-main_z6sHl__Stat, .TheBestPlayersWidget-module_stat-block__logo-wrapper-additional_ai2sq__Stat, .TheBestPlayersWidget-module_stat-block__logo-wrapper-main-first_R4saT__Stat {
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: 10px;
  display: flex;
  overflow: hidden;
}

.TheBestPlayersWidget-module_stat-block__logo-wrapper-main-first_R4saT__Stat {
  border: 1px solid var(--line-line);
  z-index: 1;
  background-color: var(--bg-block);
  width: 40px;
  height: 40px;
}

.TheBestPlayersWidget-module_stat-block__logo-wrapper-main-first__without-second-img_7blyg__Stat {
  margin-right: 0;
}

.TheBestPlayersWidget-module_stat-block__logo-wrapper-additional_ai2sq__Stat {
  border: 5px solid var(--bg-component);
  width: 40px;
  height: 40px;
  margin-left: -10px;
}

.TheBestPlayersWidget-module_stat-block__logo-wrapper-main_z6sHl__Stat {
  background-color: var(--bg-block);
  width: 20px;
  height: 20px;
}

.TheBestPlayersWidget-module_stat-block__logo-wrapper-main__without-second-img_LR16J__Stat {
  margin-right: 0;
}

@media (max-width: 660px) {
  .TheBestPlayersWidget-module_the-best__header_BP6DX__Stat {
    flex-direction: column;
    gap: 10px;
    display: flex;
  }
}

.TopPlayer-module_top-player_UbXqh__Stat {
  z-index: 1;
  flex-direction: column;
  flex: 1;
  width: 275px;
  min-width: 240px;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  position: relative;
}

.TopPlayer-module_top-player__bg_ZXDNv__Stat {
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.TopPlayer-module_top-player__title_H-Xdu__Stat {
  background-color: var(--label-general-3-bg);
  width: -moz-fit-content;
  width: fit-content;
  min-width: 20px;
  height: 20px;
  color: var(--label-general-3-text);
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  margin: 5px 0 0 5px;
  padding: 0 10px;
  display: flex;
}

.TopPlayer-module_top-player__title--first_L3WgC__Stat {
  background-color: var(--label-general-2-bg);
  color: var(--label-general-2-text);
}

.TopPlayer-module_top-player__title--hide_Icw4O__Stat {
  background-color: inherit;
}

.TopPlayer-module_top-player__name_fKcbB__Stat {
  color: var(--text-primary);
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 10px;
  display: block;
  overflow: hidden;
}

.TopPlayer-module_top-player__value_88XER__Stat {
  color: var(--text-primary);
  flex: 1;
  justify-content: flex-end;
  display: flex;
}

.TopPlayer-module_top-player__player-row_luVGk__Stat {
  align-items: center;
  padding: 10px;
  display: flex;
}

.TopPlayer-module_top-player__logo_zlkBt__Stat {
  border-radius: 10px;
  margin: 7px;
}

.TopPlayer-module_top-player__logo_zlkBt__Stat div img {
  border-radius: 30px;
}

.TopPlayer-module_top-player__logo-wrapper-main_BRTRi__Stat, .TopPlayer-module_top-player__logo-wrapper-additional_nhdEs__Stat {
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: 10px;
  display: flex;
  overflow: hidden;
}

.TopPlayer-module_top-player__logo-wrapper-main_BRTRi__Stat {
  border: 1px solid var(--line-line);
  z-index: 1;
  background-color: var(--bg-block);
  width: 40px;
  height: 40px;
}

.TopPlayer-module_top-player__logo-wrapper-main__without-second-img_5o3Iq__Stat {
  margin-right: 0;
}

.TopPlayer-module_top-player__logo-wrapper-additional_nhdEs__Stat {
  border: 5px solid var(--bg-component);
  width: 40px;
  height: 40px;
  margin-left: -10px;
}

.LeagueStatTeams-module_league-stat-teams_CmLd7__Stat {
  flex-direction: column;
  gap: 20px;
  display: flex;
}

.LeagueStatTeams-module_league-stat-teams__main_9mIsq__Stat {
  background-color: var(--bg-block);
  border-radius: 15px;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
  display: flex;
}

.AllTheBestTeams-module_all-the-best-teams_TtSW0__Stat {
  flex-wrap: wrap;
  gap: 10px;
  display: flex;
}

.AllTheBestTeams-module_all-the-best-teams__card_rXrpK__Stat {
  z-index: 1;
  flex-direction: column;
  flex: 1;
  min-width: 293px;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
}

.AllTheBestTeams-module_all-the-best-teams__empty-line_mXcVy__Stat {
  border-bottom: 1px solid var(--line-line);
  height: 40px;
}

.AllTheBestTeams-module_all-the-best-teams__show-all_OTD3H__Stat {
  cursor: pointer;
  color: var(--text-primary);
  justify-content: center;
  padding: 10px;
  display: flex;
}

.AllTheBestTeams-module_all-the-best-teams__show-all--disabled_4BfKb__Stat {
  color: var(--disabled-text);
  cursor: default;
}

.DetailedStatByMetrick-module_dropdowns_NLdQ2__Stat {
  gap: 10px;
  display: flex;
}

.DetailedStatByMetrick-module_dropdowns__dropdown_LryJj__Stat {
  width: 290px;
  max-width: 290px;
}

.DetailedStatByMetrick-module_detailed-stat_1o1-X__Stat {
  flex-direction: column;
  gap: 20px;
  display: flex;
}

.DetailedStatByMetrick-module_detailed-stat__header_Yf9Yo__Stat {
  align-items: center;
  gap: 5px;
  display: flex;
}

.DetailedStatByMetrick-module_detailed-stat__category-name_B1o8V__Stat {
  color: var(--text-primary);
}

.DetailedStatByMetrick-module_detailed-stat__arrow-icon_GKc11__Stat {
  fill: var(--icons-primary);
  cursor: pointer;
  width: 20px;
  height: 20px;
  transform: rotate(90deg);
}

.DetailedStatByMetrick-module_detailed-stat__top-teams_G10qG__Stat {
  flex-wrap: wrap;
  gap: 10px;
  display: flex;
}

.DetailedStatByMetrick-module_best-teams-table__row_fg0ug__Stat {
  padding: 10px;
  display: flex;
}

.DetailedStatByMetrick-module_best-teams-table__row_fg0ug__Stat:not(:last-child) {
  border-bottom: 1px solid var(--line-line);
}

.DetailedStatByMetrick-module_best-teams-table__number_xzwCV__Stat {
  color: var(--text-tertiary);
}

.DetailedStatByMetrick-module_best-teams-table__name_WCR63__Stat {
  color: var(--text-primary);
  -webkit-line-clamp: 2;
  word-break: break-word;
  -webkit-box-orient: vertical;
  flex: 1;
  display: -webkit-box;
  overflow: hidden;
}

.DetailedStatByMetrick-module_best-teams-table__value_-vWTW__Stat {
  color: var(--text-primary);
}

.DetailedStatByMetrick-module_best-teams-table__number_xzwCV__Stat, .DetailedStatByMetrick-module_best-teams-table__value_-vWTW__Stat {
  justify-content: center;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 25px;
  display: flex;
}

@media (max-width: 660px) {
  .DetailedStatByMetrick-module_dropdowns_NLdQ2__Stat {
    flex-direction: column;
  }

  .DetailedStatByMetrick-module_dropdowns__dropdown_LryJj__Stat {
    width: 100%;
    max-width: none;
  }
}

.TopTeam-module_top-team_36FaY__Stat {
  z-index: 1;
  flex-direction: column;
  flex: 1;
  width: 275px;
  min-width: 240px;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  position: relative;
}

.TopTeam-module_top-team__bg_1qPQJ__Stat {
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.TopTeam-module_top-team__title_GMbqS__Stat {
  background-color: var(--label-general-3-bg);
  width: -moz-fit-content;
  width: fit-content;
  min-width: 20px;
  height: 20px;
  color: var(--label-general-3-text);
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  margin: 5px 0 0 5px;
  padding: 0 10px;
  display: flex;
}

.TopTeam-module_top-team__title--first_RuUyD__Stat {
  background-color: var(--label-general-2-bg);
  color: var(--label-general-2-text);
}

.TopTeam-module_top-team__title--hide_XAd50__Stat {
  background-color: inherit;
}

.TopTeam-module_top-team__name_7BgNl__Stat {
  color: var(--text-primary);
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 10px;
  display: block;
  overflow: hidden;
}

.TopTeam-module_top-team__value_5JAMV__Stat {
  color: var(--text-primary);
  flex: 1;
  justify-content: flex-end;
  display: flex;
}

.TopTeam-module_top-team__player-row_KLlhM__Stat {
  align-items: center;
  padding: 10px;
  display: flex;
}

.TopTeam-module_top-team__logo_tj20p__Stat {
  border-radius: 10px;
  margin: 7px;
}

.TopTeam-module_top-team__logo_tj20p__Stat div img {
  border-radius: 30px;
}

.TopTeam-module_top-team__logo-wrapper-main_4uiSp__Stat, .TopTeam-module_top-team__logo-wrapper-additional_kGwQZ__Stat {
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: 10px;
  display: flex;
  overflow: hidden;
}

.TopTeam-module_top-team__logo-wrapper-main_4uiSp__Stat {
  border: 1px solid var(--line-line);
  z-index: 1;
  background-color: var(--bg-block);
  width: 40px;
  height: 40px;
}

.TopTeam-module_top-team__logo-wrapper-main__without-second-img_2xwul__Stat {
  margin-right: 0;
}

.TopTeam-module_top-team__logo-wrapper-additional_kGwQZ__Stat {
  border: 5px solid var(--bg-component);
  width: 40px;
  height: 40px;
  margin-left: -10px;
}

.AboutMatch-module_about-match_jZ1sv__Stat {
  gap: 10px;
  width: 100%;
  display: flex;
  overflow-x: auto;
}

.AboutMatch-module_about-match_jZ1sv__Stat::-webkit-scrollbar {
  height: 5px;
}

.AboutMatch-module_about-match_jZ1sv__Stat::-webkit-scrollbar-thumb {
  background: var(--dropdown-stroke);
  border-radius: 5px;
}

.AboutMatch-module_about-match__broadcasters_6R-0C__Stat {
  min-width: 345px;
}

.MatchInfoWidget-module_match-info_UjiV8__Stat {
  background: var(--bg-block);
  border-radius: 15px;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  min-width: 240px;
  padding: 20px;
  display: flex;
}

.MatchInfoWidget-module_match-info__title_6QtWz__Stat {
  color: var(--text-primary);
}

.MatchInfoWidget-module_match-info__stadium-info_xdS3B__Stat {
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  height: 100%;
  display: flex;
}

.MatchInfoWidget-module_match-info__stadium-info__stadium-text_oklZL__Stat {
  color: var(--text-primary);
}

.MatchInfoWidget-module_match-info__stadium-info__visitors-data_DRgEr__Stat {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.MatchInfoWidget-module_match-info__stadium-info__capacity-text_RKCSN__Stat, .MatchInfoWidget-module_match-info__stadium-info__attendance-text_kRuQ7__Stat {
  color: var(--text-secondary);
}

.TimeToMatch-module_time_YJ3tW__Stat {
  color: rgba(0, 0, 0, 0);
  background: linear-gradient(99deg, #ea355c, #376dff);
  -webkit-background-clip: text;
  background-clip: text;
  width: -moz-fit-content;
  width: fit-content;
}

.RefereeInfoWidget-module_referees-info_CAlok__Stat {
  background: var(--bg-block);
  border-radius: 15px;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  min-width: 280px;
  padding: 20px;
  display: flex;
}

.RefereeInfoWidget-module_referees-info__title_OmDIY__Stat {
  color: var(--text-primary);
}

.RefereeInfoWidget-module_referees-info__main-referee_uGpOX__Stat {
  align-items: center;
  gap: 15px;
  display: flex;
}

.RefereeInfoWidget-module_referees-info__main-referee__logotypes_Ty-g4__Stat {
  align-items: center;
  display: flex;
}

.RefereeInfoWidget-module_referees-info__main-referee__logo_mceBm__Stat, .RefereeInfoWidget-module_referees-info__main-referee__country-logo_VuN2z__Stat {
  border-radius: 50%;
}

.RefereeInfoWidget-module_referees-info__main-referee__country-logo_VuN2z__Stat {
  border: 1px solid var(--line-line);
}

.RefereeInfoWidget-module_referees-info__main-referee__title_URXux__Stat {
  color: var(--text-primary);
}

.RefereeInfoWidget-module_referees-info__other-referees_K-zcv__Stat, .RefereeInfoWidget-module_referees-info__reserve-referee_mlUB2__Stat {
  flex-direction: column;
  display: flex;
}

.RefereeInfoWidget-module_referees-info__other-referees__text_WjUvc__Stat, .RefereeInfoWidget-module_referees-info__reserve-referee__text_p742T__Stat {
  color: var(--text-primary);
}

.RefereeInfoWidget-module_referees-info__other-referees__text__link_AxIN8__Stat, .RefereeInfoWidget-module_referees-info__reserve-referee__text__link_UTXKF__Stat {
  color: var(--text-link-active);
}

.RefereeInfoWidget-module_referees-info__other-referees__label_3GeLf__Stat, .RefereeInfoWidget-module_referees-info__reserve-referee__label_E33bR__Stat {
  color: var(--text-secondary);
}

.HeadToHeadCard-module_card_ba9Ib__Stat {
  background: var(--bg-component);
  border-radius: 10px;
  flex-direction: column;
  gap: 20px;
  width: 160px;
  padding: 20px 15px 15px 20px;
  display: flex;
}

.HeadToHeadCard-module_card__info_ywORb__Stat {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.HeadToHeadCard-module_card__info__header_6WB5Y__Stat {
  align-items: center;
  gap: 5px;
  display: flex;
}

.HeadToHeadCard-module_card__info__logos_t-aSI__Stat {
  display: flex;
}

.HeadToHeadCard-module_card__info__logos__logo_XZa5n__Stat {
  border-radius: 50%;
}

.HeadToHeadCard-module_card__info__penalty_sSssF__Stat {
  color: var(--text-error-live);
  background: var(--match-penalty-background-opacity-10);
  text-wrap: nowrap;
  border-radius: 5px;
  padding: 2px 5px;
}

.HeadToHeadCard-module_card__info__teams_fou1T__Stat {
  flex-direction: column;
  gap: 5px;
  display: flex;
}

.HeadToHeadCard-module_card__info__teams__team_zxyhY__Stat {
  justify-content: space-between;
  gap: 10px;
  display: flex;
}

.HeadToHeadCard-module_card__info__teams__team__text_YIoR-__Stat {
  color: var(--text-primary);
  -webkit-line-clamp: 1;
  word-break: break-all;
  -webkit-box-orient: vertical;
  max-width: 100px;
  display: -webkit-box;
  overflow: hidden;
}

.HeadToHeadCard-module_card__date_jbubz__Stat {
  color: var(--text-secondary);
}

.MatchEvents-module_match-events_kgj-5__Stat {
  background: var(--bg-block);
  border-radius: 15px;
  flex-direction: column;
  gap: 40px;
  padding: 20px;
  display: flex;
}

.MatchEvents-module_match-events__header_0UtxC__Stat {
  justify-content: space-between;
  display: flex;
}

.MatchEvents-module_match-events__header__title_CtwqD__Stat, .MatchEvents-module_match-events__header__text_sueFy__Stat {
  color: var(--text-primary);
}

.MatchEvents-module_match-events__header__text_sueFy__Stat {
  cursor: pointer;
  align-items: center;
  gap: 15px;
  display: flex;
}

@media (max-width: 660px) {
  .MatchEvents-module_match-events_kgj-5__Stat {
    padding: 10px;
  }
}

.MatchEventsDetails-module_footer_AUF-V__Stat {
  background: var(--bg-component);
  border-radius: 10px;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  display: flex;
}

.MatchEventsDetails-module_footer__header_8QaL1__Stat {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.MatchEventsDetails-module_footer__header__text_GV85R__Stat {
  text-transform: uppercase;
  color: var(--text-primary);
}

.MatchEventsDetails-module_footer__header__text--goal_CS3aS__Stat {
  color: var(--label-text-broadcast-text);
  background: var(--label-text-broadcast-bg);
  border-radius: 5px;
  padding: 0 5px;
}

.MatchEventsDetails-module_footer__description__start_mjqcp__Stat, .MatchEventsDetails-module_footer__description__goal-scorer_CL9F6__Stat, .MatchEventsDetails-module_footer__description__card_xRtx0__Stat, .MatchEventsDetails-module_footer__description__break_X6ExD__Stat, .MatchEventsDetails-module_footer__description__period_V30lw__Stat, .MatchEventsDetails-module_footer__description__end_PKF3B__Stat {
  color: var(--text-primary);
}

.MatchEventsDetails-module_footer__description__assist_LH0vE__Stat {
  color: var(--text-tertiary);
}

.MatchEventsDetails-module_footer__description__substitution--out_jafRL__Stat {
  color: var(--text-primary);
  text-decoration: line-through;
}

.MatchEventsDetails-module_footer__description__substitution--in_2joF3__Stat {
  color: var(--text-primary);
}

.MatchEventsDetails-module_footer__description__substitution__icon_MxCj4__Stat {
  padding: 0 2px;
}

.MatchEventsDetails-module_footer__description__substitution__icon_MxCj4__Stat path {
  fill: var(--icons-green);
}

.MatchEventsMain-module_match-events__container_S5oUx__Stat {
  gap: 10px;
  display: flex;
}

.MatchEventsMain-module_match-events__teams_keVK0__Stat {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.MatchEventsMain-module_match-events__main_NSpWm__Stat {
  overflow-x: hidden;
}

.MatchEventsMain-module_match-events__main__container_MdT7m__Stat {
  border-left: 2px var(--match-status-line-bg) solid;
}

.MatchEventsMain-module_match-events__main__container__item_N8nLM__Stat {
  align-items: center;
  display: flex;
}

.MatchEventsMain-module_match-events__main__container__line_rnjvv__Stat {
  border-left: none;
  border-right: none;
  border-top: solid var(--match-status-line-bg) 1px;
  border-bottom: solid var(--match-status-line-bg) 1px;
  width: 40px;
}

.MatchEventsMain-module_match-events__main__container__line--small_tkow5__Stat {
  width: 20px;
}

.MatchEvent-module_match-event_TFVuq__Stat {
  box-sizing: border-box;
  background: var(--bg-block);
  flex-direction: column;
  gap: 5px;
  display: flex;
}

.MatchEvent-module_match-event--home_yy9yb__Stat, .MatchEvent-module_match-event--away_g6bvC__Stat {
  color: var(--text-primary);
  text-align: center;
  height: 15px;
}

.EventIcon-module_icon-card_nHvjo__Stat {
  border: 2px var(--match-status-line-bg) solid;
  cursor: pointer;
  border-radius: 50%;
  width: 25px;
  height: 25px;
}

.EventIcon-module_icon-card--base_ZZBLB__Stat {
  justify-content: center;
  align-items: center;
  display: flex;
}

.EventIcon-module_icon-card--active_nR0EK__Stat {
  border: 2px var(--match-status-line-live) solid;
}

.EventIcon-module_icon-card__text_E-QiC__Stat {
  color: var(--text-secondary);
  padding: 5px;
}

.EventIcon-module_icon-card--yellow_Jtv-K__Stat path {
  fill: var(--icons-yellow);
}

.EventIcon-module_icon-card--second-yellow_ICvJC__Stat path:first-child {
  fill: var(--icons-red);
}

.EventIcon-module_icon-card--second-yellow_ICvJC__Stat path:nth-child(2) {
  fill: var(--icons-yellow);
}

.EventIcon-module_icon-card--red_unvoQ__Stat path {
  fill: var(--icons-red);
}

.EventIcon-module_icon-card--card_xYwMT__Stat {
  width: 7.5px;
  height: 9.75px;
}

.EventIcon-module_icon-card--whistle_gaQdi__Stat {
  width: 14px;
  height: 11px;
}

.EventIcon-module_icon-card--whistle_gaQdi__Stat path {
  fill: var(--icons-primary);
  stroke: var(--icons-primary);
}

.EventIcon-module_icon-card--ball_hYbGY__Stat {
  width: 15px;
  height: 15px;
}

.EventIcon-module_icon-card--ball_hYbGY__Stat circle {
  fill: var(--icons-white);
}

.EventIcon-module_icon-card--ball_hYbGY__Stat path {
  fill: var(--icons-black);
}

.EventIcon-module_icon-card--own-goal_EuODJ__Stat {
  width: 15px;
  height: 15px;
}

.EventIcon-module_icon-card--own-goal_EuODJ__Stat circle {
  fill: var(--icons-red);
}

.EventIcon-module_icon-card--own-goal_EuODJ__Stat path {
  fill: var(--icons-black);
}

.EventIcon-module_icon-card--penalty-goal_4UIj2__Stat path:first-child {
  fill: var(--icons-white);
}

.EventIcon-module_icon-card--penalty-goal_4UIj2__Stat path:nth-child(2) {
  fill: var(--icons-black);
}

.EventIcon-module_icon-card--penalty-goal_4UIj2__Stat path:nth-child(3) {
  fill: var(--icons-white);
}

.EventIcon-module_icon-card--penalty-goal_4UIj2__Stat path:nth-child(4) {
  fill: var(--icons-active);
}

.EventIcon-module_icon-card--substitution_GjwhW__Stat {
  width: 11px;
  height: 11px;
}

.EventIcon-module_icon-card--substitution_GjwhW__Stat path:first-child {
  fill: var(--icons-red);
}

.EventIcon-module_icon-card--substitution_GjwhW__Stat path:nth-child(2) {
  fill: var(--icons-green);
}

.EventIcon-module_icon-card--penalty-shootout_jOpwo__Stat {
  width: 15px;
  height: 4.53px;
}

.EventIcon-module_icon-card--penalty-shootout_jOpwo__Stat path {
  fill: var(--icons-primary);
}

.MatchHeader-module_match-header_S4Noz__Stat {
  z-index: 0;
  background-color: var(--bg-block);
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  color: var(--text-primary);
  border-radius: 15px;
  flex-direction: column;
  display: flex;
  position: relative;
  overflow: hidden;
}

.MatchHeader-module_match-header__blur_6n77V__Stat {
  filter: blur(8px);
}

.MatchHeader-module_match-header__match-status_sjPnX__Stat {
  color: var(--text-white);
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  padding: 2px 10px;
  display: flex;
}

.MatchHeader-module_match-header__match-status-wrapper_-ISU7__Stat {
  justify-content: center;
  display: flex;
}

.MatchHeader-module_match-header__match-status--delayed_kX78W__Stat {
  background-color: var(--icons-gray-dark);
}

.MatchHeader-module_match-header__match-status--live_WGodc__Stat {
  background-color: var(--icons-red);
}

.MatchHeader-module_match-header__header_eJ-wy__Stat {
  justify-content: space-between;
  gap: 20px;
  display: flex;
}

.MatchHeader-module_match-header__header__icons_PHKr9__Stat {
  gap: 10px;
  display: flex;
}

.MatchHeader-module_match-header__header__favourite-match_NSIVC__Stat, .MatchHeader-module_match-header__header__icons__icon-share_IDaO3__Stat {
  background-color: var(--bg-component);
  cursor: pointer;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  display: flex;
}

.MatchHeader-module_match-header__prev-match_G5kBJ__Stat {
  color: var(--text-secondary);
  text-align: center;
  height: 15px;
}

.MatchHeader-module_match-header__main-part_Rd-Ne__Stat {
  z-index: 1;
  flex-direction: column;
  gap: 10px;
  min-height: 200px;
  padding: 10px 10px 20px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.MatchHeader-module_match-header__state_m-JVC__Stat {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.MatchHeader-module_match-header__state__date_PRz4l__Stat, .MatchHeader-module_match-header__state__time_3-eaI__Stat, .MatchHeader-module_match-header__state__score_nQLm0__Stat {
  color: var(--text-primary);
}

.MatchHeader-module_match-header__state__unknown-time_XbPQU__Stat {
  color: var(--text-white);
  background: var(--icons-gray-dark);
  text-align: center;
  border-radius: 40px;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 150px;
  margin-top: 10px;
  padding: 2.5px 10px;
}

.MatchHeader-module_match-header__state__date_PRz4l__Stat {
  text-align: center;
}

.MatchHeader-module_match-header__state__score_nQLm0__Stat {
  justify-content: center;
  align-items: center;
  gap: 5px;
  display: flex;
}

.MatchHeader-module_match-header__bg_apzFC__Stat {
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.MatchHeader-module_match-header__about-tournament_27KrA__Stat {
  gap: 10px;
  display: flex;
}

.MatchHeader-module_match-header__about-tournament__title_9-llN__Stat {
  color: var(--text-primary);
}

.MatchHeader-module_match-header__about-tournament__title--wrapper_EaIS-__Stat {
  background-color: var(--bg-component);
  -webkit-line-clamp: 1;
  word-break: break-all;
  border-radius: 10px;
  -webkit-box-orient: vertical;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  padding: 5px 10px;
  display: -webkit-box;
  overflow: hidden;
}

.MatchHeader-module_match-header__main-block_0U8au__Stat {
  flex-direction: column;
  gap: 20px;
  display: flex;
}

.MatchHeader-module_match-header__match-info_riHrC__Stat {
  justify-content: center;
  gap: 20px;
  display: flex;
  position: relative;
}

.MatchHeader-module_match-header__scorers_kweX8__Stat {
  justify-content: center;
  gap: 20px;
  display: flex;
}

.MatchHeader-module_new_pqFu9__Stat:after {
  content: "new";
  letter-spacing: .15px;
  background: linear-gradient(30deg, var(--labels-new-blue), var(--labels-new-violet));
  width: 36px;
  height: 16px;
  color: var(--text-white);
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  font-size: 10px;
  font-weight: 580;
  line-height: 15px;
  display: flex;
}

@media screen and (max-width: 660px) {
  .MatchHeader-module_match-header_S4Noz__Stat {
    border-radius: 0;
  }

  .MatchHeader-module_match-header__state_m-JVC__Stat {
    justify-content: flex-start;
  }
}

.CountriesSearch-module_countries-search_ic-kd__Stat {
  background-color: var(--bg-block);
  border-radius: 15px;
  padding: 10px;
}

.CountriesSearch-module_countries-search_ic-kd__Stat ul {
  padding: 0;
}

.CountriesSearch-module_countries-search_ic-kd__Stat li {
  list-style: none;
}

.CountriesSearch-module_countries-search__search_EnWVM__Stat {
  margin-bottom: 20px;
}

.CountriesSearch-module_countries-search__search_EnWVM__Stat input {
  height: 40px !important;
}

.CountriesSearch-module_countries-search__list_0WGaM__Stat {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.CountriesSearch-module_countries-search__country_H8-uG__Stat {
  cursor: pointer;
  align-items: center;
  gap: 10px;
  height: 40px;
  padding: 10px 15px;
  display: flex;
}

.CountriesSearch-module_countries-search__country-name_d09sw__Stat {
  color: var(--list-text);
}

.CountriesSearch-module_countries-search__country-flag_tJq-D__Stat {
  border-radius: 50%;
}

.FavoriteMatchMark-module_favorite-mark_5e97h__Stat {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding: 0 10px;
  display: flex;
}

.FavoriteMatchMark-module_favorite-mark--empty_ZDaR-__Stat {
  fill: var(--icons-primary);
}

.FavoriteMatchMark-module_favorite-mark--filled_-cg3A__Stat {
  fill: var(--icons-red);
}

.FavoritesPlaceholder-module_favorites-placeholder_99Vg1__Stat {
  color: var(--text-primary);
  flex-direction: column;
  gap: 20px;
  display: flex;
}

.FavoritesPlaceholder-module_favorites-placeholder__bg-elipses_qZuYX__Stat {
  position: absolute;
  top: 0;
  left: 0;
}

.FavoritesPlaceholder-module_favorites-placeholder__heart-pointer_fH-fp__Stat {
  width: 35px;
  height: 30px;
}

.FavoritesPlaceholder-module_favorites-placeholder__bg-bell_SplYW__Stat {
  height: 65px;
  position: absolute;
  top: 0;
  right: 0;
}

.FavoritesPlaceholder-module_favorites-placeholder__instruction_-8Prj__Stat svg {
  min-width: 30px;
}

.FavoritesPlaceholder-module_favorites-placeholder__instruction_-8Prj__Stat, .FavoritesPlaceholder-module_favorites-placeholder__subscribe_Ka6PM__Stat {
  background-color: var(--bg-block);
  border-radius: 15px;
  align-items: center;
  gap: 20px;
  padding: 20px;
  display: flex;
}

.FavoritesPlaceholder-module_favorites-placeholder__subscribe_Ka6PM__Stat {
  flex-direction: column;
  align-items: flex-start;
  min-height: 120px;
  padding: 20px 50px 20px 20px;
  position: relative;
  overflow: hidden;
}

.FavoritesPlaceholder-module_favorites-placeholder__subscribe-text_y2078__Stat {
  z-index: 1;
}

.FavoritesPlaceholder-module_favorites-placeholder__apps-link_8GkpW__Stat {
  z-index: 1;
  width: 250px;
}

.MatchesList-module_matches-list_MbKC9__Stat {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.MatchesList-module_matches-list__placeholder_mAPa4__Stat {
  background-color: var(--bg-block);
  color: var(--text-primary);
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 20px;
  display: flex;
}

.MatchesList-module_matches-list__placeholder-icon_XVoWq__Stat {
  width: 20px;
  height: 20px;
}

.CountriesWidget-module_countries-widget_luSnr__Stat {
  background-color: var(--bg-block);
  border-radius: 15px;
  height: 130px;
  padding: 20px;
  overflow: hidden;
}

.CountriesWidget-module_countries-widget_luSnr__Stat ul {
  padding: 0;
}

.CountriesWidget-module_countries-widget_luSnr__Stat li {
  list-style: none;
}

.CountriesWidget-module_countries-widget__head_EME5W__Stat {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  display: flex;
}

.CountriesWidget-module_countries-widget__title_PbFev__Stat {
  color: var(--text-primary);
}

.CountriesWidget-module_countries-widget__all-countries_gXcVi__Stat {
  color: var(--text-secondary);
  cursor: pointer;
  align-items: center;
  gap: 5px;
  display: flex;
}

.CountriesWidget-module_countries-widget__all-countries_gXcVi__Stat:hover path {
  fill: var(--text-link-active);
}

.CountriesWidget-module_countries-widget__list_fzXrn__Stat {
  align-items: center;
  gap: 10px;
  display: flex;
}

.CountriesWidget-module_countries-widget__country_C-J0m__Stat {
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  min-width: 60px;
  max-width: 60px;
  height: 50px;
  display: flex;
}

.CountriesWidget-module_countries-widget__country-name_eP-As__Stat {
  max-width: 60px;
  color: var(--text-secondary);
  overflow-wrap: break-word;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.CountriesWidget-module_countries-widget__country-flag_VZ7Fh__Stat {
  border-radius: 50%;
}

@media (max-width: 660px) {
  .CountriesWidget-module_countries-widget_luSnr__Stat {
    width: 100%;
    height: 110px;
    padding: 10px;
    overflow: hidden;
  }

  .CountriesWidget-module_countries-widget__list_fzXrn__Stat {
    overflow: scroll;
  }

  .CountriesWidget-module_countries-widget__list_fzXrn__Stat::-webkit-scrollbar {
    background: none;
    width: 0;
  }
}

.MatchesWidget-module_matches-widget_7fQHQ__Stat {
  background: var(--bg-block);
  border-radius: 15px;
  overflow: hidden;
}

.MatchesWidget-module_matches-widget__header_BpA2z__Stat {
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px;
  display: flex;
}

.MatchesWidget-module_matches-widget__header__general-info_T3oEF__Stat {
  align-items: flex-start;
  gap: 10px;
  display: flex;
}

.MatchesWidget-module_matches-widget__header__general-info__text-block_ybfOw__Stat {
  flex-direction: column;
  display: flex;
}

.MatchesWidget-module_matches-widget__header--info_Qw0tO__Stat, .MatchesWidget-module_matches-widget__header--table_VSDf1__Stat {
  align-items: center;
  display: flex;
}

.MatchesWidget-module_matches-widget__header--info_Qw0tO__Stat {
  gap: 10px;
}

.MatchesWidget-module_matches-widget__header--table_VSDf1__Stat {
  gap: 5px;
}

.MatchesWidget-module_matches-widget__header--table_VSDf1__Stat svg {
  fill: var(--icons-gray-dark);
  rotate: 270deg;
}

.MatchesWidget-module_matches-widget__text_AbbXA__Stat {
  color: var(--text-primary);
}

.MatchesWidget-module_matches-widget__table-text_oQYDz__Stat {
  color: var(--bg-prime);
}

.MatchesWidget-module_matches-widget__stat-text_CggwQ__Stat {
  border-top: 1px solid var(--line-line);
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 40px;
  display: flex;
}

.MatchesWidget-module_matches-widget__stat-text_CggwQ__Stat path {
  fill: var(--text-primary);
}

.MatchesWidget-module_matches-widget__stat-text_CggwQ__Stat:hover path {
  fill: var(--text-link-active);
}

.MatchRow-module_match-row_aDMhH__Stat {
  border-top: 1px solid var(--line-line);
  align-items: center;
  padding-left: 10px;
  display: flex;
}

.MatchRow-module_match-row__content-wrapper_Pn550__Stat {
  width: calc(100% - 40px);
}

.MatchRow-module_match-row__content_rp-qK__Stat {
  grid-column-gap: 5px;
  background: var(--bg-block);
  grid-template-columns: 1fr 65px 1fr;
  justify-content: center;
  align-items: center;
  height: 40px;
  display: grid;
}

.MatchRow-module_match-row__winner_s4YQw__Stat {
  align-items: center;
  width: 6px;
  height: 10px;
  display: flex;
}

.MatchRow-module_match-row__winner_s4YQw__Stat svg {
  fill: var(--icons-active);
}

.MatchRow-module_match-row__home_AKm18__Stat {
  text-align: right;
  justify-content: flex-end;
}

.MatchRow-module_match-row__home-winner_XgAP0__Stat {
  transform: rotate(180deg);
}

.MatchRow-module_match-row__status_ajtc-__Stat {
  flex-direction: column;
  align-items: center;
  width: 65px;
  display: flex;
}

.MatchRow-module_match-row__status__score_lSaoJ__Stat {
  justify-content: center;
  display: flex;
}

.MatchRow-module_match-row__home_AKm18__Stat, .MatchRow-module_match-row__away_FJ57I__Stat {
  word-break: break-word;
  align-items: center;
  gap: 5px;
  display: flex;
}

.MatchRow-module_match-row__team-name_BxfgY__Stat {
  color: var(--text-primary);
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.MatchRow-module_match-row__team-logo_BViGH__Stat div {
  display: flex;
}

.MatchRow-module_match-row__score_7e3hT__Stat {
  justify-content: center;
  align-items: center;
  display: flex;
}

.MatchRow-module_match-row__period_LkB-6__Stat {
  color: var(--text-secondary);
}

.MatchRow-module_match-row__score_7e3hT__Stat {
  color: var(--text-primary);
}

.MatchRow-module_match-row__caption-text_Mfd64__Stat {
  color: var(--text-secondary);
  flex-direction: column;
  align-items: center;
  display: flex;
}

.MatchRow-module_match-row__caption-wrapper_BrPMu__Stat {
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  display: flex;
}

.MatchRow-module_match-row__current-minute_CZsGP__Stat {
  color: var(--text-error-live);
}

.MatchesWidgetLoader-module_match-widget_LQXDF__Stat {
  background-color: var(--bg-block);
  border-radius: 15px;
}

.MatchesWidgetLoader-module_match-widget__header_sk3qY__Stat {
  height: 40px;
  padding: 10px;
}

.MatchesWidgetLoader-module_match-widget__row_3BuxK__Stat {
  border-top: 1px solid var(--line-line);
  justify-content: center;
  height: 40px;
  padding: 10px 40px 10px 10px;
  display: flex;
}

.HeaderTab-module_tab_hLceL__Stat {
  width: 100%;
  height: 100%;
  color: var(--text-secondary);
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
}

.HeaderTab-module_tab__active_r7fgE__Stat {
  color: var(--text-link-active);
  position: relative;
}

.HeaderTab-module_tab__active_r7fgE__Stat:after {
  content: "";
  background-color: var(--line-active);
  width: 100%;
  height: 2px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.HeaderTab-module_tab_hLceL__Stat svg {
  fill: var(--icons-red);
  margin-right: 5px;
}

@media (max-width: 660px) {
  .HeaderTab-module_tab_hLceL__Stat {
    width: auto;
    min-width: 50px;
    padding: 0 5px;
  }
}

.CountriesList-module_countries-list_nBdC5__Stat {
  background-color: var(--bg-block);
  border-radius: 15px;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  display: flex;
}

.CountriesList-module_countries-list_nBdC5__Stat ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.CountriesList-module_countries-list__search_BbDyu__Stat input {
  height: 40px !important;
}

.CountriesList-module_countries-list__item_vWg6a__Stat {
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 10px 15px;
  display: flex;
}

.CountriesList-module_countries-list__item_vWg6a__Stat svg {
  fill: var(--icons-primary);
  transform: rotate(270deg);
}

.CountriesList-module_countries-list__item--title_GUb5q__Stat {
  color: var(--list-text);
}

.MatchScheduledDate-module_match-row__date_nLPMS__Stat {
  text-align: center;
  color: var(--text-secondary);
  justify-content: center;
  align-items: center;
  display: flex;
}

.MatchScheduledDate-module_match-row__date-fallback_pKuKx__Stat {
  background-color: var(--bg-component);
  border-radius: 5px;
  width: 35px;
  height: 15px;
}

.GoalsList-module_goals-list_DmFWG__Stat {
  flex-direction: column;
  gap: 5px;
  width: 100%;
  display: flex;
}

.GoalsList-module_goals-list__icon_oYK59__Stat {
  width: 20px;
  height: 20px;
}

.GoalsList-module_goals-list--home_FCkLF__Stat {
  align-items: flex-end;
}

.GoalsList-module_scorer_ebBgs__Stat {
  color: var(--text-primary);
  gap: 5px;
  display: flex;
}

.GoalsList-module_scorer--away_YI-JI__Stat {
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.GoalsList-module_scorer__last-name_ITGJ7__Stat {
  color: var(--text-primary);
  flex-direction: column;
  display: flex;
}

.GoalsList-module_scorer-and-additional-info_sDaVs__Stat {
  flex-direction: column;
  display: flex;
}

.GoalsList-module_scorer-and-additional-info--home_BriyQ__Stat {
  align-items: flex-end;
}

.GoalsList-module_scorer--minute_6bF7V__Stat {
  color: var(--text-primary);
}

.GoalsList-module_scorer__additional-info_qpHKT__Stat {
  color: var(--text-secondary);
}

.MatchPoll-module_match-poll_ikyih__Stat {
  flex-direction: column;
  align-items: center;
  gap: 20px;
  display: flex;
}

.MatchPoll-module_match-poll__header_Fsmx-__Stat {
  color: var(--text-primary);
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  display: flex;
}

.MatchPollLoader-module_match-poll-loader_RwBFw__Stat {
  justify-content: center;
  align-items: center;
  height: 85px;
  display: flex;
}

.PollOptions-module_poll-options_pw4WV__Stat {
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  display: flex;
}

.PollOptions-module_poll-options__vote-button_-tH1-__Stat {
  cursor: pointer;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  width: 105px;
  height: 30px;
  display: flex;
}

.PollOptions-module_poll-options__vote-button--home_ymYsT__Stat {
  background-color: var(--match-team-1-bg);
  color: var(--match-team-1-text);
}

.PollOptions-module_poll-options__vote-button--draw_b1dzK__Stat {
  background-color: var(--bg-component);
  color: var(--text-primary);
}

.PollOptions-module_poll-options__vote-button--away_G1-l4__Stat {
  background-color: var(--match-team-2-bg);
  color: var(--match-team-2-text);
}

.PollResults-module_poll-results_3rldH__Stat {
  align-items: center;
  gap: 2px;
  width: 340px;
  margin-bottom: 10px;
  display: flex;
}

.PollResults-module_poll-results__voted-result_DO02H__Stat {
  width: var(--width);
  justify-content: center;
  align-items: center;
  height: 30px;
  display: flex;
}

.PollResults-module_poll-results__voted-result_DO02H__Stat svg path {
  stroke: var(--icons-white);
}

.PollResults-module_poll-results__voted-result--home_HhlF7__Stat {
  background-color: var(--match-team-1-bg);
  color: var(--match-team-1-text);
  border-radius: 15px 0 0 15px;
}

.PollResults-module_poll-results__voted-result--draw_1FvRv__Stat {
  background-color: var(--bg-component);
  color: var(--text-primary);
}

.PollResults-module_poll-results__voted-result--draw_1FvRv__Stat svg path {
  stroke: var(--icons-primary);
}

.PollResults-module_poll-results__voted-result--away_Rc6m3__Stat {
  background-color: var(--match-team-2-bg);
  color: var(--match-team-2-text);
  border-radius: 0 15px 15px 0;
}

.PollResults-module_poll-results__voted-result--one-option_ua0Ud__Stat {
  border-radius: 15px;
}

.PollResults-module_poll-results__voted-result--two-option-left_0ij1H__Stat {
  border-radius: 15px 0 0 15px;
}

.PollResults-module_poll-results__voted-result--two-option-right_r126s__Stat {
  border-radius: 0 15px 15px 0;
}

.TeamMainInfo-module_team-main-info_g1oQe__Stat {
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  width: 100%;
  display: flex;
}

.TeamMainInfo-module_team-main-info__name_6xLQn__Stat {
  color: var(--text-primary);
}

.TeamMainInfo-module_team-main-info__away_KJ9gd__Stat {
  flex-direction: row-reverse;
}

.TeamMainInfo-module_team-main-info__cards-red_z-DKQ__Stat path {
  fill: var(--icons-red);
}

.TeamMainInfo-module_team-main-info__main_w047V__Stat {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.TeamMainInfo-module_team-main-info__main--home_g1UEf__Stat {
  align-items: flex-end;
}

.TeamMainInfo-module_team-main-info__team-name-wrapper_AlaWU__Stat {
  -webkit-line-clamp: 2;
  word-break: break-word;
  text-align: center;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

@media screen and (max-width: 660px) {
  .TeamMainInfo-module_team-main-info_g1oQe__Stat {
    flex-direction: column-reverse;
    gap: 10px;
  }

  .TeamMainInfo-module_team-main-info__name_6xLQn__Stat {
    text-align: center;
  }

  .TeamMainInfo-module_team-main-info__main_w047V__Stat {
    align-items: center;
  }
}

.MatchLineup-module_match-lineup_oni6S__Stat {
  background-color: var(--bg-block);
  border-radius: 15px;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  display: flex;
}

.MatchLineup-module_match-lineup__header_Oxm9X__Stat {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.MatchLineup-module_match-lineup__header-title_pr3b5__Stat {
  color: var(--text-primary);
}

.MatchLineup-module_match-lineup__header__tabs_UapoV__Stat {
  cursor: pointer;
  gap: 10px;
  display: flex;
}

.MatchLineup-module_match-lineup__header__tab_nM46Z__Stat {
  border: 1px solid var(--buttons-tab-switch-default-stroke);
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  display: flex;
}

.MatchLineup-module_match-lineup__header__tab--active_7MP7G__Stat {
  border: 1px solid var(--buttons-tab-switch-active-bg);
  background-color: var(--buttons-tab-switch-active-bg);
}

.MatchLineup-module_match-lineup__header__tab--active_7MP7G__Stat path {
  fill: var(--icons-white);
}

.MatchLineup-module_match-lineup__header__warning_4N9-P__Stat {
  width: 100%;
}

@media (max-width: 660px) {
  .MatchLineup-module_match-lineup_oni6S__Stat {
    padding: 10px;
  }
}

.LineupList-module_lineup-list_4KZtq__Stat {
  background-color: var(--bg-block);
  flex-direction: column;
  gap: 20px;
  width: 100%;
  display: flex;
}

.LineupList-module_lineup-list__teams_hYRty__Stat {
  gap: 20px;
  display: flex;
}

.LineupList-module_lineup-list__teams-toggle_8EeGx__Stat {
  display: none;
}

.LineupList-module_lineup-list__provided-by-opta_FF3TK__Stat {
  color: var(--text-tertiary);
}

@media screen and (max-width: 660px) {
  .LineupList-module_lineup-list__teams-toggle_8EeGx__Stat {
    display: flex;
  }
}

.TeamLineup-module_team-lineup_eqVmj__Stat {
  flex-direction: column;
  gap: 20px;
  width: 100%;
  display: flex;
}

.TeamLineup-module_team-lineup__title_GtrqQ__Stat {
  color: var(--text-primary);
  cursor: pointer;
  align-items: center;
  gap: 5px;
  display: flex;
}

.TeamLineup-module_team-lineup__table_kv0zg__Stat {
  background-color: var(--bg-block);
  height: -moz-fit-content;
  height: fit-content;
}

.TeamLineup-module_team-lineup__table__title_C7wzJ__Stat {
  background-color: var(--bg-component);
  color: var(--text-secondary);
  padding: 10px;
}

@media screen and (max-width: 660px) {
  .TeamLineup-module_team-lineup--mobile_4YpKY__Stat, .TeamLineup-module_team-lineup__title_GtrqQ__Stat {
    display: none;
  }
}

.PersonLine-module_person_RsQ-R__Stat {
  z-index: 1;
  background-color: var(--bg-block);
  border-top: 1px solid var(--line-line);
  align-items: center;
  gap: 5px;
  padding: 10px;
  display: flex;
}

.PersonLine-module_person--with-substituted_0X6Va__Stat {
  padding-bottom: 35px;
}

.PersonLine-module_person__substitution-time_vkEcX__Stat, .PersonLine-module_person__goal-quantity_ZScCT__Stat, .PersonLine-module_person__own-goal-quantity_75S0J__Stat {
  color: var(--text-primary);
}

.PersonLine-module_person__info_nGChW__Stat {
  flex-direction: column;
  justify-content: center;
  padding-left: 5px;
  display: flex;
  position: relative;
}

.PersonLine-module_person__info__main_73f-c__Stat {
  align-items: center;
  gap: 5px;
  display: flex;
}

.PersonLine-module_person__captain_YKHvU__Stat {
  width: 12px;
  height: 12px;
}

.PersonLine-module_person__name_3d-pw__Stat, .PersonLine-module_person__name--substituted_ORvx6__Stat {
  cursor: pointer;
  -webkit-line-clamp: 1;
  word-break: break-all;
  -webkit-box-orient: vertical;
  width: -moz-fit-content;
  width: fit-content;
  display: -webkit-box;
  overflow: hidden;
}

.PersonLine-module_person__name_3d-pw__Stat {
  color: var(--text-primary);
}

.PersonLine-module_person__name--substituted_ORvx6__Stat {
  color: var(--disabled-text);
  cursor: default;
  text-decoration: line-through;
  position: absolute;
  bottom: -25px;
}

.PersonLine-module_person__icons_zLu-R__Stat {
  align-items: center;
  gap: 5px;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
}

.PersonLine-module_person__icon_0rQ8Q__Stat {
  justify-content: center;
  display: flex;
}

.PersonLine-module_person__icon__substitution_feYhW__Stat {
  width: 11px;
  height: 11px;
}

.PersonLine-module_person__icon__goal_Zl8dE__Stat, .PersonLine-module_person__icon__own-goal_-r1FE__Stat, .PersonLine-module_person__icon__injury_QkiiF__Stat {
  width: 15px;
  height: 15px;
}

.PersonLine-module_person__icon__own-goal_-r1FE__Stat path, .PersonLine-module_person__icon__injury_QkiiF__Stat path {
  fill: var(--icons-red);
}

.PersonLine-module_person__icon__card_ghhH6__Stat {
  width: 8px;
  height: 10px;
}

.PersonLine-module_person__icon__card--yellow_4gjPN__Stat path {
  fill: var(--icons-yellow);
}

.PersonLine-module_person__icon__card--second-yellow_UyYg6__Stat path:first-child {
  fill: var(--icons-red);
}

.PersonLine-module_person__icon__card--second-yellow_UyYg6__Stat path:nth-child(2) {
  fill: var(--icons-yellow);
}

.PersonLine-module_person__icon__card--red_EhZAk__Stat path {
  fill: var(--icons-red);
}

.PersonLine-module_person__nationalities_nHGHG__Stat, .PersonLine-module_person__avatar_NeHiY__Stat {
  width: -moz-fit-content;
  width: fit-content;
}

.PersonLine-module_person__amplua_LcLij__Stat {
  color: var(--text-secondary);
  align-items: center;
  display: flex;
}

.PersonLine-module_person__arrow-wrapper_GZpEV__Stat {
  cursor: pointer;
  justify-content: flex-end;
  align-items: center;
  width: 20px;
  min-width: 20px;
  height: 20px;
  margin-left: auto;
  display: flex;
}

.PersonLine-module_person__arrow-wrapper_GZpEV__Stat svg {
  width: 10px;
  height: 12px;
  transform: rotate(90deg);
}

.PersonLine-module_person__arrow-wrapper_GZpEV__Stat svg path {
  fill: var(--icons-primary);
}

.PersonLine-module_person-stat_Nju3x__Stat {
  border-top: 1px solid var(--line-line);
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  display: flex;
}

.PersonLine-module_person-stat__line_uIIBT__Stat {
  justify-content: space-between;
  display: flex;
}

.PersonLine-module_person-stat__line__all-stat-wrapper_Dp9v-__Stat {
  width: 100%;
  color: var(--text-link-active);
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
}

.PersonLine-module_person-stat__line__name_8793K__Stat, .PersonLine-module_person-stat__line__value_HkjWz__Stat {
  color: var(--text-primary);
  -webkit-line-clamp: 1;
  word-break: break-all;
  -webkit-box-orient: vertical;
  width: -moz-fit-content;
  width: fit-content;
  display: -webkit-box;
  overflow: hidden;
}

.PersonNationalities-module_person-nationalities_j-KQh__Stat {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  height: 100%;
  display: flex;
}

.PersonNationalities-module_person-nationalities__country-icon_-NSZ7__Stat {
  border-radius: 2px;
}

.LineupOnField-module_lineup-on-field_r9b34__Stat {
  gap: 20px;
  width: 100%;
  display: flex;
}

.LineupOnField-module_lineup-on-field__wrapper_T9hlx__Stat {
  flex-direction: column;
  gap: 20px;
  display: flex;
}

.LineupOnField-module_lineup-on-field__provided-by-opta_FixqP__Stat {
  color: var(--text-tertiary);
}

@media screen and (max-width: 660px) {
  .LineupOnField-module_lineup-on-field_r9b34__Stat {
    flex-direction: column;
    align-items: center;
  }
}

.PlayersList-module_players-lists_lZ0Hx__Stat {
  flex-direction: column;
  gap: 20px;
  width: 100%;
  display: flex;
}

.PlayersList-module_players-lists__title_LQgbT__Stat {
  cursor: pointer;
  background-color: var(--bg-component);
  color: var(--text-secondary);
  padding: 10px;
  display: flex;
}

.PlayersList-module_players-lists__team_3g1rn__Stat {
  border-top: 1px solid var(--line-line);
  padding: 10px;
}

.PlayersList-module_players-lists__team-home_kxYt-__Stat {
  background-color: var(--football-field-t-shirt-1);
  color: var(--text-white);
}

.PlayersList-module_players-lists__team-away_ccMOL__Stat {
  background-color: var(--football-field-t-shirt-2);
  color: var(--text-black);
}

.PlayersList-module_players-lists__arrow-wrapper_q7qNx__Stat {
  justify-content: flex-end;
  align-items: center;
  width: 20px;
  min-width: 20px;
  height: 20px;
  margin-left: auto;
  display: flex;
}

.PlayersList-module_players-lists__arrow-wrapper_q7qNx__Stat svg {
  width: 10px;
  height: 12px;
  transform: rotate(90deg);
}

.PlayersList-module_players-lists__arrow-wrapper_q7qNx__Stat svg path {
  fill: var(--icons-primary);
}

.Field-module_field_MdWHe__Stat {
  background-color: var(--football-field-bg);
  border-radius: 15px;
  width: 320px;
  min-width: 320px;
  height: 590px;
  position: relative;
}

.Field-module_field__wrapper_jMOmP__Stat {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.Field-module_field__field-marking_C2juJ__Stat {
  border: 1px solid var(--field-line);
  position: absolute;
}

.Field-module_field__field-marking--circle_1NJ7c__Stat {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
}

.Field-module_field__field-marking--left-goal_lJHAn__Stat {
  border-top: none;
  width: 60px;
  height: 10px;
  top: 0;
  left: calc(50% - 30px);
}

.Field-module_field__field-marking--right-goal_dgim5__Stat {
  border-bottom: none;
  width: 60px;
  height: 10px;
  top: auto;
  bottom: 0;
  left: calc(50% - 30px);
}

.Field-module_field__field-marking--center-line_MBnSF__Stat {
  border-bottom: none;
  width: 100%;
  height: 0;
  top: 50%;
  left: 0;
}

.Field-module_field__new-in-start_AG9Kq__Stat {
  color: var(--text-secondary);
  width: 320px;
}

.Field-module_field__new-in-start-icon_neZi0__Stat {
  color: var(--text-white);
  background-color: var(--football-field-bg);
  border-radius: 5px;
  padding: 2px 5px;
}

@media screen and (max-width: 660px) {
  .Field-module_field_MdWHe__Stat {
    width: 100%;
    min-width: 100%;
  }

  .Field-module_field__wrapper_jMOmP__Stat {
    align-items: center;
    width: 100%;
  }

  .Field-module_field__new-in-start_AG9Kq__Stat {
    width: 100%;
  }
}

.TeamLogoAndFormation-module_team__logo--home_KRuJw__Stat, .TeamLogoAndFormation-module_team__logo--away_ArqJ1__Stat {
  background-color: var(--bg-white);
  border-radius: 40px;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  display: flex;
  position: absolute;
}

.TeamLogoAndFormation-module_team__logo--home_KRuJw__Stat {
  top: 10px;
  left: 10px;
}

.TeamLogoAndFormation-module_team__logo--away_ArqJ1__Stat {
  bottom: 10px;
  left: 10px;
}

.TeamLogoAndFormation-module_team__formation--home_F3vfL__Stat, .TeamLogoAndFormation-module_team__formation--away_lBA9M__Stat {
  background-color: var(--bg-white-opacity-20);
  color: var(--text-white);
  border-radius: 5px;
  padding: 5px;
  position: absolute;
}

.TeamLogoAndFormation-module_team__formation--home_F3vfL__Stat {
  top: 10px;
  right: 10px;
}

.TeamLogoAndFormation-module_team__formation--away_lBA9M__Stat {
  bottom: 10px;
  right: 10px;
}

.FieldTeamFormation-module_player_FbmYd__Stat {
  width: var(--width);
  cursor: pointer;
  bottom: var(--bottom);
  left: var(--left);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

.FieldTeamFormation-module_player__jersey-wrapper_etL40__Stat {
  position: relative;
}

.FieldTeamFormation-module_player__number--home_Lnyfc__Stat, .FieldTeamFormation-module_player__number--away_BS0pU__Stat {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%);
}

.FieldTeamFormation-module_player__number--home_Lnyfc__Stat {
  color: var(--icons-white);
}

.FieldTeamFormation-module_player__number--away_BS0pU__Stat {
  color: var(--text-black);
}

.FieldTeamFormation-module_player__name_2NF7W__Stat {
  -webkit-line-clamp: 1;
  word-break: break-all;
  color: var(--text-white);
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.FieldTeamFormation-module_player__name-new-in-start_ulgyW__Stat {
  background-color: var(--bg-light-green-opacity-30);
  border-radius: 5px;
  padding: 0 5px;
}

.FieldTeamFormation-module_player__icon-substitution-wrapper_7mXj-__Stat, .FieldTeamFormation-module_player__icon-card-wrapper_QUdht__Stat, .FieldTeamFormation-module_player__icon-goal-wrapper_6q9gQ__Stat, .FieldTeamFormation-module_player__icon-captain-wrapper_RLxvD__Stat {
  justify-content: center;
  display: flex;
  position: absolute;
}

.FieldTeamFormation-module_player__icon__jersey--home_ykRmR__Stat path {
  fill: var(--football-field-t-shirt-1);
}

.FieldTeamFormation-module_player__icon__jersey--away_q36-f__Stat path {
  fill: var(--football-field-t-shirt-2);
}

.FieldTeamFormation-module_player__icon-substitution-wrapper_7mXj-__Stat {
  bottom: 0;
  left: -10px;
}

.FieldTeamFormation-module_player__icon-card-wrapper_QUdht__Stat {
  top: 0;
  right: -5px;
}

.FieldTeamFormation-module_player__icon-captain-wrapper_RLxvD__Stat {
  top: 0;
  left: -10px;
}

.FieldTeamFormation-module_player__icon-goal-wrapper_6q9gQ__Stat {
  bottom: 0;
  left: 23px;
}

.FieldTeamFormation-module_player__icon__substitution_U6Fn6__Stat {
  width: 11px;
  height: 11px;
}

.FieldTeamFormation-module_player__icon__substitution_U6Fn6__Stat path:last-child {
  fill: var(--icons-green-light);
}

.FieldTeamFormation-module_player__icon__goal_tkD3d__Stat, .FieldTeamFormation-module_player__icon__own-goal_6SbUh__Stat {
  width: 10px;
  height: 10px;
  margin-left: -5px;
}

.FieldTeamFormation-module_player__icon__own-goal_6SbUh__Stat path {
  fill: var(--icons-red);
}

.FieldTeamFormation-module_player__icon__captain_pCWBn__Stat {
  width: 12px;
  height: 12px;
}

.FieldTeamFormation-module_player__icon__card_ylObd__Stat {
  width: 8px;
  height: 10px;
}

.FieldTeamFormation-module_player__icon__card--yellow_qfmIV__Stat path {
  fill: var(--icons-yellow);
}

.FieldTeamFormation-module_player__icon__card--second-yellow_r-kUq__Stat path:first-child {
  fill: var(--icons-red);
}

.FieldTeamFormation-module_player__icon__card--second-yellow_r-kUq__Stat path:nth-child(2) {
  fill: var(--icons-yellow);
}

.FieldTeamFormation-module_player__icon__card--red_QgP1l__Stat path {
  fill: var(--icons-red);
}

.MatchShortStandings-module_match-short-standings_nv-Nk__Stat {
  background-color: var(--bg-block);
  border-radius: 15px;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  display: flex;
}

.MatchShortStandings-module_match-short-standings__title_cfWGq__Stat {
  color: var(--text-primary);
}

.MatchShortStandings-module_match-short-standings__table_uI-GH__Stat {
  border: 1px solid var(--line-line);
  border-radius: 10px;
  width: 100%;
  overflow: hidden;
}

@media (max-width: 660px) {
  .MatchShortStandings-module_match-short-standings_nv-Nk__Stat {
    padding: 10px;
  }
}

.Table-module_table--not-widget_oay8Q__Stat {
  display: grid;
  overflow-x: auto;
}

.Table-module_table--widget_xYTgm__Stat {
  flex-direction: column;
  display: flex;
}

.TableHeader-module_table-header_1B2F0__Stat {
  background-color: var(--bg-component);
  min-height: 40px;
  color: var(--text-secondary);
  text-align: center;
  justify-content: space-between;
  display: flex;
}

.TableHeader-module_table-header__team-wrapper_9J69U__Stat {
  z-index: 1;
  background-color: var(--bg-component);
  flex: 1 0 180px;
  width: 320px;
  margin-right: 10px;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
}

.TableHeader-module_table-header__team-wrapper_9J69U__Stat:after {
  z-index: 0;
  content: "";
  background-color: var(--bg-component);
  width: 5px;
  height: 100%;
  box-shadow: 4px 0 8px var(--table-shadow-bg);
  display: block;
  position: absolute;
  top: 0;
  left: 316px;
}

.TableHeader-module_table-header__team_Ih67g__Stat {
  z-index: 1;
  background-color: var(--bg-component);
  width: 100%;
  color: var(--text-secondary);
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 12px 0 12px 10px;
  overflow: hidden;
}

.TableHeader-module_table-header__metrics_NAied__Stat {
  display: flex;
}

.TableHeader-module_table-header__points_v8Y3q__Stat, .TableHeader-module_table-header__match-played_ML0yV__Stat, .TableHeader-module_table-header__wins_MuBAk__Stat, .TableHeader-module_table-header__draws_AmX7G__Stat, .TableHeader-module_table-header__losses_jXhP0__Stat, .TableHeader-module_table-header__goal-difference_3pvFG__Stat {
  width: 40px;
  color: var(--text-secondary);
  justify-content: center;
  align-items: center;
  display: flex;
}

.TableHeader-module_table-header__points_v8Y3q__Stat {
  color: var(--text-link-active);
}

.TableHeader-module_table-header__goals-for-goals-against_ZFxic__Stat {
  width: 70px;
  color: var(--text-secondary);
  justify-content: center;
  align-items: center;
  display: flex;
}

.TableHeader-module_table-header__last-five_jBsJV__Stat {
  width: 120px;
  color: var(--text-secondary);
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (max-width: 660px) {
  .TableHeader-module_table-header__team_Ih67g__Stat {
    width: 180px;
  }

  .TableHeader-module_table-header__team-wrapper_9J69U__Stat {
    flex: auto;
    width: 180px;
    max-width: 180px;
  }

  .TableHeader-module_table-header__team-wrapper_9J69U__Stat:after {
    left: 176px;
  }
}

.TableLine-module_table-line_Zi9nu__Stat {
  border-top: 1px solid var(--line-line);
  text-align: center;
  height: 40px;
  color: var(--text-primary);
  background-color: var(--bg-block);
  justify-content: space-between;
  align-items: center;
}

.TableLine-module_table-line--not-widget_3f4-M__Stat {
  display: flex;
}

.TableLine-module_table-line--widget_ymD0Y__Stat {
  grid-template-columns: 1fr 80px;
  display: grid;
}

.TableLine-module_table-line--active_-OjKq__Stat {
  background-color: var(--list-bg-active);
  color: var(--text-link-active);
}

.TableLine-module_table-line--active_-OjKq__Stat .TableLine-module_table-line__team-wrapper_hDy0Y__Stat, .TableLine-module_table-line--active_-OjKq__Stat .TableLine-module_table-line__team_WzsC-__Stat {
  background-color: var(--list-bg-active);
}

.TableLine-module_table-line--active_-OjKq__Stat .TableLine-module_table-line__team-link_4MKxs__Stat {
  color: var(--text-link-active);
}

.TableLine-module_table-line--outcome_UT3qJ__Stat .TableLine-module_table-line__team-wrapper_hDy0Y__Stat:before {
  content: "";
  width: 4px;
  height: 40px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.TableLine-module_table-line--secondary-main_Cw-hj__Stat .TableLine-module_table-line__team-wrapper_hDy0Y__Stat:before {
  background-color: var(--icons-leagues-champions-league);
}

.TableLine-module_table-line--secondary-dark_145ci__Stat .TableLine-module_table-line__team-wrapper_hDy0Y__Stat:before {
  background-color: var(--icons-leagues-champions-league-playoff);
}

.TableLine-module_table-line--yellow-light_94k3N__Stat .TableLine-module_table-line__team-wrapper_hDy0Y__Stat:before {
  background-color: var(--icons-leagues-european-cup-playoff);
}

.TableLine-module_table-line--yellow-main_LOz-e__Stat .TableLine-module_table-line__team-wrapper_hDy0Y__Stat:before {
  background-color: var(--icons-leagues-european-cup);
}

.TableLine-module_table-line--green-main_iU6YC__Stat .TableLine-module_table-line__team-wrapper_hDy0Y__Stat:before {
  background-color: var(--icons-leagues-conference-league);
}

.TableLine-module_table-line--green-light_O6BCj__Stat .TableLine-module_table-line__team-wrapper_hDy0Y__Stat:before {
  background-color: var(--icons-leagues-conference-league-playoff);
}

.TableLine-module_table-line--gray-light_oLKLS__Stat .TableLine-module_table-line__team-wrapper_hDy0Y__Stat:before {
  background-color: var(--icons-leagues-relegation);
}

.TableLine-module_table-line--red-main_flOsE__Stat .TableLine-module_table-line__team-wrapper_hDy0Y__Stat:before {
  background-color: var(--icons-leagues-left);
}

.TableLine-module_table-line__team-wrapper_hDy0Y__Stat {
  z-index: 1;
  background-color: var(--bg-block);
  align-items: center;
  height: 100%;
  margin-right: 10px;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
}

.TableLine-module_table-line__team-wrapper--not-widget_v5dpK__Stat {
  flex: 1 0 180px;
  width: 320px;
}

.TableLine-module_table-line__team-wrapper--not-widget_v5dpK__Stat:after {
  z-index: 0;
  content: "";
  width: 5px;
  height: 100%;
  box-shadow: 4px 0 8px var(--table-shadow-bg);
  display: block;
  position: absolute;
  top: 0;
  left: 316px;
}

.TableLine-module_table-line__team-wrapper--widget_1euJ8__Stat {
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
  overflow: hidden;
}

.TableLine-module_table-line__rank_iLEHz__Stat {
  text-align: center;
  width: 20px;
  color: var(--text-tertiary);
  margin: 0 5px;
}

.TableLine-module_table-line__rank-change_HvmZU__Stat {
  align-items: center;
  width: 15px;
  margin: 0;
  display: flex;
  position: relative;
}

.TableLine-module_table-line__rank-change--up_FjDWf__Stat svg {
  fill: var(--icons-green);
}

.TableLine-module_table-line__rank-change--down_65xPj__Stat svg {
  fill: var(--icons-red);
  transform: rotate(180deg);
}

.TableLine-module_table-line__rank-change--same_n6XnH__Stat:before {
  content: "";
  background-color: var(--icons-gray);
  border-radius: 1px;
  width: 8px;
  height: 2px;
  display: block;
  position: absolute;
  top: 50%;
  left: 3px;
}

.TableLine-module_table-line__team_WzsC-__Stat {
  z-index: 1;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: var(--bg-block);
  flex: 1;
  align-items: center;
  height: 100%;
  display: flex;
  overflow: hidden;
}

.TableLine-module_table-line__team--not-widget_UaXET__Stat {
  width: 100px;
}

.TableLine-module_table-line__team-link_4MKxs__Stat {
  max-height: 28px;
  color: var(--text-primary);
  text-overflow: ellipsis;
  white-space: pre-wrap;
  cursor: pointer;
  overflow-wrap: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.TableLine-module_table-line__team-logo-link_RPG7J__Stat {
  width: 24px;
  min-width: 24px;
  height: 24px;
  margin: 0 8px;
  position: relative;
}

.TableLine-module_table-line__metrics_q1TLN__Stat {
  display: flex;
}

.TableLine-module_table-line__match-played_UdP7-__Stat, .TableLine-module_table-line__wins_FqN-M__Stat, .TableLine-module_table-line__draws_50856__Stat, .TableLine-module_table-line__losses_lBgXE__Stat, .TableLine-module_table-line__goal-difference_zFu5c__Stat {
  width: 40px;
  color: var(--text-primary);
}

.TableLine-module_table-line__points_gKp8g__Stat {
  width: 40px;
  color: var(--text-link-active);
}

.TableLine-module_table-line__goals-for-goals-against_zPJ8l__Stat {
  width: 70px;
  color: var(--text-primary);
}

.TableLine-module_table-line__last-five_sijxM__Stat {
  justify-content: space-between;
  align-items: center;
  width: 120px;
  display: flex;
}

@media (max-width: 660px) {
  .TableLine-module_table-line__team-wrapper--not-widget_v5dpK__Stat {
    flex: auto;
    width: 180px;
    max-width: 180px;
  }

  .TableLine-module_table-line__team-wrapper--not-widget_v5dpK__Stat:after {
    left: 176px;
  }
}

.LastFive-module_last-five_CVQQC__Stat {
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 100%;
  display: flex;
}

.LastFive-module_last-five__item_xWd34__Stat {
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  display: flex;
}

.LastFive-module_last-five__item--win_tSlWj__Stat svg {
  fill: var(--icons-green);
}

.LastFive-module_last-five__item--loss_4poA5__Stat svg {
  fill: var(--icons-red);
}

.LastFive-module_last-five__item--draw_zUmtD__Stat svg {
  fill: var(--icons-gray);
}

.Odds-module_odds_vldYW__Stat {
  background: var(--bg-block);
  border-radius: 15px;
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

.Odds-module_odds__list_iitMU__Stat {
  background: var(--line-line);
  flex-direction: column;
  gap: 1px;
  display: flex;
}

.Odds-module_odds__banner_9r4RW__Stat {
  padding: 0 10px 10px;
}

.OddRow-module_row_BtYvF__Stat {
  background: var(--bg-block);
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 10px;
  display: flex;
}

.OddRow-module_row__odds_flFMC__Stat {
  flex: 1;
  gap: 10px;
  display: flex;
}

.Odd-module_odd_O5KdP__Stat {
  background: var(--bg-component);
  border-radius: 10px;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  min-width: 70px;
  height: 30px;
  padding: 5px 10px;
  display: flex;
}

.Odd-module_odd__label_nSvJS__Stat {
  color: var(--text-tertiary);
}

.Odd-module_odd__value_THYzj__Stat {
  color: var(--text-primary);
}

.Penalties-module_penalties_1G1nV__Stat {
  background: var(--bg-block);
  border-radius: 15px;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  display: flex;
}

.Penalties-module_penalties__teams_fOs26__Stat {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.Penalties-module_penalties__teams-logos_9VU4t__Stat {
  justify-content: space-between;
  display: flex;
}

.Penalties-module_penalties__title_i1QgV__Stat {
  color: var(--text-primary);
}

@media (max-width: 660px) {
  .Penalties-module_penalties_1G1nV__Stat {
    padding: 10px;
  }
}

.PenaltyLine-module_penalty-line_dSXEI__Stat {
  color: var(--text-primary);
  border-bottom: 1px solid var(--line-line);
  gap: 10px;
  width: 100%;
  padding: 12px 0;
  display: flex;
}

.PenaltyLine-module_penalty-line_dSXEI__Stat:last-child {
  border-bottom: none;
}

.PenaltyLine-module_penalty-line__result-wrapper_1rSqZ__Stat {
  color: var(--text-secondary);
  justify-content: space-between;
  align-items: center;
  width: 120px;
  min-width: 120px;
  display: flex;
}

.PenaltyLine-module_penalty-line__score_EdJjU__Stat {
  width: 35px;
  min-width: 35px;
  color: var(--text-primary);
  justify-content: center;
  align-items: center;
  display: flex;
}

.PenaltyLine-module_penalty-line__shootout-icon-wrapper_vkGoj__Stat {
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  display: flex;
}

.PenaltyLine-module_penalty-line__shootout--goal_TMpML__Stat {
  border: 1px solid var(--icons-green);
}

.PenaltyLine-module_penalty-line__shootout--missed_p0ZNN__Stat {
  border: 1px solid var(--bg-block);
}

.PenaltyLine-module_penalty-line__shootout--missed_p0ZNN__Stat svg path {
  fill: var(--icons-gray-dark);
}

.PenaltyLine-module_penalty-line__home-player-name_a7BBJ__Stat, .PenaltyLine-module_penalty-line__away-player-name_hV916__Stat {
  color: var(--text-primary);
}

.PenaltyLine-module_penalty-line__home-player-name--wrapper_NE5ZY__Stat, .PenaltyLine-module_penalty-line__away-player-name--wrapper_3JNYe__Stat {
  color: var(--text-primary);
  -webkit-line-clamp: 1;
  word-break: break-all;
  -webkit-box-orient: vertical;
  width: 100%;
  display: -webkit-box;
  overflow: hidden;
}

.PenaltyLine-module_penalty-line__away-player-name--wrapper_3JNYe__Stat {
  text-align: right;
}

.Statistics-module_statistics_lTgld__Stat {
  background: var(--bg-block);
  border-radius: 15px;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  display: flex;
}

.Statistics-module_statistics__title_wV80U__Stat {
  color: var(--text-primary);
}

@media (max-width: 660px) {
  .Statistics-module_statistics_lTgld__Stat {
    padding: 10px;
  }
}

.StatisticsHeader-module_header_PtamS__Stat {
  justify-content: space-between;
  display: flex;
}

.StatisticsHeader-module_header__home_llNmR__Stat, .StatisticsHeader-module_header__away_fR-Ix__Stat {
  align-items: center;
  gap: 10px;
  display: flex;
}

.StatisticsHeader-module_header__cards_UeUY0__Stat {
  align-items: center;
  gap: 3px;
  display: flex;
}

.StatisticsHeader-module_header__cards--yellow_tL9CP__Stat path {
  fill: var(--icons-yellow);
}

.StatisticsHeader-module_header__cards--red_kfdVe__Stat path {
  fill: var(--icons-red);
}

.StatisticsHeader-module_header__cards__text_0Vqnc__Stat {
  color: var(--text-primary);
}

.StatisticsMain-module_statistics--main_Zui3h__Stat {
  flex-direction: column;
  gap: 20px;
  display: flex;
}

.StatisticsMain-module_statistics__button_-fSu6__Stat {
  margin-top: 30px;
}

.StatisticsBar-module_bar-container_2BFOz__Stat {
  flex-direction: column;
  gap: 15px;
  display: flex;
}

.StatisticsBar-module_bar-container__header_g5HHc__Stat {
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  align-items: center;
  display: grid;
}

.StatisticsBar-module_bar-container__header__team_n5xTh__Stat, .StatisticsBar-module_bar-container__header__title_0SJuG__Stat {
  color: var(--text-primary);
}

.StatisticsBar-module_bar-container__header__title_0SJuG__Stat {
  text-align: center;
}

.StatisticsBar-module_bar-container__header__team--away_idvVu__Stat {
  text-align: end;
}

.StatisticsBar-module_bar-container__main_iH8UM__Stat {
  justify-content: space-between;
  gap: 5px;
  width: 100%;
  display: flex;
}

.StatisticsBar-module_bar-container__main__bar_PspR5__Stat {
  border-radius: 10px;
  height: 5px;
}

.StatisticsBar-module_bar-container__main__bar--home_ewoG-__Stat {
  background: var(--match-team-1-bg);
}

.StatisticsBar-module_bar-container__main__bar--away_LUtZz__Stat {
  background: var(--match-team-2-bg);
}

.TeamForm-module_team-form_BqlkQ__Stat {
  background: var(--bg-block);
  border-radius: 15px;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  display: flex;
}

.TeamForm-module_team-form__header_OCyDq__Stat {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.TeamForm-module_team-form__header__title_Ysc5p__Stat {
  color: var(--text-primary);
}

@media (max-width: 660px) {
  .TeamForm-module_team-form_BqlkQ__Stat {
    padding: 10px;
  }
}

.TeamFormBody-module_team-form-body_P9jwX__Stat {
  flex-direction: column;
  gap: 20px;
  display: flex;
}

.TeamFormBody-module_team-form-body__short-info_Uy7g0__Stat {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.TeamFormBody-module_team-form-body__short-info__last-five_Ie08z__Stat {
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.TeamFormBody-module_team-form-body__short-info__all-results_mx8DF__Stat {
  color: var(--text-secondary);
  align-items: center;
  gap: 5px;
  display: flex;
}

.TeamFormBody-module_team-form-body__main_rMDDB__Stat {
  overflow: hidden;
}

.TextBroadcastAdminPanel-module_text-broadcast-admin-form_bOu3X__Stat {
  background: var(--bg-block);
  border-radius: 15px;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  display: flex;
}

.TextBroadcastAdminPanel-module_text-broadcast-admin-form__title_5-9h8__Stat {
  color: var(--text-primary);
}

.TextBroadcastAdminPanel-module_text-broadcast-admin-form__main_HwOUa__Stat {
  flex-direction: column;
  gap: 40px;
  display: flex;
}

.TextBroadcastAdminPanel-module_text-broadcast-admin-form__main__time-and-icon_ZEua5__Stat {
  align-items: flex-start;
  gap: 40px;
  display: flex;
}

.TextBroadcastAdminPanel-module_text-broadcast-admin-form__main__buttons_cuI7l__Stat {
  gap: 20px;
  display: flex;
}

.Comment-module_comment_6tnKk__Stat {
  flex-direction: column;
  gap: 5px;
  display: flex;
}

.Comment-module_comment__title_MZci-__Stat {
  color: var(--text-secondary);
}

.Comment-module_comment__text_b0hyC__Stat {
  border: 1px solid var(--line-line);
  width: 100%;
  height: 80px;
  color: var(--text-primary);
  background-color: rgba(0, 0, 0, 0);
  border-radius: 10px;
  outline: none;
  padding: 10px;
}

.Comment-module_comment__text_b0hyC__Stat:focus, .Comment-module_comment__text_b0hyC__Stat:hover {
  border: 1px solid var(--line-active);
}

.IconType-module_icon-types_YDZhS__Stat {
  flex-wrap: wrap;
  gap: 40px;
  display: flex;
}

.IconType-module_icon-types__item_RnVCS__Stat {
  cursor: pointer;
  align-items: center;
  gap: 10px;
  display: flex;
}

.IconType-module_icon-types__item__input_3pcbH__Stat {
  visibility: hidden;
  width: 14px;
  height: 12px;
  display: flex;
}

.IconType-module_icon-types__item__input_3pcbH__Stat:checked {
  position: relative;
}

.IconType-module_icon-types__item__input_3pcbH__Stat:checked:before {
  content: " ";
  visibility: visible;
  border: 2px solid var(--icons-active);
  border-radius: 50%;
  width: 100%;
  height: 10px;
  display: flex;
}

.IconType-module_icon-types__item__input_3pcbH__Stat:checked:after {
  content: " ";
  visibility: visible;
  background-color: var(--icons-active);
  border-radius: 2px;
  width: 4px;
  height: 4px;
  display: flex;
  position: absolute;
  top: 5px;
  left: 5px;
}

.IconType-module_icon-types__item__input_3pcbH__Stat:before {
  content: " ";
  visibility: visible;
  border: 2px solid var(--icons-primary);
  border-radius: 50%;
  width: 100%;
  height: 10px;
  display: flex;
}

.IconType-module_icon-types__item__input_3pcbH__Stat:hover:before {
  content: " ";
  visibility: visible;
  border: 2px solid var(--icons-active);
  border-radius: 50%;
  width: 100%;
  height: 10px;
  display: flex;
}

.IconType-module_icon-types__item__label_3L8rV__Stat {
  color: var(--text-primary);
}

.Minutes-module_minutes_UhLQi__Stat {
  align-items: center;
  gap: 10px;
  display: flex;
}

.Minutes-module_minutes__input_P26np__Stat {
  width: 80px;
}

.Minutes-module_minutes__input__field_A10yT__Stat {
  outline: none;
}

.Minutes-module_minutes__label_0h2Bj__Stat {
  color: var(--text-primary);
}

.Photo-module_photo_bp2YP__Stat {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.Photo-module_photo__title_NF5YW__Stat {
  color: var(--text-secondary);
}

.Photo-module_photo__main_M3V7D__Stat {
  align-items: center;
  gap: 10px;
  display: flex;
}

.Photo-module_photo__main__load-file__input_3-XrB__Stat {
  display: none;
}

.Photo-module_photo__main__load-file__label_TWlWt__Stat {
  border: 1px solid var(--line-line);
  cursor: pointer;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  display: flex;
}

.Photo-module_photo__main__load-file__label__icon_IKRtj__Stat {
  cursor: pointer;
}

.Photo-module_photo__main__load-file__label__icon_IKRtj__Stat path {
  fill: var(--icons-primary);
}

.Photo-module_photo__main__load-file__label_TWlWt__Stat:hover {
  border: 1px solid var(--icons-active);
}

.Photo-module_photo__main__load-file__label_TWlWt__Stat:hover svg > path {
  fill: var(--icons-active);
}

.Photo-module_photo__main__remove-file-icon_PYJDA__Stat path {
  fill: var(--icons-primary);
}

.Photo-module_photo__main__remove-file-icon_PYJDA__Stat:hover path {
  fill: var(--icons-active);
}

.Photo-module_photo__main__photo-url_5hXJo__Stat {
  width: 100%;
}

.Photo-module_photo__main__photo-url__field_eqDFm__Stat {
  outline: none;
}

.Photo-module_photo__preview_0w-te__Stat {
  max-width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.Status-module_status_ajTr9__Stat {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.Status-module_status__label_-BSSx__Stat {
  color: var(--text-secondary);
}

.Status-module_status__dropdown_DCzry__Stat {
  width: -moz-fit-content;
  width: fit-content;
  min-width: 280px;
}

.TextBroadcastMini-module_text-broadcast_JFjyV__Stat {
  background: var(--bg-block);
  border-radius: 15px;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  display: flex;
}

.TextBroadcastMini-module_text-broadcast__title_sPknI__Stat {
  color: var(--text-primary);
}

.TextBroadcastMini-module_text-broadcast__message_vd74V__Stat {
  width: 240px;
  height: 140px;
}

.TextBroadcastMini-module_text-broadcast__message__text_W0MYF__Stat {
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

@media (max-width: 660px) {
  .TextBroadcastMini-module_text-broadcast_JFjyV__Stat {
    padding: 10px;
  }
}

.TextBroadcastMessage-module_message__text_wxqiK__Stat {
  color: var(--text-primary);
  word-break: break-word;
  white-space: pre-wrap;
  overflow: hidden;
}

.TextBroadcastMessage-module_message__image_HsZlf__Stat {
  width: 100%;
  max-width: 860px;
  margin: 10px auto 0;
  position: relative;
}

.BaseBroadcastMessage-module_message_Wd287__Stat {
  background: var(--bg-component);
  border-radius: 10px;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
  display: flex;
}

.BaseBroadcastMessage-module_message__header_Pvaba__Stat {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.BaseBroadcastMessage-module_message__header__info_LfoL3__Stat {
  align-items: center;
  gap: 10px;
  display: flex;
}

.BaseBroadcastMessage-module_message__header__info__card_-9FtX__Stat {
  width: 7.5px;
  height: 9.75px;
}

.BaseBroadcastMessage-module_message__header__info__card--yellow_CTzAM__Stat path, .BaseBroadcastMessage-module_message__header__info__card--second-yellow_yiYiI__Stat path:first-child {
  fill: var(--icons-yellow);
}

.BaseBroadcastMessage-module_message__header__info__card--second-yellow_yiYiI__Stat path:nth-child(2), .BaseBroadcastMessage-module_message__header__info__card--red_gytZP__Stat path {
  fill: var(--icons-red);
}

.BaseBroadcastMessage-module_message__header__info__goal_CC5te__Stat {
  width: 15px;
  height: 15px;
}

.BaseBroadcastMessage-module_message__header__info__goal_CC5te__Stat circle {
  fill: var(--icons-white);
}

.BaseBroadcastMessage-module_message__header__info__goal_CC5te__Stat path {
  fill: var(--icons-black);
}

.BaseBroadcastMessage-module_message__header__info__own-goal_7zIQQ__Stat {
  width: 15px;
  height: 15px;
}

.BaseBroadcastMessage-module_message__header__info__own-goal_7zIQQ__Stat circle {
  fill: var(--icons-white);
}

.BaseBroadcastMessage-module_message__header__info__own-goal_7zIQQ__Stat path {
  fill: var(--icons-red);
}

.BaseBroadcastMessage-module_message__header__info__attention_wdFup__Stat {
  height: 15px;
}

.BaseBroadcastMessage-module_message__header__info__attention_wdFup__Stat path {
  fill: var(--icons-red);
}

.BaseBroadcastMessage-module_message__header__info__substitution_yxwKt__Stat {
  width: 11px;
  height: 11px;
}

.BaseBroadcastMessage-module_message__header__info__substitution_yxwKt__Stat path:first-child {
  fill: var(--icons-red);
}

.BaseBroadcastMessage-module_message__header__info__substitution_yxwKt__Stat path:nth-child(2) {
  fill: var(--icons-green);
}

.BaseBroadcastMessage-module_message__header__info__comment_AesYM__Stat {
  width: 14.85px;
  height: 13px;
}

.BaseBroadcastMessage-module_message__header__info__comment_AesYM__Stat path {
  fill: var(--icons-primary);
  stress: var(--icons-primary);
}

.BaseBroadcastMessage-module_message__header__info__whistle_87dcT__Stat {
  width: 14px;
  height: 11px;
}

.BaseBroadcastMessage-module_message__header__info__whistle_87dcT__Stat path {
  fill: var(--icons-primary);
  stroke: var(--icons-primary);
}

.BaseBroadcastMessage-module_message__header__info__penalty-goal_ipej6__Stat path:first-child {
  fill: var(--icons-white);
}

.BaseBroadcastMessage-module_message__header__info__penalty-goal_ipej6__Stat path:nth-child(2) {
  fill: var(--icons-black);
}

.BaseBroadcastMessage-module_message__header__info__penalty-goal_ipej6__Stat path:nth-child(3) {
  fill: var(--icons-white);
}

.BaseBroadcastMessage-module_message__header__info__penalty-goal_ipej6__Stat path:nth-child(4) {
  fill: var(--icons-active);
}

.BaseBroadcastMessage-module_message__header__info__penalty-shootout_JUzlA__Stat path:first-child {
  fill: var(--icons-white);
}

.BaseBroadcastMessage-module_message__header__info__penalty-scored_4uGCQ__Stat {
  border: 1px solid var(--icons-green);
  border-radius: 50%;
}

.BaseBroadcastMessage-module_message__header__info__penalty-missed_cThAh__Stat {
  border: 1px solid var(--icons-red);
  border-radius: 50%;
}

.BaseBroadcastMessage-module_message__header__info__title_xpjKa__Stat {
  color: var(--text-primary);
}

.BaseBroadcastMessage-module_message__header__info__title__goal_LW8j1__Stat {
  background: var(--label-text-broadcast-bg);
  color: var(--label-text-broadcast-text);
  border-radius: 5px;
  padding: 0 5px;
}

.BaseBroadcastMessage-module_message__header__right-column_q5RF1__Stat {
  gap: 10px;
  display: flex;
}

.BaseBroadcastMessage-module_edit-button_X-Mi3__Stat, .BaseBroadcastMessage-module_delete-button_dbnHu__Stat {
  cursor: pointer;
  border-radius: 5px;
  align-items: center;
  gap: 10px;
  height: 20px;
  padding: 0 10px;
  display: flex;
}

.BaseBroadcastMessage-module_edit-button_X-Mi3__Stat {
  background: var(--label-text-broadcast-bg-opacity-10);
}

.BaseBroadcastMessage-module_edit-button__icon_N-xPn__Stat path {
  fill: var(--text-primary);
}

.BaseBroadcastMessage-module_edit-button__text_d-iER__Stat {
  color: var(--text-primary);
}

.BaseBroadcastMessage-module_delete-button_dbnHu__Stat {
  background: var(--label-text-broadcast-bg-delete-opacity-10);
}

.BaseBroadcastMessage-module_delete-button__icon_RrzGu__Stat path {
  fill: var(--text-error-live);
}

.BaseBroadcastMessage-module_delete-button__text_l-x-O__Stat {
  color: var(--text-error-live);
}

.TextBroadcast-module_text-broadcast_Qi2x1__Stat {
  background: var(--bg-block);
  border-radius: 15px;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  display: flex;
}

.TextBroadcast-module_text-broadcast__header_19wZK__Stat {
  flex-direction: column;
  gap: 20px;
  display: flex;
}

.TextBroadcast-module_text-broadcast__header__title_ZvjAq__Stat {
  color: var(--text-primary);
}

.TextBroadcast-module_text-broadcast__header__filters_38yV1__Stat {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  display: flex;
}

.TextBroadcast-module_text-broadcast__header__filters__order_jVUzl__Stat {
  max-width: 340px;
}

.TextBroadcast-module_text-broadcast__admin-panel_BkihF__Stat {
  margin-top: 20px;
}

@media (max-width: 660px) {
  .TextBroadcast-module_text-broadcast_Qi2x1__Stat {
    padding: 10px;
  }
}

.EventsWrapper-module_events-wrapper_DvbJC__Stat {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.EventMessageBody-module_message-body__text--primary_DKKga__Stat, .EventMessageBody-module_message-body__text--link_lPmXg__Stat {
  color: var(--text-primary);
}

.EventMessageBody-module_message-body__text--link_lPmXg__Stat:hover {
  color: var(--text-link-active);
}

.EventMessageBody-module_message-body__substitution_yGX1G__Stat {
  align-items: center;
  gap: 12px;
  display: flex;
}

.EventMessageBody-module_message-body__substitution__arrow-icon_uhfxh__Stat path {
  fill: var(--icons-green);
}

.EventMessageBody-module_message-body__score-change_0rqJU__Stat {
  align-items: center;
  gap: 5px;
  display: flex;
}

.EventMessageBody-module_message-body__score-change__assist__text_mskM2__Stat {
  color: var(--text-tertiary);
}

.EventMessageBody-module_message-body__penalty-shootout_UJ6cA__Stat {
  flex-direction: column;
  gap: 20px;
  display: flex;
}

.PlayerInfo-module_player-info_p8NyQ__Stat {
  align-items: center;
  gap: 10px;
  display: flex;
}

.PlayerInfo-module_player-info__photo_NJMWX__Stat {
  border-radius: 50%;
}

.PlayerInfo-module_player-info__text_yUqEg__Stat {
  color: var(--text-primary);
}

.PlayerInfo-module_player-info__text_yUqEg__Stat:hover {
  color: var(--text-link-active);
}

.TextBroadcastWrapper-module_text-broadcast-wrapper_kUVSC__Stat {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.Versus-module_versus_THCEk__Stat {
  background: var(--bg-block);
  border-radius: 15px;
  padding: 20px;
}

.Versus-module_versus__wrapper_5rsxB__Stat {
  gap: 40px;
  display: flex;
}

.Versus-module_versus__wrapper__statistics_ny3aR__Stat {
  flex-direction: column;
  gap: 20px;
  display: flex;
}

.Versus-module_versus__wrapper__statistics__title_LmH9c__Stat {
  color: var(--text-primary);
}

.Versus-module_versus__wrapper__matches_DVBg8__Stat {
  flex-direction: column;
  gap: 20px;
  padding-top: 5px;
  display: flex;
  overflow-x: hidden;
}

.Versus-module_versus__wrapper__matches__title_yb0Sj__Stat {
  color: var(--text-primary);
}

@media screen and (max-width: 760px) {
  .Versus-module_versus_THCEk__Stat {
    padding: 10px;
  }

  .Versus-module_versus__wrapper_5rsxB__Stat {
    flex-direction: column;
  }
}

.VersusStatistics-module_statistics_MkY83__Stat {
  background: var(--bg-black-2);
  border-radius: 30px;
  justify-content: space-between;
  align-items: center;
  width: 320px;
  min-width: 320px;
  padding: 5px 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.VersusStatistics-module_statistics__team_dza2b__Stat {
  background: var(--bg-black-3);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 140px;
  display: flex;
  position: relative;
}

.VersusStatistics-module_statistics__team--home_L5afZ__Stat {
  left: -50px;
}

.VersusStatistics-module_statistics__team--away_sR-Zc__Stat {
  right: -50px;
}

.VersusStatistics-module_statistics__team__logo_sAs-O__Stat {
  border-radius: 50%;
}

@media screen and (max-width: 760px) {
  .VersusStatistics-module_statistics_MkY83__Stat {
    width: 100%;
  }
}

.VersusStatisticsWrapper-module_wrapper_ir5tT__Stat {
  z-index: 1;
  gap: 15px;
  display: flex;
  position: absolute;
  top: 55px;
  left: calc(50% - 90px);
}

.VersusStatisticsWrapper-module_wrapper__column_z3iPn__Stat {
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 50px;
  display: flex;
}

.VersusStatisticsWrapper-module_wrapper__column__value_nFalD__Stat {
  color: var(--text-white);
  border-radius: 40px;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 40px;
  display: flex;
}

.VersusStatisticsWrapper-module_wrapper__column__value--win_-pdhU__Stat {
  border: 1px solid var(--icons-white);
  background: var(--bg-white-opacity-1);
}

.VersusStatisticsWrapper-module_wrapper__column__value--draw_A270G__Stat {
  background: var(--bg-white-opacity-10);
}

.VersusStatisticsWrapper-module_wrapper__column__label_TncK2__Stat {
  color: var(--text-white);
}

.DateList-module_dates-list_5VzUN__Stat {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.DateList-module_dates-list__wrapper_9-ge-__Stat {
  transform: translate3d(var(--initialTranslate), 0, 0);
}

.DateList-module_dates-list__navigation_NE-yH__Stat {
  opacity: 0;
}

.DateList-module_dates-list__navigation--prev_oJtlh__Stat {
  left: 5px;
}

.DateList-module_dates-list__navigation--next_2x-Rd__Stat {
  right: 5px;
}

.DateList-module_dates-list__navigation_NE-yH__Stat:hover {
  opacity: 1;
  border: 1px solid var(--line-line);
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.DateList-module_dates-list__navigation_NE-yH__Stat:hover svg {
  position: absolute;
  top: 9px;
  left: 9px;
}

@media (max-width: 660px) {
  .DateList-module_dates-list__navigation_NE-yH__Stat {
    display: none;
  }
}

.DateTab-module_date-tab_ZcO-o__Stat {
  background-color: var(--bg-component);
  width: 50px;
  height: 50px;
  color: var(--text-secondary);
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.DateTab-module_date-tab--active_tSGKG__Stat {
  background-color: var(--bg-prime);
  color: var(--text-white);
}

.DateTab-module_date-tab--favorite_waDEp__Stat {
  fill: var(--icons-red);
  position: absolute;
  top: 5px;
  right: 5px;
}

.Header-module_matches-header_YVjOv__Stat {
  background: var(--bg-block);
  border-radius: 15px;
  width: 100%;
  height: 120px;
}

.Header-module_matches-header--overflow_1RjUi__Stat {
  overflow: hidden;
}

.Header-module_matches-header__dates_1-gld__Stat {
  align-items: center;
  gap: 10px;
  padding: 10px 0 20px 10px;
  display: flex;
}

.Header-module_matches-header__dates--disable_KFwGu__Stat {
  opacity: .5;
  pointer-events: none;
}

.Header-module_matches-header__tabs_LCPsZ__Stat {
  border-top: 1px dotted var(--line-line);
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 0 10px;
  display: flex;
}

.TournamentsList-module_tournaments-list_V1urv__Stat {
  background-color: var(--bg-block);
  border-radius: 15px;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
}

.TournamentsList-module_tournaments-list__title_wGOLA__Stat {
  color: var(--text-primary);
  margin: 10px 0 10px 10px;
}

.TournamentsList-module_tournaments-list__item_0dp2N__Stat {
  padding: 10px 15px;
  display: flex;
}

.TournamentsList-module_tournaments-list__item--title_iT7n-__Stat {
  color: var(--text-primary);
  margin-left: 10px;
}

.PerformanceHistory-module_performance-history_QwjbW__Stat {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.PerformanceHistoryWidget-module_performance-history-widget_EP-r1__Stat {
  background: var(--bg-block);
  border-radius: 15px;
  flex-direction: column;
  gap: 25px;
  padding: 10px;
  display: flex;
}

.PerformanceHistoryWidget-module_performance-history-widget__title_aRxub__Stat {
  color: var(--text-primary);
}

.PerformanceHistoryWidget-module_performance-history-widget__main_YwMFq__Stat {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.PerformanceHistoryTable-module_performance-history-table_l4NuY__Stat {
  border: 1px solid var(--line-line);
  border-radius: 10px;
  overflow: hidden;
}

.PerformanceHistoryTable-module_performance-history-table__header_mj-y1__Stat {
  border-bottom: 1px solid var(--line-line);
  background: var(--bg-component);
  height: 40px;
  color: var(--text-primary);
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  display: flex;
}

.PerformanceHistoryTableMetrics-module_performance-history-table__metric_099Mt__Stat {
  width: 40px;
  color: var(--text-primary);
  justify-content: center;
  align-items: center;
  display: flex;
}

.PerformanceHistoryTableMetrics-module_performance-history-table__metrics_vyLA1__Stat {
  height: 40px;
  display: flex;
}

.PerformanceHistoryTableMetrics-module_performance-history-table__title_1Ai7j__Stat {
  width: calc(var(--col-number, 1) * 40px);
  color: var(--text-primary);
  justify-content: center;
  align-items: center;
  display: flex;
}

.PerformanceHistoryTableRow-module_performance-history-table-row_Vpo1Q__Stat {
  border-bottom: 1px solid var(--line-line);
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  display: flex;
}

.PerformanceHistoryTableRow-module_performance-history-table-row--expandable_znRr0__Stat {
  cursor: pointer;
}

.PerformanceHistoryTableRow-module_performance-history-table-row--not-expandable_u6qyu__Stat {
  opacity: 0;
}

.PerformanceHistoryTableRow-module_performance-history-table-row--primary_aAt2j__Stat {
  background: var(--bg-block);
}

.PerformanceHistoryTableRow-module_performance-history-table-row--secondary_64-lq__Stat {
  background: var(--bg-component);
  padding-left: 30px;
}

.PerformanceHistoryTableRow-module_performance-history-table-row__titles_vzGcr__Stat {
  align-items: center;
  gap: 10px;
  display: flex;
}

.PerformanceHistoryTableRow-module_performance-history-table-row__title_ix6IY__Stat {
  color: var(--text-primary);
}

.PerformanceHistoryTableRow-module_performance-history-table-row__sub-title_VfGgI__Stat {
  color: var(--text-secondary);
}

.PerformanceHistoryTableRow-module_performance-history-table-row__info_3-Vkx__Stat {
  align-items: center;
  gap: 10px;
  display: flex;
}

.PerformanceHistoryTableRow-module_performance-history-table-row__collapse-icon-div_1zRP6__Stat svg {
  fill: var(--text-primary);
}

.PerformanceHistoryTableRow-module_performance-history-table-row__children--hide_LbSGg__Stat {
  display: none;
}

@media screen and (max-width: 660px) {
  .PerformanceHistoryTableRow-module_performance-history-table-row__titles_vzGcr__Stat {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
}

.PlayersStatisticWidget-module_widget_VO4UY__Stat {
  background: var(--bg-block);
  border-radius: 10px;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
  display: flex;
}

.PlayersTable-module_table_5Dqap__Stat {
  border: 1px solid var(--line-line);
  border-radius: 10px;
  flex-direction: column;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.PlayersTableHeader-module_header_-U0YH__Stat {
  background: var(--bg-component);
  justify-content: space-between;
  height: 40px;
  padding: 0 10px;
  display: flex;
}

.PlayersTableHeader-module_column_1kie5__Stat {
  justify-content: center;
  align-items: center;
  min-width: 15px;
  display: flex;
}

.PlayersTableHeader-module_column__text_YXzzt__Stat {
  color: var(--text-primary);
}

.PlayersTableLine-module_table-line_ysBMV__Stat {
  grid-column-gap: 10px;
  background: var(--bg-block);
  border-top: 1px solid var(--line-line);
  grid-template-columns: 1fr 1fr 15px;
  height: 40px;
  padding: 0 10px;
  display: grid;
}

.PlayersTableLine-module_table-line__sportsman_O9ujT__Stat {
  align-items: center;
  gap: 10px;
  min-width: 150px;
  display: flex;
}

.PlayersTableLine-module_table-line__sportsman__logo_-HuDo__Stat {
  border-radius: 50%;
}

.PlayersTableLine-module_table-line__sportsman__text_Cpns8__Stat {
  -webkit-line-clamp: 1;
  word-break: break-all;
  color: var(--text-primary);
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.PlayersTableLine-module_table-line__team_gpDwO__Stat {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.PlayersTableLine-module_table-line__team__text_BAJp-__Stat {
  -webkit-line-clamp: 1;
  word-break: break-all;
  color: var(--text-secondary);
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.PlayersTableLine-module_table-line__metric_lZ6aN__Stat {
  justify-content: center;
  align-items: center;
  display: flex;
}

.PlayersTableLine-module_table-line__metric__text_lYQNf__Stat {
  color: var(--text-primary);
}

.PlayerStat-module_player-stat_1d1Hj__Stat {
  flex-direction: column;
  gap: 20px;
  width: 100%;
  display: flex;
}

.PlayerStat-module_player-stat__dropdown-wrapper_sAT0O__Stat {
  gap: 20px;
  padding: 20px 10px 0;
  display: flex;
  position: relative;
}

.PlayerStat-module_player-stat__delimiter_OkrTV__Stat {
  background: var(--line-line);
  width: 100%;
  height: 1px;
}

.PlayerStat-module_player-stat__main-block_nSyCU__Stat {
  flex-direction: column;
  gap: 20px;
  display: flex;
}

.PlayerStat-module_player-stat__main-block__header_lRhgA__Stat {
  justify-content: space-between;
  gap: 10px;
  display: flex;
}

.PlayerStat-module_player-stat__main-block__tabs_u4pFr__Stat {
  cursor: pointer;
  gap: 10px;
  display: flex;
}

.PlayerStat-module_player-stat__main-block__tab_-3COM__Stat {
  border: 1px solid var(--buttons-tab-switch-default-stroke);
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  display: flex;
}

.PlayerStat-module_player-stat__main-block__tab--active_qhORu__Stat {
  border: 1px solid var(--buttons-tab-switch-active-bg);
  background-color: var(--buttons-tab-switch-active-bg);
}

.PlayerStat-module_player-stat__main-block__tab--active_qhORu__Stat path {
  fill: var(--icons-white);
}

.PlayerStatTable-module_player-stat-table_DYy4-__Stat {
  background-color: var(--bg-block);
  width: 100%;
  overflow: hidden;
}

.PlayerStatTable-module_player-stat-table__wrapper_Bh4Mj__Stat {
  background-color: var(--bg-block);
  border-radius: 15px;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: 10px;
  display: flex;
}

.PlayerStatTable-module_player-stat-table__title_qqG-g__Stat {
  color: var(--text-primary);
}

.PlayerStatTable-module_player-stat-table__main-part_mpQwv__Stat {
  display: grid;
  overflow-x: scroll;
}

.PlayerStatTable-module_player-stat-table__footer_JnPVd__Stat {
  border-top: 1px solid var(--line-line);
}

.MatchStatRow-module_match-stat-row_4y9FF__Stat {
  border-top: 1px solid var(--line-line);
  background-color: var(--bg-block);
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  display: flex;
}

.MatchStatRow-module_match-stat-row__main-info_DIBXX__Stat {
  background-color: var(--bg-block);
  cursor: pointer;
  align-items: center;
  gap: 10px;
  display: flex;
}

.MatchStatRow-module_match-stat-row__main-info__match-result_cOs0j__Stat {
  align-items: center;
  gap: 5px;
  display: flex;
}

.MatchStatRow-module_match-stat-row__main-info__match-result-with-penalties_vY2I5__Stat {
  width: 105px;
}

.MatchStatRow-module_match-stat-row__main-info__match-result__score_942nM__Stat {
  color: var(--text-primary);
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 30px;
  height: 100%;
  display: block;
}

.MatchStatRow-module_match-stat-row__main-info__match-result__score-wrapper_Tc03z__Stat {
  display: flex;
}

.MatchStatRow-module_match-stat-row__main-info__match-result__logo_6FB3m__Stat {
  width: 15px;
  height: 15px;
  position: relative;
}

.MatchStatRow-module_match-stat-row__main-info__teams_VTaBc__Stat {
  color: var(--text-primary);
  height: 100%;
  display: block;
}

.MatchStatRow-module_match-stat-row__main-info__teams--wrapper_Uukty__Stat {
  width: 290px;
}

.MatchStatRow-module_match-stat-row__main-info__date_xfsPN__Stat {
  color: var(--text-secondary);
  height: 100%;
  display: block;
}

.MatchStatRow-module_match-stat-row__main-info__date--wrapper_2jkX7__Stat {
  flex: 1;
  display: flex;
}

.MatchStatRow-module_match-stat-row__stat_IgaSJ__Stat {
  display: flex;
}

.MatchStatRow-module_match-stat-row__not-in-squad_F0Vih__Stat, .MatchStatRow-module_match-stat-row__on-the-bench_cob4x__Stat {
  color: var(--text-primary);
  white-space: nowrap;
  min-width: var(--stat-cols-width);
  padding-left: 15px;
}

.MatchStatRow-module_match-stat-row__match-metric_R6oxV__Stat {
  width: 50px;
  color: var(--text-primary);
  justify-content: center;
  align-items: center;
  gap: 5px;
  display: flex;
}

.MatchStatRow-module_match-stat-row__match-metric__cards--red_zh8jP__Stat path {
  fill: var(--icons-red);
}

.MatchStatRow-module_match-stat-row__match-metric__cards--yellow_lLTwu__Stat path {
  fill: var(--icons-yellow);
}

@media (max-width: 660px) {
  .MatchStatRow-module_match-stat-row__main-info_DIBXX__Stat {
    flex-wrap: wrap;
    justify-content: center;
    width: 70%;
  }

  .MatchStatRow-module_match-stat-row__main-info--is-not-a-coach_CIkhT__Stat {
    z-index: 1;
    width: 180px;
    max-width: 180px;
    margin-right: 10px;
    display: flex;
    position: -webkit-sticky;
    position: sticky;
    left: 10px;
  }

  .MatchStatRow-module_match-stat-row__main-info--is-not-a-coach_CIkhT__Stat:after {
    z-index: 0;
    content: "";
    width: 5px;
    box-shadow: 4px 0 8px var(--table-shadow-bg);
    background-color: var(--bg-block);
    display: block;
    position: absolute;
    top: -10px;
    bottom: -10px;
    left: 178px;
  }

  .MatchStatRow-module_match-stat-row__main-info__match-result_cOs0j__Stat {
    order: 1;
    min-width: 50px;
  }

  .MatchStatRow-module_match-stat-row__main-info__date_xfsPN__Stat {
    padding-right: 5px;
  }

  .MatchStatRow-module_match-stat-row__main-info__date--wrapper_2jkX7__Stat {
    flex-grow: 1;
    order: 1;
    justify-content: flex-end;
    width: 35%;
    min-width: 65px;
    display: flex;
  }

  .MatchStatRow-module_match-stat-row__main-info__teams--wrapper_Uukty__Stat {
    order: 2;
    width: 100%;
    display: flex;
  }
}

.TableHeaderStat-module_header__columns_tgPJs__Stat {
  border-bottom: 1px solid var(--line-line);
  background-color: var(--bg-component);
  justify-content: space-between;
  padding: 10px;
  display: flex;
}

.TableHeaderStat-module_header__column-name-empty_bwA3d__Stat {
  background-color: var(--bg-component);
}

.TableHeaderStat-module_header__stat-columns_fS3fn__Stat {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.TableHeaderStat-module_header__column-name_vGS3N__Stat {
  width: 50px;
  color: var(--text-secondary);
  justify-content: center;
  align-items: center;
  display: flex;
}

.TableHeaderStat-module_header__season-total_5HhuC__Stat {
  background-color: var(--bg-component);
  justify-content: space-between;
  padding: 10px;
  display: flex;
}

.TableHeaderStat-module_header__season-total-name_-oelN__Stat {
  background-color: var(--bg-component);
  color: var(--text-secondary);
  flex: none;
  width: 180px;
  display: flex;
}

.TableHeaderStat-module_header__season-metrics_u5E0a__Stat {
  display: flex;
}

.TableHeaderStat-module_header__season-metric_Y9PHV__Stat {
  width: 50px;
  color: var(--text-primary);
  justify-content: center;
  align-items: center;
  display: flex;
}

.TableHeaderStat-module_header__cards_pk8l1__Stat {
  justify-content: center;
  align-items: center;
  gap: 6px;
  display: flex;
}

.TableHeaderStat-module_header__card_L3j-b__Stat {
  justify-content: center;
  align-items: center;
  gap: 3px;
  display: flex;
}

.TableHeaderStat-module_header__card--red_UEp5M__Stat path {
  fill: var(--icons-red);
}

.TableHeaderStat-module_header__card--yellow_3xmaH__Stat path {
  fill: var(--icons-yellow);
}

@media (max-width: 660px) {
  .TableHeaderStat-module_header__column-name-empty_bwA3d__Stat {
    flex: none;
    display: flex;
  }

  .TableHeaderStat-module_header__season-total-name-is-not-a-coach_BTdj3__Stat:after {
    z-index: 0;
    content: "";
    width: 5px;
    box-shadow: 4px 0 8px var(--table-shadow-bg);
    background-color: var(--bg-component);
    display: block;
    position: absolute;
    top: -10px;
    bottom: -10px;
    left: 178px;
  }

  .TableHeaderStat-module_header__season-total-name_-oelN__Stat, .TableHeaderStat-module_header__column-name-empty_bwA3d__Stat {
    z-index: 1;
    width: 180px;
    max-width: 180px;
    margin-right: 10px;
    display: flex;
    position: -webkit-sticky;
    position: sticky;
    left: 10px;
  }
}

.PlayerStatDetailedMatch-module_detailed-match_xW-1K__Stat {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.PlayerStatDetailedMatch-module_detailed-match__title_sRTm9__Stat {
  color: var(--text-primary);
}

.PlayerStatDetailedMatch-module_detailed-match__main_eBYXa__Stat {
  background-color: var(--bg-block);
  border-radius: 15px;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
  display: flex;
}

.PlayerStatDetailedMatch-module_detailed-match__header_44R-s__Stat {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.PlayerStatDetailedMatch-module_detailed-match__header__dropdown_1PMzk__Stat {
  width: 340px;
}

.PlayerStatDetailedMatch-module_detailed-match__delimiter_U5xf9__Stat {
  background: var(--line-line);
  width: calc(100% + 20px);
  height: 1px;
  margin-left: -10px;
}

.PlayerStatDetailedMatch-module_detailed-match__stat_W0a2z__Stat {
  flex-direction: column;
  gap: 20px;
  display: flex;
}

.PlayerStatDetailedMatch-module_detailed-match__stat__main_XZi3w__Stat {
  grid-template-columns: repeat(auto-fit, 290px);
  column-gap: 10px;
  display: grid;
  position: relative;
}

.PlayerStatDetailedMatch-module_detailed-match__stat__main__delimiter-remove-top-border_FYzQi__Stat {
  background-color: var(--bg-block);
  z-index: 10;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.PlayerStatDetailedMatch-module_detailed-match__stat__other_scFTm__Stat {
  flex-wrap: wrap;
  gap: 10px;
  display: flex;
}

.PlayerStatDetailedMatch-module_detailed-match__stat__other__icon_X7R5-__Stat {
  width: 20px;
  height: 20px;
}

.PlayerStatDetailedMatch-module_detailed-match__stat__other__icon-wrapper_NorMn__Stat {
  background-color: var(--bg-component);
  color: var(--text-tertiary);
  border-bottom-right-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  padding: 5px 10px;
  display: flex;
}

.PlayerStatDetailedMatch-module_detailed-match__stat__other__block_tLJcs__Stat {
  border: 1px solid var(--line-line);
  border-radius: 15px;
  width: 290px;
  height: -moz-fit-content;
  height: fit-content;
  overflow: hidden;
}

.PlayerStatDetailedMatch-module_detailed-match__stat__other__item_-bEFq__Stat:nth-child(2) {
  border-top: none;
}

.PlayerStatDetailedMatch-module_detailed-match__stat__main__item_4NsSV__Stat, .PlayerStatDetailedMatch-module_detailed-match__stat__other__item_-bEFq__Stat {
  color: var(--text-primary);
  border-top: 1px solid var(--line-line);
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 10px;
  display: flex;
}

@media (max-width: 660px) {
  .PlayerStatDetailedMatch-module_detailed-match__header_44R-s__Stat {
    flex-direction: column;
    gap: 20px;
  }

  .PlayerStatDetailedMatch-module_detailed-match__header__dropdown_1PMzk__Stat {
    width: 100%;
  }

  .PlayerStatDetailedMatch-module_detailed-match__header__link-to-the-match_gDp7w__Stat {
    justify-content: center;
    width: 100%;
    display: flex;
  }

  .PlayerStatDetailedMatch-module_detailed-match__stat__main_XZi3w__Stat {
    grid-template-columns: repeat(auto-fit, 100%);
  }

  .PlayerStatDetailedMatch-module_detailed-match__stat__other__block_tLJcs__Stat {
    width: 100%;
  }
}

.PlayerStatDetailedMatchSkeleton-module_skeleton_-6F-o__Stat {
  background-color: var(--bg-block);
  border-radius: 15px;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
  display: flex;
}

.PlayerStatDetailedMatchSkeleton-module_skeleton__dropdown_aM1fb__Stat {
  border-radius: 10px;
  width: 340px;
  overflow: hidden;
}

.PlayerStatDetailedMatchSkeleton-module_skeleton__delimiter_VYil5__Stat {
  background: var(--line-line);
  width: calc(100% + 20px);
  height: 1px;
  margin-left: -10px;
}

.PlayerStatDetailedMatchSkeleton-module_skeleton__stat_O8NMW__Stat {
  flex-direction: column;
  gap: 20px;
  display: flex;
}

.PlayerStatDetailedMatchSkeleton-module_skeleton__stat__main_au2l8__Stat {
  grid-template-columns: repeat(auto-fit, 290px);
  gap: 10px;
  display: grid;
  position: relative;
}

.PlayerStatDetailedMatchSkeleton-module_skeleton__stat__other_FfhBe__Stat {
  flex-wrap: wrap;
  gap: 10px;
  display: flex;
}

.PlayerStatDetailedMatchSkeleton-module_skeleton__stat__other__block_BqoKP__Stat {
  border-radius: 15px;
  width: 290px;
  overflow: hidden;
}

@media (max-width: 660px) {
  .PlayerStatDetailedMatchSkeleton-module_skeleton__dropdown_aM1fb__Stat {
    width: 100%;
  }

  .PlayerStatDetailedMatchSkeleton-module_skeleton__stat__main_au2l8__Stat {
    grid-template-columns: repeat(auto-fit, 100%);
  }

  .PlayerStatDetailedMatchSkeleton-module_skeleton__stat__other__block_BqoKP__Stat {
    width: 100%;
  }
}

.DesktopBracket-module_desktop-bracket_XyzPK__Stat {
  width: 100%;
  height: 700px;
  overflow: scroll;
}

.DesktopBracket-module_desktop-bracket__wrapper_nyBZr__Stat {
  display: flex;
}

.RoundColumn-module_round-column_PO8J1__Stat {
  flex-direction: column;
  gap: 20px;
  width: 200px;
  padding-bottom: 100px;
  display: flex;
}

.RoundColumn-module_round-column__brackets_bXJjH__Stat {
  z-index: 1;
  flex-direction: column;
  justify-content: space-around;
  gap: 20px;
  height: 100%;
  display: flex;
}

@media (max-width: 660px) {
  .RoundColumn-module_round-column_PO8J1__Stat {
    width: auto;
    min-width: 200px;
    min-height: 600px;
  }

  .RoundColumn-module_round-column--isMobile_9Hw3Q__Stat {
    padding: 0 10px;
  }
}

.PairCard-module_pair-card_XLF3X__Stat {
  height: 100%;
  z-index: var(--zIndex);
  flex-direction: column;
  justify-content: space-around;
  margin: 0 10px;
  display: flex;
  position: relative;
}

.PairCard-module_pair-card_XLF3X__Stat:after {
  content: "";
  width: calc(100% + 20px);
  height: 100%;
  color: var(--line-line);
  border-right: 1px solid;
  position: absolute;
  right: -10px;
}

.PairCard-module_pair-card__odd_hGgC7__Stat:after {
  border-top: 1px solid;
  border-top-right-radius: 8px;
  top: 50%;
}

.PairCard-module_pair-card__even_GXiw8__Stat:after {
  border-bottom: 1px solid;
  border-bottom-right-radius: 10px;
  bottom: 50%;
}

.PairCard-module_pair-card__even__connector_DB4Bs__Stat:before {
  background-color: var(--line-line);
  content: "";
  width: 8px;
  height: 1px;
  position: absolute;
  top: -6px;
  left: auto;
  right: -17px;
}

.PairCard-module_pair-card__first-column_FPxR9__Stat:after {
  width: 10px;
}

.PairCard-module_pair-card__last-column_aGK8U__Stat:after {
  border-right: none;
  border-radius: 0;
  width: 10px;
  height: 0;
  left: -10px;
}

.PairCard-module_pair-card--without-connector_r91VA__Stat:after {
  width: 0;
}

.PairCard-module_pair-card--empty_QNbW8__Stat {
  cursor: auto;
}

.PairCard-module_pair-card__pair_c8MOh__Stat {
  background-color: var(--bg-block);
  border: 1px solid var(--line-line);
  cursor: pointer;
  z-index: 1;
  border-radius: 10px;
  flex-direction: column;
  width: 180px;
  height: 70px;
  display: flex;
}

.PairCard-module_pair-card__pair--third-place_CHbsZ__Stat {
  height: 98px;
}

.PairCard-module_pair-card__pair--isOpen_5FjiX__Stat {
  border-bottom: none;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.PairCard-module_pair-card__third-place_7vslX__Stat {
  height: 30px;
  color: var(--text-primary);
  border-bottom: 1px solid var(--line-line);
  align-items: center;
  padding: 0 15px;
  display: flex;
}

.PairCard-module_pair-card__third-place-caption_s61Ax__Stat {
  color: var(--text-primary);
}

.PairCard-module_pair-card__teams-wrapper_rE0gG__Stat {
  flex-direction: column;
  justify-content: space-around;
  gap: 10px;
  padding: 10px;
  display: flex;
  position: relative;
}

.PairCard-module_pair-card__series_j-OCD__Stat {
  background-color: var(--bg-component);
  border: 1px solid var(--line-line);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  flex-direction: column;
  width: 180px;
  margin-top: -1px;
  margin-left: -1px;
  display: flex;
}

.PairCard-module_pair-card__series-hiden_10xqv__Stat {
  display: none;
}

.PairCard-module_pair-card__series-item_dXLgS__Stat {
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 10px;
  display: flex;
}

.PairCard-module_pair-card__match-result_TAVsV__Stat {
  color: var(--text-primary);
  align-items: center;
  gap: 5px;
  display: flex;
}

.PairCard-module_pair-card__team_Z-QPU__Stat {
  height: 20px;
  color: var(--text-primary);
  align-items: center;
  gap: 5px;
  display: flex;
}

.PairCard-module_pair-card__team--loser_u37eL__Stat {
  color: var(--text-primary-opacity-30);
}

.PairCard-module_pair-card__date_mePnS__Stat {
  color: var(--text-secondary);
  padding-right: 5px;
}

.PairCard-module_pair-card__score_zHzwT__Stat {
  text-align: center;
  width: 10px;
}

.PairCard-module_pair-card__score-match_R4-Zl__Stat {
  text-align: center;
  width: 30px;
  color: var(--text-primary);
}

.PairCard-module_pair-card__score-wrapper_9ugQs__Stat {
  gap: 10px;
  display: flex;
}

.PairCard-module_pair-card__sco_O0wub__Stat .PairCard-module_pair-card__logo_GKOWM__Stat {
  min-width: 20px;
}

.PairCard-module_pair-card__name_NBYSR__Stat {
  -webkit-line-clamp: 1;
  word-break: break-all;
  -webkit-box-orient: vertical;
  flex: 1;
  display: -webkit-box;
  overflow: hidden;
}

@media (max-width: 660px) {
  .PairCard-module_pair-card__pair_c8MOh__Stat {
    width: 100%;
  }

  .PairCard-module_pair-card__series_j-OCD__Stat {
    width: calc(100% + 2px);
  }
}

.RoundHeader-module_round-header_54ku-__Stat {
  z-index: 3;
  background-color: var(--bg-block);
  -webkit-line-clamp: 1;
  word-break: break-all;
  text-align: center;
  width: 100%;
  color: var(--text-primary);
  border-radius: 5px;
  -webkit-box-orient: vertical;
  height: 20px;
  padding: 0 10px;
  display: -webkit-box;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  overflow: hidden;
}

.MobileBracket-module_mobile-bracket_c6gEV__Stat {
  width: 100%;
  height: 600px;
  margin-top: 10px;
  padding: 0 10px;
  overflow: scroll;
}

.MobileBracket-module_mobile-bracket__swiper_Ko8Mo__Stat {
  height: 600px;
  overflow-y: scroll !important;
}

.MobileBracket-module_mobile-bracket__slide_CU6-P__Stat {
  width: 100% !important;
}

.SquadList-module_squad-list_Kk-uI__Stat {
  flex-direction: column;
  gap: 20px;
  display: flex;
}

.SquadList-module_squad-list__main_ynCRu__Stat {
  background-color: var(--bg-block);
  border-radius: 15px;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
  display: flex;
}

.SquadList-module_squad-list__dropdown_DVD7h__Stat {
  width: 290px;
  max-width: 290px;
}

.SquadList-module_squad-list__table_PV94a__Stat {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.SquadList-module_squad-list__table__players_5r49R__Stat {
  display: grid;
  overflow-x: auto;
}

@media (max-width: 660px) {
  .SquadList-module_squad-list__dropdown_DVD7h__Stat {
    width: 100%;
    max-width: 100%;
  }
}

.Header-module_table-header_5uD6s__Stat {
  background-color: var(--bg-component);
  border-bottom: 1px solid var(--line-line);
  min-height: 40px;
  color: var(--text-secondary);
  text-align: center;
  justify-content: space-between;
  gap: 10px;
  padding: 10px 10px 10px 0;
  display: flex;
  position: relative;
}

.Header-module_table-header__team-wrapper_a95eX__Stat {
  z-index: 1;
  background-color: var(--bg-component);
  flex: 1 0 180px;
  width: 320px;
  margin-right: 10px;
  padding-left: 10px;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
}

.Header-module_table-header__team_mogwU__Stat {
  z-index: 1;
  background-color: var(--bg-component);
  width: 100%;
  color: var(--text-secondary);
  align-items: center;
  align-items: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  overflow: hidden;
}

.Header-module_table-header__column-name_fhE49__Stat {
  width: 40px;
  color: var(--text-secondary);
  justify-content: center;
  align-items: center;
  display: flex;
}

.Header-module_table-header__column-name--active_if-xX__Stat {
  color: var(--text-link-active);
  display: flex;
}

.Header-module_table-header__column-name__with-sort_DooNs__Stat {
  cursor: pointer;
}

.Header-module_table-header__sort-icons_boza1__Stat {
  flex-direction: column;
  display: flex;
}

.Header-module_table-header__sort-icons__icon--active-first_axVUV__Stat path:first-child, .Header-module_table-header__sort-icons__icon--active-second_IwNXq__Stat path:last-child {
  fill: var(--text-link-active);
}

@media (max-width: 660px) {
  .Header-module_table-header__team_mogwU__Stat {
    width: 180px;
  }

  .Header-module_table-header__team-wrapper_a95eX__Stat {
    flex: auto;
    width: 180px;
    max-width: 180px;
  }

  .Header-module_table-header__team-wrapper_a95eX__Stat:after {
    z-index: 0;
    content: "";
    width: 5px;
    box-shadow: 4px 0 8px var(--table-shadow-bg);
    background-color: var(--bg-component);
    display: block;
    position: absolute;
    top: -10px;
    bottom: -10px;
    left: 178px;
  }
}

.Line-module_table-line_3zyn3__Stat {
  border-bottom: 1px solid var(--line-line);
  background-color: var(--bg-block);
  justify-content: space-between;
  gap: 10px;
  min-height: 40px;
  padding: 10px 10px 10px 0;
  display: flex;
  position: relative;
}

.Line-module_table-line__player-info_FxTfp__Stat {
  z-index: 1;
  background-color: var(--bg-block);
  flex: 1 0 180px;
  align-items: center;
  gap: 5px;
  margin-right: 10px;
  padding-left: 10px;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
}

.Line-module_table-line__player-info__name_DL6Sq__Stat {
  align-items: center;
  padding-left: 5px;
  display: flex;
}

.Line-module_table-line__player-info__name-icons_wrapper_jCxtY__Stat {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.Line-module_table-line__player-info__main_DRDOI__Stat, .Line-module_table-line__player-info__icons_byxUF__Stat {
  gap: 5px;
  display: flex;
}

.Line-module_table-line__player-info__name_DL6Sq__Stat {
  -webkit-line-clamp: 2;
  word-break: break-word;
  color: var(--text-primary);
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.Line-module_table-line__player-info__secondary_zgbOa__Stat {
  color: var(--text-secondary);
  align-items: center;
  gap: 5px;
  padding-left: 5px;
  display: flex;
}

.Line-module_table-line__player-info__secondary-divider_IIMxd__Stat {
  border-left: 1px solid var(--line-line);
  height: 5px;
}

.Line-module_table-line__player-info__secondary-club_9GoU6__Stat {
  color: var(--text-secondary);
}

.Line-module_table-line__player-data_cZ-Ue__Stat {
  width: 40px;
  color: var(--text-primary);
  justify-content: center;
  align-items: center;
  display: flex;
}

.Line-module_table-line__player-data--active_iPexo__Stat {
  color: var(--text-link-active);
}

@media (max-width: 660px) {
  .Line-module_table-line__player-info_FxTfp__Stat {
    flex: auto;
    width: 180px;
    max-width: 180px;
  }

  .Line-module_table-line__player-info_FxTfp__Stat:after {
    z-index: 0;
    content: "";
    width: 5px;
    box-shadow: 4px 0 8px var(--table-shadow-bg);
    background-color: var(--bg-block);
    display: block;
    position: absolute;
    top: -10px;
    bottom: -10px;
    left: 178px;
  }

  .Line-module_table-line__player-info__main_DRDOI__Stat {
    width: 110px;
  }

  .Line-module_table-line__player-info__main--nationals_-CeTE__Stat {
    width: 130px;
  }

  .Line-module_table-line__player-info__icons_byxUF__Stat {
    align-items: flex-end;
  }

  .Line-module_table-line__player-info__secondary_zgbOa__Stat {
    width: 130px;
  }
}

.TeamHead-module_teams-head_HI9dE__Stat {
  flex-direction: column;
  justify-content: space-between;
  min-width: 210px;
  height: 85px;
  padding: 10px;
  display: flex;
}

.TeamHead-module_teams-head__player-name_7sK-Q__Stat {
  color: var(--text-primary);
}

.TeamHead-module_teams-head__first-row_AUcA0__Stat, .TeamHead-module_teams-head__second-row_-41Hh__Stat {
  color: var(--text-primary);
  align-items: center;
  gap: 10px;
  display: flex;
}

.TeamHead-module_teams-head__first-row_AUcA0__Stat {
  color: var(--text-secondary);
  justify-content: space-between;
}

.SquadStat-module_squad-stat_MNLt7__Stat {
  flex-direction: column;
  gap: 20px;
  display: flex;
}

.SquadStat-module_squad-stat__main_QfDGA__Stat {
  background-color: var(--bg-block);
  border-radius: 15px;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
  display: flex;
}

.SquadStat-module_squad-stat__dropdowns_ZmVq9__Stat {
  gap: 10px;
  display: flex;
}

.SquadStat-module_squad-stat__dropdowns__dropdown_1dhvX__Stat {
  width: 290px;
  max-width: 290px;
}

.SquadStat-module_table_MhZQB__Stat {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.SquadStat-module_table__players_Rb9n-__Stat {
  display: grid;
  overflow-x: auto;
}

@media (max-width: 660px) {
  .SquadStat-module_squad-stat__dropdowns_ZmVq9__Stat {
    flex-direction: column;
  }

  .SquadStat-module_squad-stat__dropdowns__dropdown_1dhvX__Stat {
    width: 100%;
    max-width: 100%;
  }
}

.AllGroups-module_all-groups_yZ6MW__Stat {
  flex-direction: column;
  gap: 20px;
  display: flex;
}

.AllGroups-module_all-groups__table_bXv7T__Stat {
  border: 1px solid var(--line-line);
  border-radius: 10px;
  width: 100%;
  overflow: hidden;
}

.AllGroups-module_all-groups__group_VKnC4__Stat {
  text-transform: uppercase;
  color: var(--text-primary);
  padding: 0 10px 10px;
}

@media (max-width: 660px) {
  .AllGroups-module_all-groups__table_bXv7T__Stat {
    border-bottom: 0;
    border-left: none;
    border-right: none;
  }
}

.TableFooter-module_table-footer_1GIJi__Stat {
  background-color: var(--bg-block);
}

.TableFooter-module_table-footer__labels_HaroH__Stat {
  border-top: 1px solid var(--line-line);
  border-bottom: 1px solid var(--line-line);
  flex-wrap: wrap;
  gap: 20px 40px;
  padding: 20px 10px;
  display: flex;
}

.TableFooter-module_table-footer__labels-pair_UVWy5__Stat {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.TableFooter-module_table-footer__label_LvSmT__Stat {
  gap: 5px;
  display: flex;
}

.TableFooter-module_table-footer__label--rank-up_sc1N3__Stat svg {
  fill: var(--icons-green);
}

.TableFooter-module_table-footer__label--rank-down_vWRzB__Stat svg {
  fill: var(--icons-red);
  transform: rotate(180deg);
}

.TableFooter-module_table-footer__label--rank-same_HJwa4__Stat .TableFooter-module_table-footer__label-icon_9MFmC__Stat:before {
  content: "";
  background-color: var(--icons-gray);
  border-radius: 1px;
  width: 8px;
  height: 2px;
  display: block;
  position: absolute;
  top: calc(50% - 1px);
  left: calc(50% - 4px);
}

.TableFooter-module_table-footer__label--outcome_J9-gp__Stat .TableFooter-module_table-footer__label-icon_9MFmC__Stat:before {
  content: "";
  border-radius: 4px;
  width: 8px;
  height: 4px;
  display: block;
  position: absolute;
  top: calc(50% - 2px);
  left: calc(50% - 4px);
}

.TableFooter-module_table-footer__label--secondary-main_V2MC5__Stat .TableFooter-module_table-footer__label-icon_9MFmC__Stat:before {
  background-color: var(--icons-leagues-champions-league);
}

.TableFooter-module_table-footer__label--secondary-dark_ipaG2__Stat .TableFooter-module_table-footer__label-icon_9MFmC__Stat:before {
  background-color: var(--icons-leagues-champions-league-playoff);
}

.TableFooter-module_table-footer__label--yellow-light_b0pLU__Stat .TableFooter-module_table-footer__label-icon_9MFmC__Stat:before {
  background-color: var(--icons-leagues-european-cup-playoff);
}

.TableFooter-module_table-footer__label--yellow-main_UoQpG__Stat .TableFooter-module_table-footer__label-icon_9MFmC__Stat:before {
  background-color: var(--icons-leagues-european-cup);
}

.TableFooter-module_table-footer__label--green-main_zkqcO__Stat .TableFooter-module_table-footer__label-icon_9MFmC__Stat:before {
  background-color: var(--icons-leagues-conference-league);
}

.TableFooter-module_table-footer__label--green-light_SCd1r__Stat .TableFooter-module_table-footer__label-icon_9MFmC__Stat:before {
  background-color: var(--icons-leagues-conference-league-playoff);
}

.TableFooter-module_table-footer__label--gray-light_hvZu-__Stat .TableFooter-module_table-footer__label-icon_9MFmC__Stat:before {
  background-color: var(--icons-leagues-relegation);
}

.TableFooter-module_table-footer__label--red-main_gEvlr__Stat .TableFooter-module_table-footer__label-icon_9MFmC__Stat:before {
  background-color: var(--icons-leagues-left);
}

.TableFooter-module_table-footer__label-icon_9MFmC__Stat {
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  display: flex;
  position: relative;
}

.TableFooter-module_table-footer__label-caption_8KbDA__Stat {
  color: var(--text-secondary);
}

.TableFooter-module_table-footer__legends_fcNPV__Stat {
  flex-flow: wrap;
  gap: 10px;
  width: 100%;
  padding: 10px;
  display: flex;
}

.TableFooter-module_table-footer__legend-shorted_w4jMm__Stat {
  color: var(--text-primary);
}

.TableFooter-module_table-footer__legend-desc_itJ1y__Stat {
  color: var(--text-secondary);
}

@media (max-width: 660px) {
  .TableFooter-module_table-footer__labels_HaroH__Stat {
    grid-template-columns: 1fr 1fr;
    display: grid;
  }
}

.Controls-module_standings-controls_8Knjv__Stat {
  align-items: center;
  width: 100%;
  display: flex;
}

.Controls-module_standings-controls--not-widget_Eejhs__Stat {
  gap: 20px;
  padding: 0 20px 20px 10px;
}

.Controls-module_standings-controls--widget_YERIb__Stat {
  padding-bottom: 0;
}

@media (max-width: 660px) {
  .Controls-module_standings-controls--not-widget_Eejhs__Stat {
    overflow-x: scroll;
  }
}

.Standings-module_standings-table_4ySBP__Stat {
  background-color: var(--bg-block);
  border-radius: 15px;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  display: flex;
}

.Standings-module_standings-table--not-widget_P-lr5__Stat {
  min-height: 500px;
  padding: 20px 10px;
}

.Standings-module_standings-table__warning_dyzXs__Stat {
  width: 100%;
}

.Standings-module_standings-table__table_bjey3__Stat {
  border: 1px solid var(--line-line);
  border-radius: 10px;
  width: 100%;
  overflow: hidden;
}

.Standings-module_standings-table__group_rGPcX__Stat {
  text-transform: uppercase;
  color: var(--text-primary);
  padding: 0 10px 10px;
}

.Standings-module_standings-table__placeholder_zMML5__Stat {
  margin: 20px 0 0;
}

@media (max-width: 660px) {
  .Standings-module_standings-table_4ySBP__Stat {
    position: relative;
  }

  .Standings-module_standings-table__table_bjey3__Stat {
    border-bottom: 0;
    border-left: none;
    border-right: none;
  }

  .Standings-module_standings-table__placeholder_zMML5__Stat {
    margin: 40px 10px;
  }
}

.StatisticWidget-module_widgets_igXo5__Stat {
  flex-direction: column;
  gap: 20px;
  display: flex;
}

.TournamentsStatisticWidget-module_widget_eKDuu__Stat {
  background: var(--bg-block);
  border-radius: 10px;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  display: flex;
}

.TournamentsStatisticWidget-module_widget__title_-pF-s__Stat {
  color: var(--text-primary);
  padding-left: 10px;
}

.WhereToWatch-module_where-to-watch_7hiWH__Stat {
  background: url("https://stat-pictures.tribuna.com/image/f860acf1-3a84-4529-9e24-0625ea931cc7?quality=80") center / cover no-repeat local;
  border-radius: 15px;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
  display: flex;
}

.WhereToWatch-module_where-to-watch__title_3Zr5t__Stat {
  color: var(--text-white);
}

.WhereToWatch-module_where-to-watch__main_MqyN-__Stat {
  flex-direction: column;
  gap: 15px;
  display: flex;
}

.WhereToWatch-module_where-to-watch__main__card-text_HIUcQ__Stat {
  color: var(--text-white);
  display: inline;
}

.WhereToWatch-module_where-to-watch__main__card-text--blurred_CVA7T__Stat {
  filter: blur(3px);
}

.WhereToWatch-module_where-to-watch__main__show-more_e8dno__Stat {
  color: var(--text-white);
  text-transform: lowercase;
  cursor: pointer;
  align-items: center;
  gap: 5px;
  display: inline-flex;
}

.WhereToWatch-module_where-to-watch__main__show-more_e8dno__Stat svg path {
  fill: var(--text-white);
}

.WhereToWatch-module_where-to-watch__main__show-more_e8dno__Stat:hover {
  color: var(--text-link-active);
}

.WhereToWatch-module_where-to-watch__main__show-more_e8dno__Stat:hover svg path {
  fill: var(--text-link-active);
}

.WhereToWatch-module_where-to-watch__main__button_2BdOi__Stat {
  background-color: var(--bg-white);
  border: none;
  gap: 3px;
  display: flex;
}

.WhereToWatch-module_where-to-watch__main__button_2BdOi__Stat:hover {
  background-color: var(--buttons-hover);
}

.WhereToWatch-module_where-to-watch__main__button_2BdOi__Stat:hover .WhereToWatch-module_where-to-watch__main__button__text_1BTlz__Stat {
  color: var(--text-white);
}

.WhereToWatch-module_where-to-watch__main__button__text_1BTlz__Stat {
  color: var(--text-black);
}

.WhereToWatch-module_where-to-watch__main__button__logo_g9b85__Stat {
  width: 45px;
  height: 15px;
}

.WhereToWatch-module_where-to-watch__main__button__logo--blurred_ZZx4u__Stat {
  filter: blur(3px);
}

.BroadcastersList-module_broadcaster-list_HTNLQ__Stat {
  gap: 5px;
  padding-bottom: 2px;
  display: flex;
  overflow-x: auto;
}

.BroadcastersList-module_broadcaster-list_HTNLQ__Stat::-webkit-scrollbar {
  height: 5px;
}

.BroadcastersList-module_broadcaster-list_HTNLQ__Stat:hover::-webkit-scrollbar-thumb {
  background: var(--bg-white-opacity-20);
  opacity: .5;
  border-radius: 5px;
}

.BroadcasterItem-module_broadcaster-item_3RDl5__Stat {
  background: var(--bg-white-opacity-20);
  white-space: nowrap;
  border-radius: 10px;
  align-items: center;
  gap: 2px;
  padding: 5px 10px;
  display: flex;
}

.BroadcasterItem-module_broadcaster-item_3RDl5__Stat .BroadcasterItem-module_broadcaster-item__wrapper-img_xHSbp__Stat {
  max-width: 120px;
  max-height: 15px;
}

.BroadcasterItem-module_broadcaster-item_3RDl5__Stat .BroadcasterItem-module_broadcaster-item__wrapper-img_xHSbp__Stat .BroadcasterItem-module_broadcaster-item__wrapper-img__image_TO91i__Stat {
  position: static;
}

.BroadcasterItem-module_broadcaster-item__text_g-1eM__Stat {
  color: var(--text-white);
}

.BroadcasterItem-module_broadcaster-item__text--blurred_pUG05__Stat {
  filter: blur(3px);
}

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/74c003a2abab0c4f-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/24c15609eaa28576-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/84602850c8fd81c3-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/90da053edc2b7de3-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* math */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/ac3b7908202f8517-s.woff2) format('woff2');
  unicode-range: U+0302-0303, U+0305, U+0307-0308, U+0310, U+0312, U+0315, U+031A, U+0326-0327, U+032C, U+032F-0330, U+0332-0333, U+0338, U+033A, U+0346, U+034D, U+0391-03A1, U+03A3-03A9, U+03B1-03C9, U+03D1, U+03D5-03D6, U+03F0-03F1, U+03F4-03F5, U+2016-2017, U+2034-2038, U+203C, U+2040, U+2043, U+2047, U+2050, U+2057, U+205F, U+2070-2071, U+2074-208E, U+2090-209C, U+20D0-20DC, U+20E1, U+20E5-20EF, U+2100-2112, U+2114-2115, U+2117-2121, U+2123-214F, U+2190, U+2192, U+2194-21AE, U+21B0-21E5, U+21F1-21F2, U+21F4-2211, U+2213-2214, U+2216-22FF, U+2308-230B, U+2310, U+2319, U+231C-2321, U+2336-237A, U+237C, U+2395, U+239B-23B7, U+23D0, U+23DC-23E1, U+2474-2475, U+25AF, U+25B3, U+25B7, U+25BD, U+25C1, U+25CA, U+25CC, U+25FB, U+266D-266F, U+27C0-27FF, U+2900-2AFF, U+2B0E-2B11, U+2B30-2B4C, U+2BFE, U+3030, U+FF5B, U+FF5D, U+1D400-1D7FF, U+1EE00-1EEFF;
}
/* symbols */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/627d916fd739a539-s.woff2) format('woff2');
  unicode-range: U+0001-000C, U+000E-001F, U+007F-009F, U+20DD-20E0, U+20E2-20E4, U+2150-218F, U+2190, U+2192, U+2194-2199, U+21AF, U+21E6-21F0, U+21F3, U+2218-2219, U+2299, U+22C4-22C6, U+2300-243F, U+2440-244A, U+2460-24FF, U+25A0-27BF, U+2800-28FF, U+2921-2922, U+2981, U+29BF, U+29EB, U+2B00-2BFF, U+4DC0-4DFF, U+FFF9-FFFB, U+10140-1018E, U+10190-1019C, U+101A0, U+101D0-101FD, U+102E0-102FB, U+10E60-10E7E, U+1D2C0-1D2D3, U+1D2E0-1D37F, U+1F000-1F0FF, U+1F100-1F1AD, U+1F1E6-1F1FF, U+1F30D-1F30F, U+1F315, U+1F31C, U+1F31E, U+1F320-1F32C, U+1F336, U+1F378, U+1F37D, U+1F382, U+1F393-1F39F, U+1F3A7-1F3A8, U+1F3AC-1F3AF, U+1F3C2, U+1F3C4-1F3C6, U+1F3CA-1F3CE, U+1F3D4-1F3E0, U+1F3ED, U+1F3F1-1F3F3, U+1F3F5-1F3F7, U+1F408, U+1F415, U+1F41F, U+1F426, U+1F43F, U+1F441-1F442, U+1F444, U+1F446-1F449, U+1F44C-1F44E, U+1F453, U+1F46A, U+1F47D, U+1F4A3, U+1F4B0, U+1F4B3, U+1F4B9, U+1F4BB, U+1F4BF, U+1F4C8-1F4CB, U+1F4D6, U+1F4DA, U+1F4DF, U+1F4E3-1F4E6, U+1F4EA-1F4ED, U+1F4F7, U+1F4F9-1F4FB, U+1F4FD-1F4FE, U+1F503, U+1F507-1F50B, U+1F50D, U+1F512-1F513, U+1F53E-1F54A, U+1F54F-1F5FA, U+1F610, U+1F650-1F67F, U+1F687, U+1F68D, U+1F691, U+1F694, U+1F698, U+1F6AD, U+1F6B2, U+1F6B9-1F6BA, U+1F6BC, U+1F6C6-1F6CF, U+1F6D3-1F6D7, U+1F6E0-1F6EA, U+1F6F0-1F6F3, U+1F6F7-1F6FC, U+1F700-1F7FF, U+1F800-1F80B, U+1F810-1F847, U+1F850-1F859, U+1F860-1F887, U+1F890-1F8AD, U+1F8B0-1F8BB, U+1F8C0-1F8C1, U+1F900-1F90B, U+1F93B, U+1F946, U+1F984, U+1F996, U+1F9E9, U+1FA00-1FA6F, U+1FA70-1FA7C, U+1FA80-1FA89, U+1FA8F-1FAC6, U+1FACE-1FADC, U+1FADF-1FAE9, U+1FAF0-1FAF8, U+1FB00-1FBFF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/b5ee789b512e4d1b-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/2c07349e02a7b712-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/47cbc4e2adbc5db9-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/74c003a2abab0c4f-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/24c15609eaa28576-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/84602850c8fd81c3-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/90da053edc2b7de3-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* math */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/ac3b7908202f8517-s.woff2) format('woff2');
  unicode-range: U+0302-0303, U+0305, U+0307-0308, U+0310, U+0312, U+0315, U+031A, U+0326-0327, U+032C, U+032F-0330, U+0332-0333, U+0338, U+033A, U+0346, U+034D, U+0391-03A1, U+03A3-03A9, U+03B1-03C9, U+03D1, U+03D5-03D6, U+03F0-03F1, U+03F4-03F5, U+2016-2017, U+2034-2038, U+203C, U+2040, U+2043, U+2047, U+2050, U+2057, U+205F, U+2070-2071, U+2074-208E, U+2090-209C, U+20D0-20DC, U+20E1, U+20E5-20EF, U+2100-2112, U+2114-2115, U+2117-2121, U+2123-214F, U+2190, U+2192, U+2194-21AE, U+21B0-21E5, U+21F1-21F2, U+21F4-2211, U+2213-2214, U+2216-22FF, U+2308-230B, U+2310, U+2319, U+231C-2321, U+2336-237A, U+237C, U+2395, U+239B-23B7, U+23D0, U+23DC-23E1, U+2474-2475, U+25AF, U+25B3, U+25B7, U+25BD, U+25C1, U+25CA, U+25CC, U+25FB, U+266D-266F, U+27C0-27FF, U+2900-2AFF, U+2B0E-2B11, U+2B30-2B4C, U+2BFE, U+3030, U+FF5B, U+FF5D, U+1D400-1D7FF, U+1EE00-1EEFF;
}
/* symbols */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/627d916fd739a539-s.woff2) format('woff2');
  unicode-range: U+0001-000C, U+000E-001F, U+007F-009F, U+20DD-20E0, U+20E2-20E4, U+2150-218F, U+2190, U+2192, U+2194-2199, U+21AF, U+21E6-21F0, U+21F3, U+2218-2219, U+2299, U+22C4-22C6, U+2300-243F, U+2440-244A, U+2460-24FF, U+25A0-27BF, U+2800-28FF, U+2921-2922, U+2981, U+29BF, U+29EB, U+2B00-2BFF, U+4DC0-4DFF, U+FFF9-FFFB, U+10140-1018E, U+10190-1019C, U+101A0, U+101D0-101FD, U+102E0-102FB, U+10E60-10E7E, U+1D2C0-1D2D3, U+1D2E0-1D37F, U+1F000-1F0FF, U+1F100-1F1AD, U+1F1E6-1F1FF, U+1F30D-1F30F, U+1F315, U+1F31C, U+1F31E, U+1F320-1F32C, U+1F336, U+1F378, U+1F37D, U+1F382, U+1F393-1F39F, U+1F3A7-1F3A8, U+1F3AC-1F3AF, U+1F3C2, U+1F3C4-1F3C6, U+1F3CA-1F3CE, U+1F3D4-1F3E0, U+1F3ED, U+1F3F1-1F3F3, U+1F3F5-1F3F7, U+1F408, U+1F415, U+1F41F, U+1F426, U+1F43F, U+1F441-1F442, U+1F444, U+1F446-1F449, U+1F44C-1F44E, U+1F453, U+1F46A, U+1F47D, U+1F4A3, U+1F4B0, U+1F4B3, U+1F4B9, U+1F4BB, U+1F4BF, U+1F4C8-1F4CB, U+1F4D6, U+1F4DA, U+1F4DF, U+1F4E3-1F4E6, U+1F4EA-1F4ED, U+1F4F7, U+1F4F9-1F4FB, U+1F4FD-1F4FE, U+1F503, U+1F507-1F50B, U+1F50D, U+1F512-1F513, U+1F53E-1F54A, U+1F54F-1F5FA, U+1F610, U+1F650-1F67F, U+1F687, U+1F68D, U+1F691, U+1F694, U+1F698, U+1F6AD, U+1F6B2, U+1F6B9-1F6BA, U+1F6BC, U+1F6C6-1F6CF, U+1F6D3-1F6D7, U+1F6E0-1F6EA, U+1F6F0-1F6F3, U+1F6F7-1F6FC, U+1F700-1F7FF, U+1F800-1F80B, U+1F810-1F847, U+1F850-1F859, U+1F860-1F887, U+1F890-1F8AD, U+1F8B0-1F8BB, U+1F8C0-1F8C1, U+1F900-1F90B, U+1F93B, U+1F946, U+1F984, U+1F996, U+1F9E9, U+1FA00-1FA6F, U+1FA70-1FA7C, U+1FA80-1FA89, U+1FA8F-1FAC6, U+1FACE-1FADC, U+1FADF-1FAE9, U+1FAF0-1FAF8, U+1FB00-1FBFF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/b5ee789b512e4d1b-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/2c07349e02a7b712-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/47cbc4e2adbc5db9-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/74c003a2abab0c4f-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/24c15609eaa28576-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/84602850c8fd81c3-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/90da053edc2b7de3-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* math */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/ac3b7908202f8517-s.woff2) format('woff2');
  unicode-range: U+0302-0303, U+0305, U+0307-0308, U+0310, U+0312, U+0315, U+031A, U+0326-0327, U+032C, U+032F-0330, U+0332-0333, U+0338, U+033A, U+0346, U+034D, U+0391-03A1, U+03A3-03A9, U+03B1-03C9, U+03D1, U+03D5-03D6, U+03F0-03F1, U+03F4-03F5, U+2016-2017, U+2034-2038, U+203C, U+2040, U+2043, U+2047, U+2050, U+2057, U+205F, U+2070-2071, U+2074-208E, U+2090-209C, U+20D0-20DC, U+20E1, U+20E5-20EF, U+2100-2112, U+2114-2115, U+2117-2121, U+2123-214F, U+2190, U+2192, U+2194-21AE, U+21B0-21E5, U+21F1-21F2, U+21F4-2211, U+2213-2214, U+2216-22FF, U+2308-230B, U+2310, U+2319, U+231C-2321, U+2336-237A, U+237C, U+2395, U+239B-23B7, U+23D0, U+23DC-23E1, U+2474-2475, U+25AF, U+25B3, U+25B7, U+25BD, U+25C1, U+25CA, U+25CC, U+25FB, U+266D-266F, U+27C0-27FF, U+2900-2AFF, U+2B0E-2B11, U+2B30-2B4C, U+2BFE, U+3030, U+FF5B, U+FF5D, U+1D400-1D7FF, U+1EE00-1EEFF;
}
/* symbols */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/627d916fd739a539-s.woff2) format('woff2');
  unicode-range: U+0001-000C, U+000E-001F, U+007F-009F, U+20DD-20E0, U+20E2-20E4, U+2150-218F, U+2190, U+2192, U+2194-2199, U+21AF, U+21E6-21F0, U+21F3, U+2218-2219, U+2299, U+22C4-22C6, U+2300-243F, U+2440-244A, U+2460-24FF, U+25A0-27BF, U+2800-28FF, U+2921-2922, U+2981, U+29BF, U+29EB, U+2B00-2BFF, U+4DC0-4DFF, U+FFF9-FFFB, U+10140-1018E, U+10190-1019C, U+101A0, U+101D0-101FD, U+102E0-102FB, U+10E60-10E7E, U+1D2C0-1D2D3, U+1D2E0-1D37F, U+1F000-1F0FF, U+1F100-1F1AD, U+1F1E6-1F1FF, U+1F30D-1F30F, U+1F315, U+1F31C, U+1F31E, U+1F320-1F32C, U+1F336, U+1F378, U+1F37D, U+1F382, U+1F393-1F39F, U+1F3A7-1F3A8, U+1F3AC-1F3AF, U+1F3C2, U+1F3C4-1F3C6, U+1F3CA-1F3CE, U+1F3D4-1F3E0, U+1F3ED, U+1F3F1-1F3F3, U+1F3F5-1F3F7, U+1F408, U+1F415, U+1F41F, U+1F426, U+1F43F, U+1F441-1F442, U+1F444, U+1F446-1F449, U+1F44C-1F44E, U+1F453, U+1F46A, U+1F47D, U+1F4A3, U+1F4B0, U+1F4B3, U+1F4B9, U+1F4BB, U+1F4BF, U+1F4C8-1F4CB, U+1F4D6, U+1F4DA, U+1F4DF, U+1F4E3-1F4E6, U+1F4EA-1F4ED, U+1F4F7, U+1F4F9-1F4FB, U+1F4FD-1F4FE, U+1F503, U+1F507-1F50B, U+1F50D, U+1F512-1F513, U+1F53E-1F54A, U+1F54F-1F5FA, U+1F610, U+1F650-1F67F, U+1F687, U+1F68D, U+1F691, U+1F694, U+1F698, U+1F6AD, U+1F6B2, U+1F6B9-1F6BA, U+1F6BC, U+1F6C6-1F6CF, U+1F6D3-1F6D7, U+1F6E0-1F6EA, U+1F6F0-1F6F3, U+1F6F7-1F6FC, U+1F700-1F7FF, U+1F800-1F80B, U+1F810-1F847, U+1F850-1F859, U+1F860-1F887, U+1F890-1F8AD, U+1F8B0-1F8BB, U+1F8C0-1F8C1, U+1F900-1F90B, U+1F93B, U+1F946, U+1F984, U+1F996, U+1F9E9, U+1FA00-1FA6F, U+1FA70-1FA7C, U+1FA80-1FA89, U+1FA8F-1FAC6, U+1FACE-1FADC, U+1FADF-1FAE9, U+1FAF0-1FAF8, U+1FB00-1FBFF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/b5ee789b512e4d1b-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/2c07349e02a7b712-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/47cbc4e2adbc5db9-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/74c003a2abab0c4f-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/24c15609eaa28576-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/84602850c8fd81c3-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/90da053edc2b7de3-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* math */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/ac3b7908202f8517-s.woff2) format('woff2');
  unicode-range: U+0302-0303, U+0305, U+0307-0308, U+0310, U+0312, U+0315, U+031A, U+0326-0327, U+032C, U+032F-0330, U+0332-0333, U+0338, U+033A, U+0346, U+034D, U+0391-03A1, U+03A3-03A9, U+03B1-03C9, U+03D1, U+03D5-03D6, U+03F0-03F1, U+03F4-03F5, U+2016-2017, U+2034-2038, U+203C, U+2040, U+2043, U+2047, U+2050, U+2057, U+205F, U+2070-2071, U+2074-208E, U+2090-209C, U+20D0-20DC, U+20E1, U+20E5-20EF, U+2100-2112, U+2114-2115, U+2117-2121, U+2123-214F, U+2190, U+2192, U+2194-21AE, U+21B0-21E5, U+21F1-21F2, U+21F4-2211, U+2213-2214, U+2216-22FF, U+2308-230B, U+2310, U+2319, U+231C-2321, U+2336-237A, U+237C, U+2395, U+239B-23B7, U+23D0, U+23DC-23E1, U+2474-2475, U+25AF, U+25B3, U+25B7, U+25BD, U+25C1, U+25CA, U+25CC, U+25FB, U+266D-266F, U+27C0-27FF, U+2900-2AFF, U+2B0E-2B11, U+2B30-2B4C, U+2BFE, U+3030, U+FF5B, U+FF5D, U+1D400-1D7FF, U+1EE00-1EEFF;
}
/* symbols */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/627d916fd739a539-s.woff2) format('woff2');
  unicode-range: U+0001-000C, U+000E-001F, U+007F-009F, U+20DD-20E0, U+20E2-20E4, U+2150-218F, U+2190, U+2192, U+2194-2199, U+21AF, U+21E6-21F0, U+21F3, U+2218-2219, U+2299, U+22C4-22C6, U+2300-243F, U+2440-244A, U+2460-24FF, U+25A0-27BF, U+2800-28FF, U+2921-2922, U+2981, U+29BF, U+29EB, U+2B00-2BFF, U+4DC0-4DFF, U+FFF9-FFFB, U+10140-1018E, U+10190-1019C, U+101A0, U+101D0-101FD, U+102E0-102FB, U+10E60-10E7E, U+1D2C0-1D2D3, U+1D2E0-1D37F, U+1F000-1F0FF, U+1F100-1F1AD, U+1F1E6-1F1FF, U+1F30D-1F30F, U+1F315, U+1F31C, U+1F31E, U+1F320-1F32C, U+1F336, U+1F378, U+1F37D, U+1F382, U+1F393-1F39F, U+1F3A7-1F3A8, U+1F3AC-1F3AF, U+1F3C2, U+1F3C4-1F3C6, U+1F3CA-1F3CE, U+1F3D4-1F3E0, U+1F3ED, U+1F3F1-1F3F3, U+1F3F5-1F3F7, U+1F408, U+1F415, U+1F41F, U+1F426, U+1F43F, U+1F441-1F442, U+1F444, U+1F446-1F449, U+1F44C-1F44E, U+1F453, U+1F46A, U+1F47D, U+1F4A3, U+1F4B0, U+1F4B3, U+1F4B9, U+1F4BB, U+1F4BF, U+1F4C8-1F4CB, U+1F4D6, U+1F4DA, U+1F4DF, U+1F4E3-1F4E6, U+1F4EA-1F4ED, U+1F4F7, U+1F4F9-1F4FB, U+1F4FD-1F4FE, U+1F503, U+1F507-1F50B, U+1F50D, U+1F512-1F513, U+1F53E-1F54A, U+1F54F-1F5FA, U+1F610, U+1F650-1F67F, U+1F687, U+1F68D, U+1F691, U+1F694, U+1F698, U+1F6AD, U+1F6B2, U+1F6B9-1F6BA, U+1F6BC, U+1F6C6-1F6CF, U+1F6D3-1F6D7, U+1F6E0-1F6EA, U+1F6F0-1F6F3, U+1F6F7-1F6FC, U+1F700-1F7FF, U+1F800-1F80B, U+1F810-1F847, U+1F850-1F859, U+1F860-1F887, U+1F890-1F8AD, U+1F8B0-1F8BB, U+1F8C0-1F8C1, U+1F900-1F90B, U+1F93B, U+1F946, U+1F984, U+1F996, U+1F9E9, U+1FA00-1FA6F, U+1FA70-1FA7C, U+1FA80-1FA89, U+1FA8F-1FAC6, U+1FACE-1FADC, U+1FADF-1FAE9, U+1FAF0-1FAF8, U+1FB00-1FBFF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/b5ee789b512e4d1b-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/2c07349e02a7b712-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/47cbc4e2adbc5db9-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/74c003a2abab0c4f-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/24c15609eaa28576-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/84602850c8fd81c3-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/90da053edc2b7de3-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* math */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/ac3b7908202f8517-s.woff2) format('woff2');
  unicode-range: U+0302-0303, U+0305, U+0307-0308, U+0310, U+0312, U+0315, U+031A, U+0326-0327, U+032C, U+032F-0330, U+0332-0333, U+0338, U+033A, U+0346, U+034D, U+0391-03A1, U+03A3-03A9, U+03B1-03C9, U+03D1, U+03D5-03D6, U+03F0-03F1, U+03F4-03F5, U+2016-2017, U+2034-2038, U+203C, U+2040, U+2043, U+2047, U+2050, U+2057, U+205F, U+2070-2071, U+2074-208E, U+2090-209C, U+20D0-20DC, U+20E1, U+20E5-20EF, U+2100-2112, U+2114-2115, U+2117-2121, U+2123-214F, U+2190, U+2192, U+2194-21AE, U+21B0-21E5, U+21F1-21F2, U+21F4-2211, U+2213-2214, U+2216-22FF, U+2308-230B, U+2310, U+2319, U+231C-2321, U+2336-237A, U+237C, U+2395, U+239B-23B7, U+23D0, U+23DC-23E1, U+2474-2475, U+25AF, U+25B3, U+25B7, U+25BD, U+25C1, U+25CA, U+25CC, U+25FB, U+266D-266F, U+27C0-27FF, U+2900-2AFF, U+2B0E-2B11, U+2B30-2B4C, U+2BFE, U+3030, U+FF5B, U+FF5D, U+1D400-1D7FF, U+1EE00-1EEFF;
}
/* symbols */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/627d916fd739a539-s.woff2) format('woff2');
  unicode-range: U+0001-000C, U+000E-001F, U+007F-009F, U+20DD-20E0, U+20E2-20E4, U+2150-218F, U+2190, U+2192, U+2194-2199, U+21AF, U+21E6-21F0, U+21F3, U+2218-2219, U+2299, U+22C4-22C6, U+2300-243F, U+2440-244A, U+2460-24FF, U+25A0-27BF, U+2800-28FF, U+2921-2922, U+2981, U+29BF, U+29EB, U+2B00-2BFF, U+4DC0-4DFF, U+FFF9-FFFB, U+10140-1018E, U+10190-1019C, U+101A0, U+101D0-101FD, U+102E0-102FB, U+10E60-10E7E, U+1D2C0-1D2D3, U+1D2E0-1D37F, U+1F000-1F0FF, U+1F100-1F1AD, U+1F1E6-1F1FF, U+1F30D-1F30F, U+1F315, U+1F31C, U+1F31E, U+1F320-1F32C, U+1F336, U+1F378, U+1F37D, U+1F382, U+1F393-1F39F, U+1F3A7-1F3A8, U+1F3AC-1F3AF, U+1F3C2, U+1F3C4-1F3C6, U+1F3CA-1F3CE, U+1F3D4-1F3E0, U+1F3ED, U+1F3F1-1F3F3, U+1F3F5-1F3F7, U+1F408, U+1F415, U+1F41F, U+1F426, U+1F43F, U+1F441-1F442, U+1F444, U+1F446-1F449, U+1F44C-1F44E, U+1F453, U+1F46A, U+1F47D, U+1F4A3, U+1F4B0, U+1F4B3, U+1F4B9, U+1F4BB, U+1F4BF, U+1F4C8-1F4CB, U+1F4D6, U+1F4DA, U+1F4DF, U+1F4E3-1F4E6, U+1F4EA-1F4ED, U+1F4F7, U+1F4F9-1F4FB, U+1F4FD-1F4FE, U+1F503, U+1F507-1F50B, U+1F50D, U+1F512-1F513, U+1F53E-1F54A, U+1F54F-1F5FA, U+1F610, U+1F650-1F67F, U+1F687, U+1F68D, U+1F691, U+1F694, U+1F698, U+1F6AD, U+1F6B2, U+1F6B9-1F6BA, U+1F6BC, U+1F6C6-1F6CF, U+1F6D3-1F6D7, U+1F6E0-1F6EA, U+1F6F0-1F6F3, U+1F6F7-1F6FC, U+1F700-1F7FF, U+1F800-1F80B, U+1F810-1F847, U+1F850-1F859, U+1F860-1F887, U+1F890-1F8AD, U+1F8B0-1F8BB, U+1F8C0-1F8C1, U+1F900-1F90B, U+1F93B, U+1F946, U+1F984, U+1F996, U+1F9E9, U+1FA00-1FA6F, U+1FA70-1FA7C, U+1FA80-1FA89, U+1FA8F-1FAC6, U+1FACE-1FADC, U+1FADF-1FAE9, U+1FAF0-1FAF8, U+1FB00-1FBFF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/b5ee789b512e4d1b-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/2c07349e02a7b712-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/47cbc4e2adbc5db9-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/74c003a2abab0c4f-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/24c15609eaa28576-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/84602850c8fd81c3-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/90da053edc2b7de3-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* math */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/ac3b7908202f8517-s.woff2) format('woff2');
  unicode-range: U+0302-0303, U+0305, U+0307-0308, U+0310, U+0312, U+0315, U+031A, U+0326-0327, U+032C, U+032F-0330, U+0332-0333, U+0338, U+033A, U+0346, U+034D, U+0391-03A1, U+03A3-03A9, U+03B1-03C9, U+03D1, U+03D5-03D6, U+03F0-03F1, U+03F4-03F5, U+2016-2017, U+2034-2038, U+203C, U+2040, U+2043, U+2047, U+2050, U+2057, U+205F, U+2070-2071, U+2074-208E, U+2090-209C, U+20D0-20DC, U+20E1, U+20E5-20EF, U+2100-2112, U+2114-2115, U+2117-2121, U+2123-214F, U+2190, U+2192, U+2194-21AE, U+21B0-21E5, U+21F1-21F2, U+21F4-2211, U+2213-2214, U+2216-22FF, U+2308-230B, U+2310, U+2319, U+231C-2321, U+2336-237A, U+237C, U+2395, U+239B-23B7, U+23D0, U+23DC-23E1, U+2474-2475, U+25AF, U+25B3, U+25B7, U+25BD, U+25C1, U+25CA, U+25CC, U+25FB, U+266D-266F, U+27C0-27FF, U+2900-2AFF, U+2B0E-2B11, U+2B30-2B4C, U+2BFE, U+3030, U+FF5B, U+FF5D, U+1D400-1D7FF, U+1EE00-1EEFF;
}
/* symbols */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/627d916fd739a539-s.woff2) format('woff2');
  unicode-range: U+0001-000C, U+000E-001F, U+007F-009F, U+20DD-20E0, U+20E2-20E4, U+2150-218F, U+2190, U+2192, U+2194-2199, U+21AF, U+21E6-21F0, U+21F3, U+2218-2219, U+2299, U+22C4-22C6, U+2300-243F, U+2440-244A, U+2460-24FF, U+25A0-27BF, U+2800-28FF, U+2921-2922, U+2981, U+29BF, U+29EB, U+2B00-2BFF, U+4DC0-4DFF, U+FFF9-FFFB, U+10140-1018E, U+10190-1019C, U+101A0, U+101D0-101FD, U+102E0-102FB, U+10E60-10E7E, U+1D2C0-1D2D3, U+1D2E0-1D37F, U+1F000-1F0FF, U+1F100-1F1AD, U+1F1E6-1F1FF, U+1F30D-1F30F, U+1F315, U+1F31C, U+1F31E, U+1F320-1F32C, U+1F336, U+1F378, U+1F37D, U+1F382, U+1F393-1F39F, U+1F3A7-1F3A8, U+1F3AC-1F3AF, U+1F3C2, U+1F3C4-1F3C6, U+1F3CA-1F3CE, U+1F3D4-1F3E0, U+1F3ED, U+1F3F1-1F3F3, U+1F3F5-1F3F7, U+1F408, U+1F415, U+1F41F, U+1F426, U+1F43F, U+1F441-1F442, U+1F444, U+1F446-1F449, U+1F44C-1F44E, U+1F453, U+1F46A, U+1F47D, U+1F4A3, U+1F4B0, U+1F4B3, U+1F4B9, U+1F4BB, U+1F4BF, U+1F4C8-1F4CB, U+1F4D6, U+1F4DA, U+1F4DF, U+1F4E3-1F4E6, U+1F4EA-1F4ED, U+1F4F7, U+1F4F9-1F4FB, U+1F4FD-1F4FE, U+1F503, U+1F507-1F50B, U+1F50D, U+1F512-1F513, U+1F53E-1F54A, U+1F54F-1F5FA, U+1F610, U+1F650-1F67F, U+1F687, U+1F68D, U+1F691, U+1F694, U+1F698, U+1F6AD, U+1F6B2, U+1F6B9-1F6BA, U+1F6BC, U+1F6C6-1F6CF, U+1F6D3-1F6D7, U+1F6E0-1F6EA, U+1F6F0-1F6F3, U+1F6F7-1F6FC, U+1F700-1F7FF, U+1F800-1F80B, U+1F810-1F847, U+1F850-1F859, U+1F860-1F887, U+1F890-1F8AD, U+1F8B0-1F8BB, U+1F8C0-1F8C1, U+1F900-1F90B, U+1F93B, U+1F946, U+1F984, U+1F996, U+1F9E9, U+1FA00-1FA6F, U+1FA70-1FA7C, U+1FA80-1FA89, U+1FA8F-1FAC6, U+1FACE-1FADC, U+1FADF-1FAE9, U+1FAF0-1FAF8, U+1FB00-1FBFF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/b5ee789b512e4d1b-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/2c07349e02a7b712-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/47cbc4e2adbc5db9-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: 'Roboto Fallback';src: local("Arial");ascent-override: 92.98%;descent-override: 24.47%;line-gap-override: 0.00%;size-adjust: 99.78%
}.__className_7eec81 {font-family: 'Roboto', 'Roboto Fallback';font-style: normal
}.__variable_7eec81 {--roboto-font: 'Roboto', 'Roboto Fallback'
}

/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/3f69592b2fe603c7-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/6325a8417175c41d-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/021bc4481ed92ece-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/99b7f73d5af7c3e2-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://____TRIBUNA_STATIC_DOMAIN____/_next/static/media/4f05ba3a6752a328-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: 'Montserrat Fallback';src: local("Arial");ascent-override: 85.79%;descent-override: 22.25%;line-gap-override: 0.00%;size-adjust: 112.83%
}.__className_0b89e0 {font-family: 'Montserrat', 'Montserrat Fallback';font-style: normal
}.__variable_0b89e0 {--montserrat-font: 'Montserrat', 'Montserrat Fallback'
}

:root[data-theme="dark"] {
  --text-primary: #fff;
  --text-primary-white: #2b2b2b;
  --text-secondary: #a2a6b1;
  --text-tertiary: #6f7279;
  --text-error-live: #e05555;
  --text-white: #fff;
  --text-black: #2b2b2b;
  --text-link-active: #5f8bff;
  --text-success: #008451;
  --bg-page: #2b2b2b;
  --bg-block: #313131;
  --bg-block-gray: #313131;
  --bg-component: #3c3c3c;
  --bg-prime: #5f8bff;
  --bg-nba: #fff;
  --bg-white: #fff;
  --bg-green: #e2f4a6;
  --bg-black-2: #212121;
  --bg-black-3: #000;
  --line-line: #4b4c50;
  --line-stroke: #6f7279;
  --line-active: #5f8bff;
  --line-nba: #2b2b2b;
  --icons-primary: #fff;
  --icons-primary-light: #313131;
  --icons-gray: #6f7279;
  --icons-gray-dark: #a2a6b1;
  --icons-white: #fff;
  --icons-black: #2b2b2b;
  --icons-green: #008451;
  --icons-green-light: #00dd4a;
  --icons-red: #e05555;
  --icons-yellow: #f6c247;
  --icons-active: #5f8bff;
  --icons-secondary: #353d55;
  --icons-placeholder-0-data: #5f8bff;
  --icons-placeholder-0-data-2: #455b95;
  --icons-placeholder-bg: var(--bg-component);
  --icons-placeholder-logo: #6f7279;
  --icons-leagues-champions-league: #5f8bff;
  --icons-leagues-champions-league-playoff: #455b95;
  --icons-leagues-european-cup: #f6c247;
  --icons-leagues-european-cup-playoff: #ffe178;
  --icons-leagues-conference-league: #008451;
  --icons-leagues-conference-league-playoff: #24b57e;
  --icons-leagues-relegation: var(--icons-gray);
  --icons-leagues-left: #fb9090;
  --label-general-1-bg: var(--bg-component);
  --label-general-1-text: var(--text-secondary);
  --label-general-2-bg: #5f8bff;
  --label-general-2-text: var(--text-white);
  --label-general-3-bg: #353d55;
  --label-general-3-text: var(--text-link-active);
  --label-general-4-bg: var(--bg-block);
  --label-general-4-text: var(--text-primary);
  --label-transfers-bg: var(--bg-component);
  --label-transfers-text: var(--text-primary);
  --label-text-broadcast-bg: #fff;
  --label-text-broadcast-text: #2b2b2b;
  --label-text-broadcast-bg-delete: #e05555;
  --label-notifications-bg: #5f8bff;
  --label-notifications-text: var(--text-white);
  --label-notifications-post-bg: #fff;
  --label-notifications-post-text: #2b2b2b;
  --label-notifications-message-bg: #5f8bff;
  --label-notifications-message-text: var(--text-white);
  --label-bonus-blue: #5f8bff;
  --label-bonus-green: #008451;
  --label-bonus-yellow: #f6c247;
  --label-clubs-text: #fff;
  --buttons-hover: #5f8bff;
  --buttons-text: #fff;
  --buttons-filled-bg: #484a50;
  --buttons-filled-text: var(--text-white);
  --buttons-outlined-stroke: #cacdd4;
  --buttons-outlined-text: var(--text-primary);
  --buttons-subscribed-text: var(--text-secondary);
  --buttons-subscribed-stroke: var(--line-line);
  --buttons-subscribed-bg: var(--bg-block);
  --buttons-subscribe-text: var(--text-white);
  --buttons-subscribe-bg: var(--buttons-filled-bg);
  --buttons-tag-hover: #5f8bff;
  --buttons-tag-default-stroke: var(--buttons-outlined-stroke);
  --buttons-tag-default-text: var(--text-primary);
  --buttons-tag-not-active-stroke: var(--line-stroke);
  --buttons-tag-not-active-text: var(--text-secondary);
  --buttons-switch-theme-bg: var(--bg-component);
  --buttons-switch-theme-light-icon-bg: #fff;
  --buttons-switch-theme-light-icon-icon: var(--icons-black);
  --buttons-switch-theme-dark-icon-bg: var(--bg-component);
  --buttons-switch-theme-dark-icon-icon: var(--icons-gray);
  --buttons-tab-switch-active-bg: #5f8bff;
  --buttons-tab-switch-active-text: var(--text-white);
  --buttons-tab-switch-default-bg: var(--bg-block);
  --buttons-tab-switch-default-text: var(--text-primary);
  --buttons-tab-switch-default-stroke: var(--line-stroke);
  --buttons-bonus-bg: #fff;
  --buttons-bonus-text: #2b2b2b;
  --input-text-empty: var(--text-secondary);
  --input-text-full: var(--text-primary);
  --input-hover-focus: var(--line-active);
  --input-stroke: var(--line-line);
  --input-error: #e05555;
  --input-optional-text: var(--text-secondary);
  --input-bg: var(--bg-block);
  --list-text: var(--text-primary);
  --list-bg: var(--bg-block);
  --list-bg-hover: var(--bg-component);
  --list-text-active: var(--text-link-active);
  --list-bg-active: #353d55;
  --list-nba-text: #2b2b2b;
  --list-nba-bg: #fff;
  --list-nba-text-active: #fff;
  --list-nba-bg-active: #2b2b2b;
  --dropdown-text: var(--text-primary);
  --dropdown-stroke: var(--line-stroke);
  --dropdown-line: var(--line-line);
  --dropdown-bg: var(--bg-block);
  --dropdown-text-active: var(--text-link-active);
  --dropdown-hover: var(--line-active);
  --dropdown-bg-active: var(--list-bg-active);
  --dropdown-error: #e05555;
  --disabled-text: #656565;
  --disabled-bg: #3a3a3a;
  --disabled-stroke: #4d4d4d;
  --disabled-icons: #656565;
  --match-bg: #4b4c50;
  --match-bg-score-default: #484a50;
  --match-bg-score-full-time: #a2a6b1;
  --match-bg-score-live: #24b57e;
  --match-bg-score-not-started: var(--bg-component);
  --match-team-1-bg: #5f8bff;
  --match-team-1-text: #fff;
  --match-status-line-live: #5f8bff;
  --match-status-line-bg: #353d55;
  --match-team-2-bg: #fff;
  --match-team-2-text: #2b2b2b;
  --calendar-today: #5f8bff;
  --calendar-selected-date: #5f8bff;
  --calendar-hover: #353d55;
  --survey-bg: var(--bg-component);
  --survey-text: var(--text-primary);
  --survey-stroke: var(--line-line);
  --survey-choice-bg: #353d55;
  --survey-choice-text: #5f8bff;
  --survey-choice-stroke: #5f8bff;
  --tooltip-bg: #fff;
  --tooltip-text: var(--text-primary-white);
  --statistic-black: #fff;
  --statistic-blue: #5f8bff;
  --statistic-blue-light: #353d55;
  --statistic-green: #008451;
  --statistic-red: #e05555;
  --statistic-yellow: #f6c247;
  --fullscreen-bg: rgba(43, 43, 43, .5);
  --table-shadow-bg: rgba(0, 0, 0, .05);
  --field-line: rgba(255, 255, 255, .15);
  --label-bonus-blue-bg: rgba(95, 139, 255, .1);
  --label-bonus-green-bg: rgba(0, 132, 81, .1);
  --label-bonus-yellow-bg: rgba(246, 194, 71, .1);
  --bg-white-opacity-20: rgba(255, 255, 255, .2);
  --bg-white-opacity-5: rgba(255, 255, 255, .05);
  --bg-light-green-opacity-30: rgba(0, 221, 74, .3);
  --advertisement-orange: #ff4800;
  --advertisement-orange-opacity-40: rgba(255, 72, 0, .4);
  --football-field-bg: #227a50;
  --football-field-t-shirt-1: #2b2b2b;
  --football-field-t-shirt-2: #e2eafd;
  --bg-green-opacity-60: rgba(225, 244, 164, .6);
  --bg-white-opacity-1: rgba(255, 255, 255, .01);
  --bg-white-opacity-10: rgba(255, 255, 255, .1);
  --text-white-opacity-50: rgba(255, 255, 255, .5);
  --text-white-opacity-60: rgba(255, 255, 255, .6);
  --text-primary-opacity-30: rgba(255, 255, 255, .3);
  --match-status-line-opacity-10: rgba(95, 139, 255, .1);
  --icons-yellow-opacity-20: rgba(246, 194, 71, .2);
  --label-text-broadcast-bg-opacity-10: rgba(255, 255, 255, .1);
  --label-text-broadcast-bg-delete-opacity-10: rgba(224, 85, 85, .1);
  --labels-general-2-bg: #5f8bff;
  --labels-new-blue: #3d72ed;
  --labels-new-violet: #8843fa;
  --match-penalty-background-opacity-10: rgba(224, 85, 85, .1);
}

:root, :root[data-theme="light"] {
  --text-primary: #2b2b2b;
  --text-primary-white: #fff;
  --text-secondary: #6f7279;
  --text-tertiary: #a2a6b1;
  --text-error-live: #e05555;
  --text-white: #fff;
  --text-black: #2b2b2b;
  --text-link-active: #1b4fc8;
  --text-success: #008451;
  --bg-page: #eef0f3;
  --bg-block: #fff;
  --bg-block-gray: #313131;
  --bg-component: #f2f4f7;
  --bg-prime: #1b4fc8;
  --bg-nba: #2b2b2b;
  --bg-white: #fff;
  --bg-green: #e2f4a6;
  --bg-black-2: #212121;
  --bg-black-3: #000;
  --line-line: #cacdd4;
  --line-stroke: #a2a6b1;
  --line-active: #1b4fc8;
  --line-nba: #fff;
  --icons-primary: #2b2b2b;
  --icons-primary-light: #fff;
  --icons-gray: #cacdd4;
  --icons-gray-dark: #a2a6b1;
  --icons-white: #fff;
  --icons-black: #2b2b2b;
  --icons-green: #008451;
  --icons-green-light: #00dd4a;
  --icons-red: #e05555;
  --icons-yellow: #f6c247;
  --icons-active: #1b4fc8;
  --icons-secondary: #e2eafd;
  --icons-placeholder-0-data: #1b4fc8;
  --icons-placeholder-0-data-2: #9eb8f5;
  --icons-placeholder-bg: var(--bg-component);
  --icons-placeholder-logo: #cacdd4;
  --icons-leagues-champions-league: #1b4fc8;
  --icons-leagues-champions-league-playoff: #9eb8f5;
  --icons-leagues-european-cup: #f6c247;
  --icons-leagues-european-cup-playoff: #ffe178;
  --icons-leagues-conference-league: #008451;
  --icons-leagues-conference-league-playoff: #24b57e;
  --icons-leagues-relegation: var(--icons-gray);
  --icons-leagues-left: #fb9090;
  --label-general-1-bg: var(--bg-component);
  --label-general-1-text: var(--text-secondary);
  --label-general-2-bg: #1b4fc8;
  --label-general-2-text: var(--text-white);
  --label-general-3-bg: #e2eafd;
  --label-general-3-text: var(--text-link-active);
  --label-general-4-bg: var(--bg-block);
  --label-general-4-text: var(--text-primary);
  --label-transfers-bg: var(--bg-component);
  --label-transfers-text: var(--text-primary);
  --label-text-broadcast-bg: #2b2b2b;
  --label-text-broadcast-text: #fff;
  --label-text-broadcast-bg-delete: #e05555;
  --label-notifications-bg: #1b4fc8;
  --label-notifications-text: var(--text-white);
  --label-notifications-post-bg: #2b2b2b;
  --label-notifications-post-text: #fff;
  --label-notifications-message-bg: #1b4fc8;
  --label-notifications-message-text: var(--text-white);
  --label-bonus-blue: #1b4fc8;
  --label-bonus-green: #008451;
  --label-bonus-yellow: #bf920b;
  --label-clubs-text: #2b2b2b;
  --buttons-hover: #1b4fc8;
  --buttons-text: #2b2b2b;
  --buttons-filled-bg: #2b2b2b;
  --buttons-filled-text: var(--text-white);
  --buttons-outlined-stroke: #4b4c50;
  --buttons-outlined-text: var(--text-primary);
  --buttons-subscribed-text: var(--text-secondary);
  --buttons-subscribed-stroke: var(--line-line);
  --buttons-subscribed-bg: var(--bg-block);
  --buttons-subscribe-text: var(--text-white);
  --buttons-subscribe-bg: var(--buttons-filled-bg);
  --buttons-tag-hover: #1b4fc8;
  --buttons-tag-default-stroke: var(--buttons-outlined-stroke);
  --buttons-tag-default-text: var(--text-primary);
  --buttons-tag-not-active-stroke: var(--line-stroke);
  --buttons-tag-not-active-text: var(--text-secondary);
  --buttons-switch-theme-bg: var(--bg-component);
  --buttons-switch-theme-light-icon-bg: var(--bg-component);
  --buttons-switch-theme-light-icon-icon: var(--icons-gray-dark);
  --buttons-switch-theme-dark-icon-bg: #2b2b2b;
  --buttons-switch-theme-dark-icon-icon: var(--icons-white);
  --buttons-tab-switch-active-bg: #1b4fc8;
  --buttons-tab-switch-active-text: var(--text-white);
  --buttons-tab-switch-default-bg: var(--bg-block);
  --buttons-tab-switch-default-text: var(--text-primary);
  --buttons-tab-switch-default-stroke: var(--line-stroke);
  --buttons-bonus-bg: #fff;
  --buttons-bonus-text: #2b2b2b;
  --input-text-empty: var(--text-secondary);
  --input-text-full: var(--text-primary);
  --input-hover-focus: var(--line-active);
  --input-stroke: var(--line-line);
  --input-error: #e05555;
  --input-optional-text: var(--text-secondary);
  --input-bg: var(--bg-block);
  --list-text: var(--text-primary);
  --list-bg: var(--bg-block);
  --list-bg-hover: var(--bg-component);
  --list-text-active: var(--text-link-active);
  --list-bg-active: #e2eafd;
  --list-nba-text: #fff;
  --list-nba-bg: #2b2b2b;
  --list-nba-text-active: #2b2b2b;
  --list-nba-bg-active: #fff;
  --dropdown-text: var(--text-primary);
  --dropdown-stroke: var(--line-stroke);
  --dropdown-line: var(--line-line);
  --dropdown-bg: var(--bg-block);
  --dropdown-text-active: var(--text-link-active);
  --dropdown-hover: var(--line-active);
  --dropdown-bg-active: var(--list-bg-active);
  --dropdown-error: #e05555;
  --disabled-text: #b3b3b3;
  --disabled-bg: #e5e5e5;
  --disabled-stroke: #cdcdcd;
  --disabled-icons: #b3b3b3;
  --match-bg: #cacdd4;
  --match-bg-score-default: #2b2b2b;
  --match-bg-score-full-time: #a2a6b1;
  --match-bg-score-live: #24b57e;
  --match-bg-score-not-started: var(--bg-component);
  --match-team-1-bg: #1b4fc8;
  --match-team-1-text: #fff;
  --match-status-line-live: #1b4fc8;
  --match-status-line-bg: #e2eafd;
  --match-team-2-bg: #2b2b2b;
  --match-team-2-text: #fff;
  --calendar-today: #1b4fc8;
  --calendar-selected-date: #1b4fc8;
  --calendar-hover: #e2eafd;
  --survey-bg: var(--bg-component);
  --survey-text: var(--text-primary);
  --survey-stroke: var(--line-line);
  --survey-choice-bg: #e2eafd;
  --survey-choice-text: #1b4fc8;
  --survey-choice-stroke: #1b4fc8;
  --tooltip-bg: #2b2b2b;
  --tooltip-text: var(--text-primary-white);
  --statistic-black: #2b2b2b;
  --statistic-blue: #1b4fc8;
  --statistic-blue-light: #e2eafd;
  --statistic-green: #008451;
  --statistic-red: #e05555;
  --statistic-yellow: #f6c247;
  --fullscreen-bg: rgba(43, 43, 43, .5);
  --table-shadow-bg: rgba(0, 0, 0, .05);
  --field-line: rgba(255, 255, 255, .15);
  --comment-selected-bg: rgba(61, 114, 237, .15);
  --label-bonus-blue-bg: rgba(61, 114, 237, .1);
  --label-bonus-green-bg: rgba(0, 132, 81, .1);
  --label-bonus-yellow-bg: rgba(191, 146, 11, .1);
  --bg-white-opacity-20: rgba(255, 255, 255, .2);
  --bg-white-opacity-5: rgba(255, 255, 255, .05);
  --bg-light-green-opacity-30: rgba(0, 221, 74, .3);
  --advertisement-orange: #ff4800;
  --advertisement-orange-opacity-40: rgba(255, 72, 0, .4);
  --football-field-bg: #227a50;
  --football-field-t-shirt-1: #2b2b2b;
  --football-field-t-shirt-2: #e2eafd;
  --bg-green-opacity-60: rgba(225, 244, 164, .6);
  --bg-white-opacity-1: rgba(255, 255, 255, .01);
  --bg-white-opacity-10: rgba(255, 255, 255, .1);
  --text-white-opacity-50: rgba(255, 255, 255, .5);
  --text-white-opacity-60: rgba(255, 255, 255, .6);
  --text-primary-opacity-30: rgba(43, 43, 43, .3);
  --match-status-line-opacity-10: rgba(61, 114, 237, .1);
  --icons-yellow-opacity-20: rgba(246, 194, 71, .2);
  --label-text-broadcast-bg-opacity-10: rgba(43, 43, 43, .1);
  --label-text-broadcast-bg-delete-opacity-10: rgba(224, 85, 85, .1);
  --labels-general-2-bg: #1b4fc8;
  --labels-new-blue: #3d72ed;
  --labels-new-violet: #8843fa;
  --match-penalty-background-opacity-10: rgba(224, 85, 85, .1);
}

.NewUiText-module_text_qmUyw__Main {
  font-family: var(--montserrat-font), sans-serif;
  font-style: normal;
}

.NewUiText-module_text--h1_eoKkV__Main {
  letter-spacing: .15px;
  font-size: 18px;
  font-weight: 650;
  line-height: 25px;
}

.NewUiText-module_text--h2_-bwDU__Main {
  letter-spacing: .15px;
  font-size: 16px;
  font-weight: 680;
  line-height: 20px;
}

.NewUiText-module_text--h3_C80hV__Main {
  letter-spacing: .15px;
  font-size: 14px;
  font-weight: 680;
  line-height: 20px;
}

.NewUiText-module_text--subtitle-1_EbJBq__Main {
  letter-spacing: .15px;
  font-size: 12px;
  font-weight: 480;
  line-height: 15px;
}

.NewUiText-module_text--subtitle-2_HH1yd__Main {
  letter-spacing: .15px;
  font-size: 14px;
  font-weight: 480;
  line-height: 20px;
}

.NewUiText-module_text--subtitle-3_MKm6l__Main {
  letter-spacing: .15px;
  font-size: 16px;
  font-weight: 680;
  line-height: 20px;
}

.NewUiText-module_text--subtitle-4_vp-5q__Main {
  letter-spacing: .15px;
  font-size: 13px;
  font-weight: 480;
  line-height: 20px;
}

.NewUiText-module_text--subtitle-5_UJVQH__Main {
  letter-spacing: .15px;
  font-size: 14px;
  font-weight: 580;
  line-height: 20px;
}

.NewUiText-module_text--subtitle-6_VadI3__Main {
  letter-spacing: .15px;
  font-size: 28px;
  font-weight: 650;
  line-height: 35px;
}

.NewUiText-module_text--subtitle-7_2l4x8__Main {
  letter-spacing: .15px;
  font-size: 10px;
  font-weight: 580;
  line-height: 15px;
}

.NewUiText-module_text--subtitle-8_fr6r-__Main {
  letter-spacing: .15px;
  font-size: 20px;
  font-weight: 650;
  line-height: 30px;
}

.NewUiText-module_text--subtitle-9_ETeMn__Main {
  letter-spacing: .15px;
  font-size: 12px;
  font-weight: 580;
  line-height: 15px;
}

.NewUiText-module_text--body-1_FUoUN__Main {
  font-family: var(--roboto-font), sans-serif;
  letter-spacing: .15px;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
}

.NewUiText-module_text--body-2_YOO3K__Main {
  font-family: var(--roboto-font), sans-serif;
  letter-spacing: .15px;
  font-size: 16px;
  font-weight: 680;
  line-height: 25px;
}

.NewUiText-module_text--body-3_if5Yy__Main {
  font-family: var(--roboto-font), sans-serif;
  letter-spacing: .15px;
  font-size: 12px;
  font-weight: 380;
  line-height: 15px;
}

.NewUiText-module_text--body-4_72aiB__Main {
  font-family: var(--roboto-font), sans-serif;
  letter-spacing: .15px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.NewUiText-module_text--button_FLtVN__Main {
  letter-spacing: .15px;
  font-size: 14px;
  font-weight: 580;
  line-height: 20px;
}

.NewUiText-module_text--button-2_-PFsU__Main {
  letter-spacing: .15px;
  font-size: 12px;
  font-weight: 580;
  line-height: 15px;
}

.NewUiText-module_text--caption_V2DW-__Main {
  font-family: var(--roboto-font), sans-serif;
  letter-spacing: .4px;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
}

.NewUiText-module_text--overline_fAPqz__Main {
  letter-spacing: .15px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 680;
  line-height: 15px;
}

.NewUiText-module_text--overline-2_0SY9x__Main {
  letter-spacing: .15px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 480;
  line-height: 15px;
}

.NewUiText-module_text--link_zCxhk__Main:hover {
  color: var(--text-link-active);
}

.NewUiText-module_text--important-info_012Lw__Main {
  letter-spacing: .25px;
  font-size: 46px;
  font-weight: 800;
  line-height: 56px;
}

.Ui-button-module_btn_uGeHD__Main {
  text-align: center;
  text-overflow: ellipsis;
  cursor: pointer;
  font-family: var(--montserrat-font), sans-serif;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border-radius: 10px;
  outline: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.Ui-button-module_btn--text-type-button_8YCYr__Main {
  letter-spacing: .15px;
  font-size: 14px;
  font-style: normal;
  font-weight: 580;
  line-height: 20px;
}

.Ui-button-module_btn--text-type-button-2_UefFh__Main {
  letter-spacing: .15px;
  font-size: 12px;
  font-style: normal;
  font-weight: 580;
  line-height: 15px;
}

.Ui-button-module_btn_uGeHD__Main[disabled] {
  cursor: not-allowed;
}

.Ui-button-module_btn--full-width_k1YXj__Main {
  width: 100%;
}

.Ui-button-module_btn--color-inherited_Mgi7S__Main {
  background-color: inherit;
  color: currentColor;
}

.Ui-button-module_btn--size-large_63Q3p__Main {
  height: 50px;
  min-height: 50px;
  padding: 0 20px;
}

.Ui-button-module_btn--size-medium_xG5QX__Main {
  height: 40px;
  min-height: 40px;
  padding: 0 20px;
}

.Ui-button-module_btn--size-small_YfTNK__Main {
  height: 30px;
  min-height: 30px;
  padding: 0 20px;
}

.Ui-button-module_btn--contained_y75Bp__Main {
  background-color: var(--buttons-filled-bg);
  color: var(--buttons-filled-text);
  border: none;
}

.Ui-button-module_btn--contained_y75Bp__Main:hover, .Ui-button-module_btn--contained_y75Bp__Main:active {
  background-color: var(--buttons-hover);
}

.Ui-button-module_btn--contained_y75Bp__Main[disabled] {
  background-color: var(--disabled-bg);
  color: var(--disabled-text);
  cursor: not-allowed;
}

.Ui-button-module_btn--outlined_fhkfj__Main {
  color: var(--buttons-outlined-text);
  border: 1px solid var(--buttons-outlined-stroke);
  background-color: rgba(0, 0, 0, 0);
}

.Ui-button-module_btn--outlined_fhkfj__Main:hover, .Ui-button-module_btn--outlined_fhkfj__Main:active {
  border-color: var(--buttons-hover);
  color: var(--buttons-hover);
}

.Ui-button-module_btn--outlined_fhkfj__Main:hover svg, .Ui-button-module_btn--outlined_fhkfj__Main:active svg {
  fill: var(--icons-active);
}

.Ui-button-module_btn--outlined_fhkfj__Main[disabled] {
  color: var(--disabled-text);
  background-color: var(--disabled-bg);
  border-color: var(--disabled-stroke);
  cursor: not-allowed;
}

.Ui-button-module_btn--outlined_fhkfj__Main[disabled] svg {
  fill: var(--disabled-icons);
}

.Ui-button-module_btn--text_SwjQ7__Main {
  color: var(--buttons-text);
  background-color: rgba(0, 0, 0, 0);
  border: none;
}

.Ui-button-module_btn--text_SwjQ7__Main:hover, .Ui-button-module_btn--text_SwjQ7__Main:active {
  color: var(--buttons-hover);
}

.Ui-button-module_btn--text_SwjQ7__Main:hover svg, .Ui-button-module_btn--text_SwjQ7__Main:active svg {
  fill: var(--icons-active);
}

.Ui-button-module_btn--text_SwjQ7__Main[disabled] {
  color: var(--disabled-text);
  cursor: not-allowed;
}

.Ui-button-module_btn--text_SwjQ7__Main[disabled] svg {
  fill: var(--disabled-icons);
}

@media (max-width: 660px) {
  .Ui-button-module_btn--contained_y75Bp__Main:not([disabled]):hover {
    background-color: var(--buttons-filled-bg);
  }

  .Ui-button-module_btn--outlined_fhkfj__Main:not([disabled]):hover {
    border-color: var(--buttons-outlined-stroke);
    color: var(--buttons-outlined-text);
  }

  .Ui-button-module_btn--outlined_fhkfj__Main:not([disabled]):hover svg {
    fill: inherit;
  }

  .Ui-button-module_btn--text_SwjQ7__Main:not([disabled]):hover {
    color: var(--buttons-text);
  }

  .Ui-button-module_btn--text_SwjQ7__Main:not([disabled]):hover svg {
    fill: inherit;
  }
}

.Loader-module_loader_Ukoov__Main {
  justify-content: center;
  width: 100%;
  display: flex;
}

.Loader-module_loader__spinner_cp5N7__Main {
  width: var(--width);
  height: var(--height);
  border-radius: 50%;
  animation: 1s linear infinite Loader-module_rotate_p-j1x__Main;
  position: relative;
}

.Loader-module_loader__spinner_cp5N7__Main:before {
  content: "";
  box-sizing: border-box;
  border: 3px solid var(--buttons-switch-theme-dark-icon-bg);
  border-radius: 50%;
  animation: 1.5s linear infinite Loader-module_prixClipFix_yGEF1__Main;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@keyframes Loader-module_rotate_p-j1x__Main {
  to {
    transform: rotate(360deg);
  }
}

@keyframes Loader-module_prixClipFix_yGEF1__Main {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }

  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }

  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }

  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }

  to {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}

.UiText-module_text_aWC6D__Main {
  font-family: var(--montserrat-font), sans-serif;
  font-style: normal;
}

.UiText-module_text--h1_6bhwR__Main {
  letter-spacing: .25px;
  font-size: 32px;
  font-weight: 700;
  line-height: 39px;
}

.UiText-module_text--h2_YuPwS__Main {
  letter-spacing: .25px;
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
}

.UiText-module_text--h3_P5fV4__Main {
  letter-spacing: .25px;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
}

.UiText-module_text--h4_Bwu1n__Main {
  letter-spacing: .25px;
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
}

.UiText-module_text--h5_Jp8HF__Main {
  letter-spacing: .25px;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
}

.UiText-module_text--h6_NRjeq__Main {
  letter-spacing: .25px;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
}

.UiText-module_text--h7_2qCoq__Main {
  letter-spacing: .15px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.UiText-module_text--subtitle-1_OVEp9__Main {
  font-family: var(--roboto-font), sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

.UiText-module_text--subtitle-2_AyUMj__Main {
  font-family: var(--roboto-font), sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.UiText-module_text--subtitle-3_MyQFf__Main {
  font-family: var(--roboto-font), sans-serif;
  letter-spacing: .1px;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
}

.UiText-module_text--subtitle-4_Qp4Ld__Main {
  letter-spacing: .25px;
  font-size: 8px;
  font-weight: 500;
  line-height: 9.75px;
}

.UiText-module_text--subtitle-5_-f8Ht__Main {
  letter-spacing: .25px;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.6px;
}

.UiText-module_text--body-1_c2QB2__Main {
  font-family: var(--roboto-font), sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.UiText-module_text--body-2_ieVL3__Main {
  font-family: var(--roboto-font), sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
}

.UiText-module_text--body-3_NTvlg__Main {
  font-family: var(--roboto-font), sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

.UiText-module_text--body-4_A10xg__Main {
  font-family: var(--roboto-font), sans-serif;
  font-size: 17px;
  font-weight: 700;
  line-height: 18px;
}

.UiText-module_text--body-5_BTyN6__Main {
  font-family: var(--roboto-font), sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.41px;
}

.UiText-module_text--body-6_jjLlq__Main {
  font-family: var(--roboto-font), sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}

.UiText-module_text--button_4oqd9__Main {
  font-family: var(--roboto-font), sans-serif;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
}

.UiText-module_text--caption_0pAU7__Main {
  font-family: var(--roboto-font), sans-serif;
  letter-spacing: .4px;
  font-size: 12px;
  font-style: italic;
  line-height: 14px;
}

.UiText-module_text--overline_7PE45__Main {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
}

.UiText-module_text--link_lZmoh__Main:hover {
  color: var(--text-link-active);
}

.UiText-module_text--important-info_c8pZX__Main {
  letter-spacing: .25px;
  font-size: 46px;
  font-weight: 800;
  line-height: 56px;
}

.Main-module__nHnNZG__main-layout {
  background-color: var(--bg-page);
  width: 100%;
  min-height: 100%;
  position: relative;
}

.Main-module__nHnNZG__main-layout__wrapper {
  flex-flow: column;
  max-width: 1280px;
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
}

.Main-module__nHnNZG__main-layout__empty-block {
  min-height: 330px;
}

.Main-module__nHnNZG__main-layout__branding {
  z-index: 1;
  background-image: var(--background-image);
  background-position: top;
  background-repeat: no-repeat;
  background-size: auto;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 68px;
  left: 0;
}

.Main-module__nHnNZG__main-layout__fixed-banners {
  z-index: 99;
  justify-content: center;
  width: 100%;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 50%;
  overflow: hidden;
  transform: translateX(-50%);
}

.Main-module__nHnNZG__main-layout__dugout {
  display: none;
}

.Main-module__nHnNZG__main-layout__portal {
  z-index: 12;
  position: relative;
}

.Main-module__nHnNZG__main-layout__menu {
  width: 250px;
  min-width: 250px;
  position: relative;
}

.Main-module__nHnNZG__main-layout__menu:after {
  content: "";
  background-color: var(--icons-gray);
  z-index: 2;
  width: 1px;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}

.Main-module__nHnNZG__main-layout__content {
  flex-grow: 1;
  width: 100%;
  position: relative;
}

@media (max-width: 660px) {
  .Main-module__nHnNZG__main-layout {
    overflow: hidden;
  }

  .Main-module__nHnNZG__main-layout__wrapper {
    flex-flow: row;
    width: 100%;
    display: block;
  }

  .Main-module__nHnNZG__main-layout__wrapper--subscription-page {
    min-height: auto;
  }

  .Main-module__nHnNZG__main-layout__menu {
    z-index: 100000;
    background-color: var(--icons-white);
    width: 100%;
    min-height: 56px;
    position: fixed;
    top: 0;
  }

  .Main-module__nHnNZG__main-layout__menu:after {
    display: none;
  }

  .Main-module__nHnNZG__main-layout__content {
    width: 100%;
  }

  .Main-module__nHnNZG__main-layout__dugout {
    min-height: 200px;
    display: block;
  }
}

* {
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
  color: var(--text-primary);
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

body {
  display: block;
}

.next-error-h1 {
  border-right: 1px solid rgba(0, 0, 0, .3) !important;
}

body.no-scroll {
  overflow-y: hidden;
}

a {
  -webkit-tap-highlight-color: #fff;
  color: var(--text-link-active);
  outline: none;
  text-decoration: none;
}

:root {
  --swiper-navigation-size: 44px;
  --swiper-theme-color: #fff !important;
}

.swiper-button-prev, .swiper-button-next {
  color: #000 !important;
}

.swiper-button-prev:after, .swiper-button-next:after {
  background-color: #fff;
  border: 1px solid #eaeaea;
  border-radius: 100%;
  padding: 16px;
  font-size: 15px !important;
  line-height: .5 !important;
}

.embed__iframe {
  width: 100%;
  min-height: 320px;
  max-height: 450px;
}

.w-100 {
  width: 100%;
}

@media only screen and (min-width: 660px) {
  .desktop-interscroller {
    box-sizing: content-box;
    width: 100%;
    height: 250px;
    position: relative;
  }

  .desktop-interscroller + .place-for-ad {
    display: block;
  }

  .desktop-interscroller div {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }

  .desktop-interscroller__clipper {
    clip: rect(0, 100vw, 250px, 0);
    clip-path: inset(0);
    width: 100%;
    height: 250px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .desktop-interscroller__container {
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    top: 50%;
    left: 52%;
    transform: translate(-50%, -50%);
  }

  .desktop-interscroller__container iframe {
    width: 100%;
    max-width: 752px;
    max-height: 600px;
    min-width: auto !important;
  }

  .desktop-interscroller__wrapper {
    padding-top: 0;
    padding-bottom: 24px;
  }
}

.__react_modal_image__modal_container {
  top: 112px !important;
}

.__react_modal_image__medium_img {
  max-height: 85% !important;
  top: 45% !important;
}

.swiper-slide.computed-width-for-swiper-slides {
  width: var(--width) !important;
}

.swiper-button-disabled {
  display: none !important;
}

@media (max-width: 660px) {
  .swiper-slide.computed-width-for-swiper-slides {
    width: auto !important;
  }
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, dl, dt, dd, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
  vertical-align: baseline;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
}

:focus {
  outline: 0;
}

body {
  color: #000;
  background: #fff;
  line-height: 1;
}

table {
  border-collapse: separate;
  border-spacing: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
}

blockquote, q {
  quotes: "" "";
}

.ProseMirror-menu-wrapper {
  color: var(--text-primary) !important;
}

.ProseMirror-input, .linked-image__input-url {
  background: #fff;
}

.ProseMirror-imageControls__description-field textarea:focus {
  color: var(--text-primary) !important;
}

.ProseMirror-linkmenubar-btn {
  flex-wrap: wrap;
}

.ProseMirror-menubar, .ProseMirror-linkmenubar {
  border-radius: 5px;
  left: 0 !important;
}

.ProseMirror-menubar .ProseMirror-icon svg, .ProseMirror-linkmenubar .ProseMirror-icon svg {
  color: #000 !important;
}

.ProseMirror-menubar .ProseMirror-menu__dropdown, .ProseMirror-linkmenubar .ProseMirror-menu__dropdown {
  border-radius: 5px;
}

.ProseMirror-menubar .ProseMirror-menu__dropdown li, .ProseMirror-linkmenubar .ProseMirror-menu__dropdown li {
  border: none !important;
  list-style: none !important;
}

[data-type="subtitle"], [data-type="paragraph"] {
  color: #000;
}

button.ProseMirror-blockmenu__button {
  background: var(--buttons-filled-bg) !important;
}

.is-background-gray {
  background-color: var(--bg-block) !important;
}

.is-background-gray path, .is-background-gray g {
  fill: var(--buttons-outlined-text) !important;
}

@media (max-width: 660px) {
  .ProseMirror-linkmenubar-row .ProseMirror-input {
    width: 280px !important;
  }

  .ProseMirror-linkmenubar-show-mode {
    width: 300px !important;
  }

  .ProseMirror-menu-field {
    width: 150px !important;
  }
}

